
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";
import {mapGetters} from "vuex";
import configHelper from "@/helpers/configHelper.js";
import ShopModal from "@/layouts/shop/components/modal/ShopModal.vue";
import viewportMixin from "@/mixins/viewportMixin.js";
import {trackAddPaymentInfo, trackAddShippingInfo} from "@/helpers/gtagHelper.js";

export default {
  name: "CheckoutContinueButton",
  components: {ShopModal, ShopButton},
  mixins: [viewportMixin],
  props: {
    widthClasses: {
      type: String,
      default: "w-full"
    }
  },
  watch: {
    getButtonDisabledMessage: function (value) {
      if(!this.isInCheckout) {
        return false;
      }

      // Also mark ready when cart is loading but no other message
      const readyToOrder = value === null;
      return this.$emit(readyToOrder ? 'available' : 'unavailable');
    }
  },
  computed: {
    isInCheckout() {
      return this.$store.getters["checkout/isInCheckout"];
    },
    getButtonDisabledMessage() {
      if (this.isCartEmpty){
        return this.$t('cart.cart_empty');
      }

      if(!this.isInCheckout) {
        return null;
      }

      if(this.$store.getters['checkout/getSelectedTimeslotId'] === null) {
        return this.$t('checkout.no_timeslot_selected');
      }

      if(!this.hasSelectedPaymentMethod) {
        return this.$t('checkout.payment_method_no_selection')
      }

      if(this.getHasCartErrors) {
        return this.$t('checkout.error_cart');
      }

      if(!this.isMinimumOrderAmountReached) {
        return this.$t('checkout.min_order_not_reached.text', {minimum_amount: this.getMinimumOrderAmount});
      }

      return null;
    },
    getButtonText() {
      if (this.isInCheckout) {
        return this.$t('checkout.payment_checkout');
      }

      return this.$t('cart.checkout');
    },
    hasSelectedPaymentMethod() {
      if (this.getSelectedPaymentMethod ==='wallet' && this.getTotalAmount > this.$auth.user.walletAmount ) {
        return false;
      }
      return this.getSelectedPaymentMethod !== null;
    },
    getMinimumOrderAmount() {
      return this.$moneyHelper.centsToEuro(configHelper.getMinimumAmountForZone(this.getSelectedDeliveryContact.zoneId));
    },
    isCartEmpty() {
      return this.$auth.loggedIn
        ? this.$store.getters['cart/isCartEmpty']
        : this.$store.getters['guestCart/isCartEmpty'];
    },
    getSelectedDeliveryContact() {
      return this.getDeliveryContacts.find(d => d.id === this.getSelectedDeliveryContactId) || null;
    },
    ...mapGetters('checkout', [
      'getSelectedPaymentMethod',
      'getSelectedDeliveryContactId',
      'getSelectedTimeslotId',
      'getFilteredSelectedBags',
      'getSelectedCreditCardAlias',
      'getSaveNewCreditCard',
      'isMinimumOrderAmountReached',
      'getDeliveryContacts',
      'getConfirmButtonLoading'
    ]),
    ...mapGetters('cart', [
      'getHasCartErrors',
      'getExcludedCampaigns',
      'getTotalAmount',
      'isCartLoading',
      'getLoadedCampaignsWithConfirmation',
      'getFees'
    ]),
  },
  methods: {
    navigate() {
      if (!this.isInCheckout) {
        return this.navigateToCheckout();
      }

      // if User has campaigns  he can uncheck or fees, we will show him a dialog.
      // Otherwise, we init payment directly.
      if(this.getLoadedCampaignsWithConfirmation.length > 0 || this.getFees.length > 0) {
        this.$store.commit('checkout/setShowConfirmationModal', true);
        return;
      }

      this.initPayment();
    },
    initPayment() {
      if (!this.hasSelectedPaymentMethod) {
        return false;
      }

      this.sendTracking();

      this.$store.dispatch('checkout/initializePaymentForCart')
        .finally(() => {
          this.$nextTick(() => {
            return this.$router.push(this.localePath('checkout-payment-payment-init'));
          });
        });
    },
    navigateToCheckout() {
      this.$emit('checkout-navigation');
      return this.$router.push(this.localePath({name: 'checkout'}));
    },

    sendTracking() {
      trackAddPaymentInfo(
        this.$store.getters["cart/getItemsWithoutFeesAndCampaigns"],
        this.$store.getters["cart/getTotalAmount"],
        this.$store.getters["checkout/getSelectedPaymentMethodToSend"],
        this.$store.getters["cart/getPromoCodeIfValid"],
      );

      trackAddShippingInfo(
        this.$store.getters["cart/getItemsWithoutFeesAndCampaigns"],
        this.$store.getters["cart/getTotalAmount"],
        this.$store.getters["cart/getPromoCodeIfValid"],
      )
    }
  }
}
