
import ShopButton from "../buttons/ShopButton";
import LoadingSpinner from "../loading/LoadingSpinner";
import ShopCard from "../cards/ShopCard";
import BlogPostImagePlaceholder from "@/layouts/shop/components/recipes/BlogPostImagePlaceholder";

export default {
  name: "BlogPostPreviewCard",
  components: {BlogPostImagePlaceholder, ShopCard, LoadingSpinner, ShopButton},
  props: {
    blogPost: {
      type: Object,
      required: true
    },
    bigImage: {
      type: Boolean,
      default: false
    },
    showReadMore: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    imageLoaded: false
  }),
  created() {
    if (this.getImageUrl === null) {
      this.imageLoaded = true;
    }
  },
  computed: {
    getMainImageUrl() {
      return this.$getUrlOfImage(this.blogPost.media, "medium");
    },
    getImageClasses() {
      return "w-full h-full";
    },
    getBlogPostRoute() {
      return this.localePath({
        name: 'blog-type-post-id-slug',
        params: {
          type: this.blogPost.type.toLowerCase() === "recipe" ? "recipes" : "posts",
          id: this.blogPost.id,
          slug: this.blogPost.title.toLowerCase()
            .replace(/[^\w ]+/g, '')
            .replace(/ +/g, '-'),
        }
      });
    }
  },
}
