
import CheckoutReviewCard from "@/layouts/shop/components/retryPaymentAfterOrderCreation/review/CheckoutReviewCard.vue";
import CustomTable from "@/layouts/shop/components/tables/CustomTable";
import TableRow from "@/layouts/shop/components/tables/TableRow";
import TableDataCell from "@/layouts/shop/components/tables/TableDataCell";
import configHelper from "@/helpers/configHelper";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton";

export default {
  name: "CheckoutReviewCardPayment",
  components: { ShopButton, TableDataCell, TableRow, CustomTable, CheckoutReviewCard },
  props: {
    // If set to true, it means component will be used from the "my orders" page
    // -> Payment not on checkout but after the order has been created and not paid.
    payAfterOrderCreationContext: {
      type: Boolean,
      default: false
    },
    // Order info, only required if payAfterOrderCreationContext set to true
    order: {
      type: Object,
    }
  },
  watch: {
    getAmountToPay: function () {
      if(this.$auth.user.walletAmount >= this.$store.getters['cart/getTotalAmount']) {
        this.$store.commit('checkout/setSelectedPaymentMethod', configHelper.paymentMethods.wallet);
      }

    }
  },
  mounted() {
    this.$emit('amount', this.getAmountToPayWithoutWallet);
  },

  async fetch() {
    // Re-fetch to have the latest wallet balance
    await this.$auth.fetchUser();
  },
  computed: {
    getWalletAmount() {
      if (this.getPaymentMethod === 'monthlyInvoice' || this.payAfterOrderCreationContext) {
        return 0;
      }

      return this.$auth.user.walletAmount;
    },
    getWalletAmountText() {
      const amount = this.getAmountPaidFromWallet;

      if (amount > 0) {
        return '-' + this.$moneyHelper.centsToEuroString(amount);
      } else {
        return this.$moneyHelper.centsToEuroString(amount);
      }
    },
    getWalletAmountClass() {
      const amount = this.getWalletAmount;
      return amount > 0
        ? 'text-luxcaddy font-bold'
        : '';
    },
    getTotals() {
      return this.$store.getters['cart/getTotals'];
    },
    getOrderAmount() {
      if (this.payAfterOrderCreationContext) {
        return this.order.total.amount;
      }

      return this.getTotals.amount;
    },
    getAmountToPay() {
      if (this.payAfterOrderCreationContext) {
        return this.getOrderAmount - this.getAmountAlreadyPaid;
      }
      return this.getOrderAmount;
    },

    // Returns the amount that needs to be paid after the walled credit has been applied
    getAmountToPayWithoutWallet() {
      return this.getAmountToPay - this.getAmountPaidFromWallet;
    },

    getPaymentMethod() {
      if (this.payAfterOrderCreationContext) {
        return null;
      }

      return this.$store.getters['checkout/getSelectedPaymentMethod'];
    },
    getPaymentMethodText() {
      if (!this.getPaymentMethod) {
        return null;
      }

      let paymentMethod = this.getPaymentMethod;
      if (paymentMethod ==='wallet' && this.getAmountToPay > 0) {
          paymentMethod = null;
      }

      return this.$t(configHelper.getTranslationKeyForPaymentMethod(paymentMethod, true));
    },
    // Determine if Part of the order has already been paid.
    // This can be the case if the user initiated a payment, where part was paid with wallet
    // and is now trying to use "pay later" to pay for the rest.
    getAmountAlreadyPaid() {
      if (!this.payAfterOrderCreationContext) {
        return 0;
      }

      return this.order
        .paymentTransactions
        .filter(pt => pt.payedAmount > 0)
        .reduce((a, pt) => {
          return a + pt.payedAmount;
        }, 0);
    },
    getAmountPaidFromWallet() {
      if (this.getWalletAmount > this.getAmountToPay) {
        return  this.getAmountToPay;
      }

      return this.getWalletAmount;
    },
  }
}
