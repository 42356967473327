
import SingleFaqInModalIcon from "@/layouts/shop/components/faq/ShopFaqSingle/SingleFaqInModalIcon.vue";

export default {
  name: "ShopInputLabel",
  components: {SingleFaqInModalIcon},
  props: {
    label: {
      type: String,
      default: ''
    },
    showRequiredMark: {
      type: Boolean,
      default: false
    },
    faqQuestionId: {
      type: String,
    }
  }
}
