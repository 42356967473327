
import { debounce } from "@/helpers/debounceHelper";
import QuantitySelector from "@/layouts/shop/components/quantitySelector/QuantitySelector.vue";

export default {
  name: "ShoppingListModalList",
  components: {QuantitySelector },
  props: {
    list: {
      type: Object,
      required: true
    }
  },
  methods: {
    toggleAddToList() {
      if (this.isCurrentProductOnList) {
        this.productQuantity = 0;
      } else {
        this.productQuantity = 1;
      }
    },

    /**
     *  Updates the quantity on the backend
     */
    updateQuantity: debounce(function (quantity) {
      this.$store.dispatch('shoppingLists/setProductQuantity', {
        listId: this.list.id,
        quantity: quantity
      });
    }, 180),
  },
  computed: {
    isCurrentProductOnList() {
      return this.getCurrentProductInfoOnList !== null;
    },
    getCurrentProductInfoOnList() {
      const currentProduct = this.$store.getters['shoppingLists/getSelectedProduct'];
      return this.list.products.find(p => p.id === currentProduct?.id) ?? null;
    },
    productQuantity: {
      get() {
        return this.getCurrentProductInfoOnList?.qty ?? 0;
      },
      set(value) {
        this.$store.commit('shoppingLists/setProductQuantityOnList', {
          listId: this.list.id,
          quantity: value
        })

        this.updateQuantity(value);
      }
    }
  }
}
