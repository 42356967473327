
export default {
  name: "ProductCardInfoExplanation",
  props: {
    icon: {
      type: [String, Array],
      required: true
    }
  }
}
