
import ShopCartItem from "./ShopCartItem";
import SkeletonCartItem from "../skeleton/cart/SkeletonCartItem";

export default {
  name: "ShopCartItemList",
  components: { SkeletonCartItem, ShopCartItem },
  props: {
    isCartLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getCartItems() {
      return this.$auth.loggedIn
        ? this.$store.getters["cart/getItemsWithoutFeesAndCampaigns"]
        : this.$store.getters["guestCart/getItemsWithoutFeesAndCampaigns"];
    },
    isCartEmpty() {
      return this.$auth.loggedIn
        ? this.$store.getters["cart/isCartEmpty"]
        : this.$store.getters["guestCart/isCartEmpty"];
    },
    isInCheckout() {
      return this.$store.getters["checkout/isInCheckout"];
    },
  }
}
