
export default {
  name: "ProductCardGiftCampaignBadge",
  props: {
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: null
    },
    showDescription: {
      type: Boolean,
      default: false
    },
    textSizeClass: {
      type: String,
      default: "text-sm"
    },
    paddingClasses: {
      type: String,
      default: 'px-2 py-0.5'
    },
  },
  computed: {
    isDescriptionShown() {
      return this.description !== null && this.showDescription;
    },
    getPaddingClasses() {
      if(this.isDescriptionShown) {
        return "p-2.5";
      }
      return this.paddingClasses;
    }
  },
}
