
import {ValidationProvider} from 'vee-validate';
import ShopInputErrorMessage from "./ShopInputErrorMessage";
import ShopInputLabel from "./ShopInputLabel";


export default {
  name: "ShopTextAreaElement",
  components: {ShopInputLabel, ShopInputErrorMessage, ValidationProvider},
  props: {
    value: {
      type: [String, Number]
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    validationRules: {
      type: String,
      required: false,
      default: ""
    },
    additionalInputClasses: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Whether to occupy space for the validation message.
     */
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
    rows: {
      type: Number,
      default: 6
    },
    // This is required if two fields with the same label are used twice.
    // if no suffix is set, the first instance of that field will not be used.
    identifierSuffix: {
      type: String,
      default: null
    },
  },
  data: () => ({}),
  computed: {
    getName() {
      const suffix = this.identifierSuffix

      if (this.id) {
        return suffix ? this.id + "_" + suffix : this.id;
      }

      return suffix ? this.label + '_' + suffix : this.label;
    },

    /**
     * Returns the Input Placeholder.
     *
     * @returns {string}
     */
    getPlaceholder() {
      return this.placeholder || '';
    },
    /**
     * Determines if the input is required to be filled out.
     *
     * @return {boolean}
     */
    isRequired() {
      if (!this.validationRules) {
        return false;
      }

      const rules = this.validationRules.split('|');
      return rules.includes("required");
    },
  },
  methods: {
    emitInput(e) {
      this.$emit('input', e.target.value);
    },
    blurInput(e) {
      this.$emit('blur', e.target.value);
    },
    focus() {
      this.$refs.input.focus();
    }
  }
}
