const url = "/products";

export default $api => ({
  async getProducts() {
    return await $api.$get(url);
  },
  async getSingleProduct(productId) {
    return await $api.$get(`${url}/${productId}`);
  },
  async getDiscountedProducts() {
    return await $api.$get(`${url}/special-offers`);
  },
  async getNewProducts(page = 1, perPage = 40) {
    return await $api.$get(`${url}/new?page=${page}&perPage=${perPage}`);
  },

  async search(searchTerm) {
    return await $api.$post(`${url}/search`, {
      searchQuery: searchTerm
    });
  }
});
