
const TYPE_FEES = "fees";
const TYPE_GIFTS = "gifts";

export default {
  name: "CheckoutGiftFeeCard",
  props: {
    type: {
      type: String,
      validator: val => [TYPE_FEES, TYPE_GIFTS].includes(val)
    }
  },
  computed: {
    getCardData() {
      let amount;

      switch (this.type) {
        case TYPE_GIFTS:
          amount = this.$store.getters['cart/getLoadedCampaignsWithConfirmation'].length;

          return {
            title: this.$tc('checkout.campaign', amount),
            titleTextClasses: "text-luxcaddy-500",
            icon: "gift",
            boxClasses: "bg-luxcaddy-50",
          };
        case TYPE_FEES:
          amount = this.$store.getters['cart/getFees'].length;

          return {
            title: this.$tc('checkout.fees.title', amount),
            titleTextClasses: "text-red-400",
            icon: "cog",
            boxClasses: "bg-red-50"
          };
      }
    }
  },
}
