import { render, staticRenderFns } from "./SingleFaqInModalIcon.vue?vue&type=template&id=4176ac70&scoped=true&"
import script from "./SingleFaqInModalIcon.vue?vue&type=script&lang=js&"
export * from "./SingleFaqInModalIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4176ac70",
  null
  
)

export default component.exports