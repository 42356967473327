
import LoadingSpinner from "@/layouts/shop/components/loading/LoadingSpinner";

export default {
  name: "PaymentInitializedMonthlyInvoice",
  components: { LoadingSpinner },
  mounted() {
    this.$nextTick(() => {
      if(this.hasId) {
        return this.$router.push(
          this.localePath('checkout-payment-order-confirmed')
        );
      }
    })
  },
  computed: {
    getPaymentInitResponse() {
      return this.$store.getters['checkout/getPaymentInitResponse'];
    },
    hasId() {
      return this.getPaymentInitResponse?.id || false;
    }
  },
}
