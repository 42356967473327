
import CustomTable from "@/layouts/shop/components/tables/CustomTable.vue";

export default {
  name: "InvoiceList",
  components: { CustomTable },
  computed: {
    getHeadings() {
      return [
        {label: this.$t('account.invoices.invoice_number')},
        {label: this.$t('account.invoices.status')},
        {label: this.$t('account.invoices.amount_with_vat')},
        {label: this.$t('account.invoices.remaining_amount')},
        {label: this.$t('account.invoices.dueDate')},
        {label: this.$t('account.invoices.overDueDays')},
        {label: ''},
      ];
    }
  },
}
