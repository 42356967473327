
import ShopModal from "@/layouts/shop/components/modal/ShopModal.vue";
import CheckoutPromoCodeForm
  from "@/layouts/shop/components/checkout/payment/sub/promocode/CheckoutPromoCodeForm.vue";

export default {
  name: "CheckoutPaymentPromoCodeModal",
  components: {CheckoutPromoCodeForm, ShopModal},
  methods: {
    onClose() {
      this.$emit('close');
      this.$destroy();
    }
  },
}
