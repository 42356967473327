const EVENT_ADD_TO_CART = 'add_to_cart';
const EVENT_REMOVE_FROM_CART = 'remove_from_cart';
const EVENT_VIEW_ITEM = 'view_item';
const EVENT_BEGIN_CHECKOUT = 'begin_checkout';
const EVENT_ADD_PAYMENT_INFO = 'add_payment_info';
const EVENT_ADD_SHIPPING_INFO = 'add_shipping_info';
const EVENT_ADD_PRODUCT_FROM_RECIPE = 'add_product_from_recipe';

const trackEvent = function (eventName, eventData) {
  if (process.client && window && typeof window.gtag === 'function') {
    try {
      window.gtag('event', eventName, eventData);
    } catch (error) {
      console.error("Error sending to GA: " + error);
    }
  }
}

const trackAddToCart = function (product, quantity) {
  if(quantity === 0) {
    return;
  }

  let price = getPriceOfProduct(product);

  return trackEvent(EVENT_ADD_TO_CART, {
    currency: "EUR",
    value: convertCentsToEuro(price * quantity),
    items: [getMappedProductObject(product, quantity)]
  });
}

const trackRemoveFromCart = function (product, quantity) {
  if(quantity === 0) {
    return;
  }

  let price = getPriceOfProduct(product);

  return trackEvent(EVENT_REMOVE_FROM_CART, {
    currency: "EUR",
    value: convertCentsToEuro(price * quantity),
    items: [getMappedProductObject(product, quantity)]
  });
}

const trackCartCleared = function (cartItems) {
  const totalAmount = cartItems.reduce((acc, item) => {
    return acc + item.price.amount; // Price = (quantity * singlePrice) here already
  }, 0)

  const mappedItems = cartItems.map(p => {
    return {
      item_id: p.id,
      item_name: p.name,
      price: convertCentsToEuro(Math.round(p.price.amount / p.quantity)),
      quantity: p.quantity
    };
  })

  return trackEvent(EVENT_REMOVE_FROM_CART, {
    currency: "EUR",
    value: convertCentsToEuro(totalAmount),
    items: mappedItems
  });
}

const trackViewItem = function (product) {
  return trackEvent(EVENT_VIEW_ITEM, {
    items: [getMappedProductObject(product, 1)]
  });
}

const getMappedProductObject = function (product, quantity) {
  let price = getPriceOfProduct(product);

  // Product has campaign with discounted price
  if (product.campaigns?.discountedPrice) {
    price = product.campaigns.discountedPrice;
  }

  return {
    item_id: product.id,
    item_name: product.name,
    item_brand: product.brand?.name,
    price: convertCentsToEuro(price),
    quantity: quantity
  };
}

const getPriceOfProduct = function (product) {
  let price = product.sellingData?.price ?? null;

  if(price === null) {
    price = product.price.amount;
  }

  return price;
}

const convertCentsToEuro = function (value) {
  if(value === 0) {
    return 0;
  }
  return Number.parseFloat((value / 100).toFixed(2));
}

const getDiscountAmountForCartItem = function (item) {
  let discountAmount = 0;

  item.reference
    .filter(refItem => refItem.type === "Discount" && refItem.price.amount < 0)
    .forEach(refItem => {
      discountAmount += refItem.price.amount;
    });

  return discountAmount;
}

const mapCartItems = function (cartItems) {
  return cartItems.map((item, index) => {
    return {
      item_id: item.id,
      item_name: item.name,
      discount: convertCentsToEuro(getDiscountAmountForCartItem(item)),
      index: index,
      price: convertCentsToEuro(getPriceOfProduct(item)),
      quantity: item.quantity
    }
  })
}

const trackBeginCheckout = function (items, totalAmount, coupon = null) {
  return trackEvent(EVENT_BEGIN_CHECKOUT, {
    currency: 'EUR',
    value: convertCentsToEuro(totalAmount),
    coupon: coupon,
    items: mapCartItems(items)
  });
}

const trackAddPaymentInfo = function (items, totalAmount, paymentType, coupon = null) {
  return trackEvent(EVENT_ADD_PAYMENT_INFO, {
    currency: 'EUR',
    value: convertCentsToEuro(totalAmount),
    coupon: coupon,
    items: mapCartItems(items),
    payment_type: paymentType
  });
}

const trackAddShippingInfo = function (items, totalAmount, coupon = null) {
  return trackEvent(EVENT_ADD_SHIPPING_INFO, {
    currency: 'EUR',
    value: convertCentsToEuro(totalAmount),
    coupon: coupon,
    items: mapCartItems(items),
  });
}


const trackProductAddedToCartFromRecipe = function (product, routePath) {
  return trackEvent(EVENT_ADD_PRODUCT_FROM_RECIPE, {
    product_id: product.id,
    product_name: product.name,
    recipe: routePath
  });
}


export {trackAddToCart, trackRemoveFromCart, trackCartCleared, trackViewItem, trackBeginCheckout, trackAddPaymentInfo, trackAddShippingInfo, trackProductAddedToCartFromRecipe};
