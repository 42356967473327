
import PasswordInputElement from "@/layouts/shop/components/forms/inputs/password/PasswordInputElement.vue";
import ShopFormWrapper from "@/layouts/shop/components/forms/ShopFormWrapper.vue";
import ShopRadioButton from "@/layouts/shop/components/forms/inputs/radio/ShopRadioButton.vue";
import ShopPageTitle from "@/layouts/shop/components/pages/ShopPageTitle.vue";
import ShopInputElement from "@/layouts/shop/components/forms/inputs/base/ShopInputElement.vue";
import LanguageSelectInput from "@/layouts/shop/components/forms/inputs/LanguageSelectInput.vue";
import ShopCard from "@/layouts/shop/components/cards/ShopCard.vue";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "RegisterAccountStep",
  components: {
    PasswordInputElement,
    ShopRadioButton, ShopFormWrapper, ShopInputElement, LanguageSelectInput, ShopPageTitle, ShopCard
  },
  methods: {
    ...mapMutations('register', [
      'setAccountType',
      'setPassword',
      'setEmail',
      'resetDetailsStep',
    ]),
    changeAccountType(type) {
      this.setAccountType(type);

      // Clear step 2 when user changed types.
      this.resetDetailsStep();
    },
    markStepCompleted(callback) {
      // Load Vat Details, but only for LUX companies
      if(this.hasSelectedCompanyType && typeof this.vatNumber === "string" && this.vatNumber.length >= 8 && this.vatNumber.startsWith("LU")) {
        this.$store.dispatch("register/loadVATNumberDetails").finally(() => {
          callback();
          this.$emit('completed');
        });
        return;
      }

      callback();
      this.$emit('completed');
    }
  },
  mounted() {
    this.$refs.emailField.focus();
  },
  computed: {
    ...mapGetters('register', [
      'getAccountType',
      'getPassword',
      'getEmail',
    ]),

    password: {
      set(password) {
        this.setPassword(password)
      },
      get() {
        return this.getPassword;
      },
    },
    email: {
      set(email) {
        this.setEmail(email)
      },
      get() {
        return this.getEmail;
      },
    },
    vatNumber: {
      get() {
        return this.$store.state.register.vatNumber;
      },
      set(value) {
        // Remove all spaces
        value = value.replace(/ /g,'');
        this.$store.commit('register/setVatNumber', value);
      }
    },

    hasSelectedPrivatePersonType() {
      return this.getAccountType === "private";
    },
    hasSelectedCompanyType() {
      return this.getAccountType === "company";
    }
  },
}
