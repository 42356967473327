
import SelectInput from "./SelectInput";
export default {
  name: "FloorSelectInput",
  components: { SelectInput },
  props: {
    value: {
      type: [String, Number],
    },
    validationRules: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // This is required if two fields with the same label are used twice.
    // if no suffix is set, the first instance of that field will not be used.
    identifierSuffix: {
      type: String,
      default: null
    },
  },
  computed: {
    getFloorOptions() {
      let oneToTwenty = [...Array(21).keys()].map(o => {
        if (o === 0) {
          return { label: this.$t('common.floor_selection.ground_floor'), value: "ground" };
        }
        return { label: o, value: o };
      });
      return [
        { label: this.$t('common.floor_selection.house'), value: 'house' },
        { label: this.$t('common.floor_selection.reception'), value: 'reception' },
        ...oneToTwenty,
        { label: '-1', value: -1 },
        { label: '-2', value: -2 },
        { label: '-3', value: -3 },
        { label: '-4', value: -4 },
        { label: '-5', value: -5 },
        { label: '-6', value: -6 },
      ]
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
}
