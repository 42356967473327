
export default {
  name: "SameLevelCategoryBarCategory",
  props: {
    category: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(!this.isActive) {
        return false;
      }

      const posLeft = this.$refs.categoryBarItem.offsetLeft;
      this.$emit('mounted', posLeft);
    })
  },
  methods: {
    navigateToCategory() {
      if(this.type === "recipe") {
        this.$router.push(
          this.localePath({
            name: 'blog-type-category-categoryId',
            params: {
              type: 'recipes',
              categoryId: this.category.id
            }
          })
        )
      } else if(this.type === "product") {
        this.$router.push(
          this.localePath({
            name: 'categories-id-slug',
            params: {
              type: 'recipes',
              id: this.category.id,
              slug: this.category.slug
            }
          })
        );
      }
    }
  },
}
