
import ShopPageTitle from "@/layouts/shop/components/pages/ShopPageTitle.vue";
import CountrySelectInput from "@/layouts/shop/components/forms/inputs/CountrySelectInput.vue";
import TitleSelectInput from "@/layouts/shop/components/forms/inputs/TitleSelectInput.vue";
import FloorSelectInput from "@/layouts/shop/components/forms/inputs/FloorSelectInput.vue";
import ShopCheckbox from "@/layouts/shop/components/forms/inputs/base/ShopCheckbox.vue";
import ShopInputElement from "@/layouts/shop/components/forms/inputs/base/ShopInputElement.vue";
import ShopFormWrapper from "@/layouts/shop/components/forms/ShopFormWrapper.vue";
import RegisterAddressForm from "@/layouts/shop/components/auth/register/sub/RegisterAddressForm.vue";
import ShopRadioButton from "@/layouts/shop/components/forms/inputs/radio/ShopRadioButton.vue";
import {mapGetters, mapMutations} from "vuex";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";

export default {
  name: "RegisterDetailsStep",
  components: {
    ShopButton,
    ShopRadioButton,
    RegisterAddressForm,
    ShopFormWrapper,
    ShopInputElement, ShopCheckbox, FloorSelectInput, TitleSelectInput, CountrySelectInput, ShopPageTitle
  },
  methods: {
    ...mapMutations('register', [
      'setDifferentInvoiceAddress',
    ]),

    async register(callback) {
      if (!this.tosAccepted) {
        this.$modal.show({
          type: 'danger',
          title: this.$t('common.error'),
          body: this.$t('auth.registration.accept_tos_error'),
          primary: {
            label: "Ok",
            theme: 'danger',
            action: () => {
            },
          },
        });
        callback();
        return false;
      }

      const token = await this.$recaptcha.execute('register').catch((e) => {
        console.error("Get Captcha failed!");
        console.error(e);
        return callback();
      });

      let trackingParams = {
        gaClientId: this.$store.getters['app/getGaClientId'],
        gaSessionId: this.$store.getters['app/getGaSessionId']
      }

      const paramsFromStore = this.$store.getters['register/getRegisterPayload']

      await this.$registerRepository.register(
        {
          ...paramsFromStore,
          captchaToken: token,
          trackingParams: trackingParams,
          language: this.$i18n.locale,
        }).then(() => {
        this.$auth.loginWith('laravelSanctum', {
          data: {
            email: this.$store.getters["register/getEmail"],
            password: this.$store.getters["register/getPassword"],
            items: this.getGuestCartItems
          }
        }).then((res) => {
          const user = res.data.data.user;
          this.$auth.setUser(user);
          this.$sentry.setUser(user);

          this.$nextTick(async () => {
            this.$store.commit('checkout/clearStore');
            this.$store.commit('register/clearStore');
            await this.$store.dispatch('cart/fetchCart');
            await this.$store.dispatch('shoppingLists/fetchCompactLists');
            this.$store.commit('guestCart/resetCart');
            this.$toast.success(this.$t('auth.registration.success_message'));
            callback();
            return true;
          });
        }).finally(() => {
          callback();
          return false;
        });
      }).catch(() => {
        callback();
        return false;
      });
    }
  },
  computed: {
    ...mapGetters('register', [
      'getIsUsingDifferentInvoiceAddress',
      'getAccountType',
      'getTosAccepted',
      'getSubscribeNewsletter'
    ]),
    isCompany() {
      return this.getAccountType === "company";
    },
    vatNumber: {
      get() {
        return this.$store.state.register.vatNumber;
      },
      set(value) {
        this.$store.commit('register/setVatNumber', value);
      }
    },
    tosAccepted: {
      get() {
        return this.$store.getters["register/getTosAccepted"];
      },
      set(value) {
        this.$store.commit('register/setTosAccepted', value);
      }
    },
    subscribeNewsletter: {
      get() {
        return this.$store.getters["register/getSubscribeNewsletter"];
      },
      set(value) {
        this.$store.commit('register/setSubscribeNewsletter', value);
      }
    },
    getGuestCartItems() {
      return this.$store.getters['guestCart/getProductItemsMapped'];
    }
  }
}
