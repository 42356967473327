
export default {
  name: "ShopMobileCartBarDetails",
  props: {
    displayAsFlexRow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getTotalAmount() {
      return this.$auth.loggedIn
        ? this.$store.getters["cart/getTotals"]?.amount || 0
        : this.$store.getters["guestCart/getTotals"]?.amount || 0;
    },
  }
}
