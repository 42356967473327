
export default {
  name: "PasswordLengthRuleValidationText",
  props: {
    value: {
      type: String,
      default: null
    },
  },
  computed: {
    getText() {
      return this.$t('auth.password_validation.length');
    },
    hasMinLength() {
      return this.value === null ? false : this.value.length >= 6;
    },
  }
}
