
export default {
  name: "CheckoutPackagingPricingDetails",
  props: {
    bag: {
      type: Object,
      required: true
    },
    withMarginTop: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isComputedQuantity() {
      return this.bag.isComputedQuantity;
    },
    isSelected() {
      return this.$store.getters['checkout/getSelectedBagById'](this.bag.id) !== undefined;
    },
    showAsFixed() {
      return !this.isComputedQuantity && this.bag.maximumQuantity === 1;
    },
    getComputedQuantity() {
      return this.isComputedQuantity ? this.bag.quantity : null;
    },
    getPricePerUnit() {
      return this.bag.priceWithVat;
    },
    getTotalPrice() {
      if (this.showAsFixed) {
        return this.bag.maximumQuantity * this.getPricePerUnit;
      }

      return this.selectedQuantity * this.getPricePerUnit;
    },
    selectedQuantity() {
      if (!this.isSelected) {
        if (this.isComputedQuantity) {
          return this.getComputedQuantity;
        }

        return 0;
      }

      return this.$store.getters['checkout/getSelectedBagById'](this.bag.id).quantity;
    },
  }
}
