
export default {
  name: "ShopCheckboxButton",
  props: {
    value: {
      required: true
    },
    checkedValue: {
      type: [String, Number, Boolean],
      default: true
    },
    uncheckedValue: {
      type: [String, Number, Boolean],
      default: false
    }
  },
  methods: {
    onToggle() {
      this.$emit(
        'input',
        this.value === this.checkedValue
          ? this.uncheckedValue
          : this.checkedValue
      );
    }
  },
}
