
export default {
  name: "BackToPreviousBar",
  props: {
    to: {
      type: [String, Object],
      required: true
    },
  }
}
