
import LogoSvg from "@/layouts/shop/svgs/LogoSvg";
export default {
  name: "BlogPostImagePlaceholder",
  components: { LogoSvg },
  props: {
    biggerLogo: {
      type: Boolean,
      default: false
    },
  },
}
