
import ShopInputElement from "./base/ShopInputElement";

export default {
  name: "ShopTextInputElement",
  components: {
    ShopInputElement
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-types
    value: {
      required: false
    },
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: [String, null]
    },
    type: {
      type: String,
      default: "text"
    },
    // eslint-disable-next-line vue/require-default-prop
    label: {
      type: String,
      required: false
    },
    // eslint-disable-next-line vue/require-default-prop
    placeholder: {
      type: String,
      required: false
    },
    // eslint-disable-next-line vue/require-default-prop
    validationRules: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    // eslint-disable-next-line vue/require-default-prop
    insetText: {
      type: String,
      required: false
    },
    // eslint-disable-next-line vue/require-default-prop
    insetPosition: {
      type: String
    },

    /**
     * Whether to occupy space for the validation message.
     */
    occupyValidationSpace: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    }
  },
}
