
import CheckoutCreditCardMenu
  from "@/layouts/shop/components/retryPaymentAfterOrderCreation/payment/Creditcard/CheckoutCreditCardMenu.vue";
import CheckoutPaymentMethodSelection
  from "@/layouts/shop/components/retryPaymentAfterOrderCreation/payment/CheckoutPaymentMethodSelection.vue";
export default {
  name: "PaymentMethodSelectionMenu",
  components: { CheckoutPaymentMethodSelection, CheckoutCreditCardMenu },
  created() {
    this.selectedPaymentMethod = null;
    if (this.allowedPaymentType !== undefined && this.allowedPaymentType !== null && this.allowedPaymentType.length === 1) {
      this.selectedPaymentMethod  = this.allowedPaymentType[0]
    }

  },
  props: {
    allowedPaymentType: {
      type: Array,
      required: false
    }
  },
  methods: {
    isAllowedPaymentMethod(payment) {
      if (this.allowedPaymentType !== undefined && this.allowedPaymentType !== null) {
        return this.allowedPaymentType.includes(payment);
      }
      return true;
    }
  },
  computed: {
    selectedPaymentMethod: {
      get() {
        return this.$store.getters['checkout/getSelectedPaymentMethod'];
      },
      set(method) {
        this.$store.commit('checkout/setSelectedPaymentMethod', method);
      },
    },
    getAllowedPaymentProviders() {
      return this.$auth.user.allowedPaymentProviders;
    },
    canUseCreditCard() {
      if (this.allowedPaymentType !== undefined && this.allowedPaymentType !== null) {
        return this.allowedPaymentType.includes('saferpay');
      }
      return this.getAllowedPaymentProviders.includes('saferpay');
    },
    canUsePayconiq() {
      if (this.allowedPaymentType !== undefined && this.allowedPaymentType !== null) {
        return this.allowedPaymentType.includes('payconiq');
      }

      return this.getAllowedPaymentProviders.includes('payconiq');
    },
    canUseInvoice() {
      if (this.allowedPaymentType !== undefined && this.allowedPaymentType !== null) {
        return this.allowedPaymentType.includes('invoice');
      }
      return this.getAllowedPaymentProviders.includes('invoice');
    }
  },
}
