
import ProductCardInfoBadge from "@/layouts/shop/components/products/ProductCardInfos/ProductCardInfoBadge";
import ProductCardInfoExcludedDeliveryDaysBadge
  from "@/layouts/shop/components/products/ProductCardInfos/ExcludedDeliveryDays/ProductCardInfoExcludedDeliveryDaysBadge";
import ProductCardInfoDeliveryTimeBadge
  from "@/layouts/shop/components/products/ProductCardInfos/AdditionalDeliervyDays/ProductCardInfoDeliveryTimeBadge";
export default {
  name: "ProductCardInfoBadgeList",
  components: { ProductCardInfoDeliveryTimeBadge, ProductCardInfoExcludedDeliveryDaysBadge, ProductCardInfoBadge },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    /**
     * Whether the list should be shown at all.
     * @returns {boolean}
     */
    shouldShow() {
      return this.product.canBeBought && ( this.getExcludedDeliveryDays ||
        this.getDeliveryTime > 1);
    },

    /**
     * Returns an array of weekdays (1-7) on which this product can not be delivered.
     * @returns {array}
     */
    getExcludedDeliveryDays() {
      return this.product.excludedDeliveryDays;
    },
    /**
     * Returns the additional days that are needed for delivery.
     * @returns {number}
     */
    getDeliveryTime() {
      return this.product.deliveryTime;
    }
  },
}
