
import CheckoutPackagingSelectedBagsItem
  from "@/layouts/shop/components/checkout/packaging/sub/selected/CheckoutPackagingSelectedBagsItem.vue";

export default {
  name: "CheckoutPackagingSelectedBagsList",
  components: {CheckoutPackagingSelectedBagsItem},
  props: {
    selectedBags: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    getSelectedBagsWithQuantity() {
      return this.selectedBags
        .map(bag => {
          return {
            ...bag,
            selectedQuantity: this.selectedBags.find(b => b.id === bag.id).quantity
          };
        });
    }
  },
}
