
export default {
  name: "ProductFilterActive",
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  methods: {
    unselectFilter() {
      this.$store.commit('filters/selectOrUnselectFilterById', this.filter.id);
    }
  },
}
