
export default {
  name: "CheckoutTimeSlotDay",
  props: {
    date: {
      type: String,
      required: true
    },
    value: {
      type: [Object, String], //dayjs
      required: true,
    }
  },
  computed: {
    dateModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    getAsDayjs() {
      return this.$dayjs(this.date);
    },
    getWeekDayString() {
      return this.getAsDayjs.format('ddd');
    },
    isSelected() {
      return this.getAsDayjs.isSame(this.dateModel);
    }
  },
  methods: {
    setAsSelected() {
      this.dateModel = this.getAsDayjs;
      this.$emit('select');
    }
  },
}
