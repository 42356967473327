
import ShopNavLoginButton from "../nav/ShopNavLoginButton";
import ShopNavRegisterButton from "../nav/ShopNavRegisterButton";
import ShopCart from "../../components/cart/ShopCart";
import ShopNavUserButton from '../nav/ShopNavUserButton'
import viewportMixin from "@/mixins/viewportMixin";
export default {
  name: "ShopRightBar",
  components: { ShopNavUserButton, ShopCart, ShopNavRegisterButton, ShopNavLoginButton },
  mixins: [viewportMixin]
}
