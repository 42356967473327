
import ShopCartActionButton from "../ShopCartActionButton";
export default {
  name: "ShopCartActionClearCart",
  components: { ShopCartActionButton },
  methods: {
    clearCart() {
      this.$modal.show({
        type: 'danger',
        title: this.$t('cart.clear_cart.title'),
        body: this.$t('cart.clear_cart.message'),
        primary: {
          label: this.$t('common.confirm'),
          theme: 'danger',
          action: () => {
            if(this.$auth.loggedIn) {
              this.$store.dispatch('cart/clearItems');
            } else {
              this.$store.dispatch('guestCart/clearItems');
            }
          },
        },
        secondary: {
          label: this.$t('common.cancel'),
          theme: 'light',
          action: () => {}
        }
      });
    }
  },
}
