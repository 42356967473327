
export default {
  name: "ShopNavButtonMenuLink",
  props: {
    to: {
      type: [Object, String],
      default: null
    },
    icon: {
      type: String
    },
    label: {
      type: String
    }
  },
  methods: {
    emitClick(e) {
      this.$emit('click', e);
    }
  },
  computed: {
    getClasses() {
      return "block px-1.5 lg:px-4 py-1.5 w-full tracking-tight leading-tight lg:tracking-normal rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 hover:text-luxcaddy-600 dark:hover:text-luxcaddy-400 focus:outline-none focus:ring-2 focus:ring-luxcaddy-600 focus:text-luxcaddy-600 cursor-pointer flex items-center space-x-6 text-sm 4xl:text-md";
    },
    isRouterLink() {
      return this.to !== null;
    }
  },
}
