
import ShopModal from "@/layouts/shop/components/modal/ShopModal.vue";
import configHelper from "@/helpers/configHelper.js";
import PaymentMethodImage
  from "@/layouts/shop/components/account/orders/single/orderTransactions/PaymentMethodImage.vue";
import LoadingSpinner from "@/layouts/shop/components/loading/LoadingSpinner.vue";
import CheckoutPaymentMethodSelectionItem
  from "@/layouts/shop/components/checkout/payment/sub/selection/CheckoutPaymentMethodSelectionItem.vue";
import SingleFaqInModalIcon from "@/layouts/shop/components/faq/ShopFaqSingle/SingleFaqInModalIcon.vue";

export default {
  name: "CheckoutPaymentMethodSelectionMenu",
  components: {SingleFaqInModalIcon, CheckoutPaymentMethodSelectionItem, LoadingSpinner, PaymentMethodImage, ShopModal},
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    selectCreditCard(creditCard) {
      this.$store.commit('checkout/setSelectedPaymentMethod', "saferpay");
      this.$store.commit('checkout/setSelectedCreditCardDetails', creditCard);
      this.$emit('selected');
    },
    selectPaymentMethod(paymentMethod) {
      this.$store.commit('checkout/setSelectedPaymentMethod', paymentMethod);
      this.$store.commit('checkout/setSelectedCreditCardDetails', null);
      this.$emit('selected');
    },
  },
  computed: {
    getAllowedPaymentProviders() {
      let methods = [...this.$auth.user.allowedPaymentProviders];

      const index = methods.indexOf(configHelper.paymentMethods.wallet);
      if (index !== -1) {
        methods.splice(index, 1);
      }

      return methods;
    },
    getSavedCreditCards() {
      return this.$store.getters["checkout/getSavedCreditCardDetails"];
    },
    getAllPaymentProviders() {
      let methods = Object.values(configHelper.paymentMethods);
      const index = methods.indexOf(configHelper.paymentMethods.wallet);
      if (index !== -1) {
        methods.splice(index, 1);
      }

      return methods;
    },
    isBusinessCustomer() {
      return this.$auth.user.companyName !== null;
    },
    getUnusedPaymentProviders() {
      if(!this.isBusinessCustomer) {
        return [];
      }

      return this.getAllPaymentProviders.filter(provider => !this.getAllowedPaymentProviders.includes(provider));
    }
  },
}
