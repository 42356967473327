
export default {
  name: "CheckoutTitle",
  props: {
    withoutMargin: {
      type: Boolean,
      default: false
    },
    selectionDone: {
      type: Boolean,
      default: false
    }
  }
}
