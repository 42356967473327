
import ShopSearchInput from "./partials/search/ShopSearchInput";
import ShopNavMenu from "./partials/nav/ShopNavMenu";
import ShopNavLoginButton from "./partials/nav/ShopNavLoginButton";
import ShopNavRegisterButton from "./partials/nav/ShopNavRegisterButton";
import ShopCartButtonWithOverlay from "./components/cart/ShopCartButtonWithOverlay";
import ShopNavUserButton from './partials/nav/ShopNavUserButton'
import viewportMixin from "@/mixins/viewportMixin";
import ShopCartActions from "@/layouts/shop/components/cart/includes/ShopCartActions.vue";
export default {
  name: "ShopTopNav",
  components: {ShopCartActions, ShopNavUserButton, ShopCartButtonWithOverlay, ShopNavRegisterButton, ShopNavLoginButton, ShopNavMenu, ShopSearchInput },
  mixins: [viewportMixin]
}
