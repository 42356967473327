
export default {
  name: "ShopCheckbox",
  props: {
    value: {
      type: Boolean,
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(bool) {
        this.$emit('input', bool);
      }
    }
  }
}
