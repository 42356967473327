export const state = () => ({
  appVersion: null,
  appRefreshNeeded: false,
  gaClientId: null,
  gaSessionId: null
})

export const mutations = {
  setAppVersion(state, version) {
    state.appVersion = version;
  },
  setAppRefreshNeededTrue(state) {
    state.appRefreshNeeded = true;
  },
  setGaClientId(state, gaClientId) {
    state.gaClientId = gaClientId;
  },

  setGaSessionId(state, gaSessionId) {
    state.gaSessionId = gaSessionId;
  },
}

export const getters = {
  getAppVersion: state => state.appVersion,
  getGaClientId: (state) => {
    if (state.gaClientId) {
      return state.gaClientId;
    }

    const gaCookie = document.cookie.split('; ').find((cookie) => cookie.startsWith('_ga='));
    if (gaCookie) {
      // Extract the GA client ID from the cookie value
      return gaCookie.split('.').slice(-2).join('.');
    }

    return null;
  },

  getGaSessionId: (state) => {
    if (state.gaSessionId) {
      return state.gaSessionId;
    }

    const gaSessionCookie = document.cookie.split('; ').find((cookie) => cookie.startsWith('_ga_'));
    if (gaSessionCookie) {
      // Extract the GA client ID from the cookie value
      return gaSessionCookie.split('.').slice(2)[0] ?? null;
    }

    return null;
  },
  isAppRefreshNeeded: state => state.appRefreshNeeded === true,
}
