import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _95621288 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2b76814c = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _0c5834e3 = () => interopDefault(import('../pages/account/index/delivery-address/index.vue' /* webpackChunkName: "pages/account/index/delivery-address/index" */))
const _2988a824 = () => interopDefault(import('../pages/account/index/invoices/index.vue' /* webpackChunkName: "pages/account/index/invoices/index" */))
const _0eb1b06d = () => interopDefault(import('../pages/account/index/orders/index.vue' /* webpackChunkName: "pages/account/index/orders/index" */))
const _ee602b8e = () => interopDefault(import('../pages/account/index/payment-methods/index.vue' /* webpackChunkName: "pages/account/index/payment-methods/index" */))
const _25b24677 = () => interopDefault(import('../pages/account/index/profile/index.vue' /* webpackChunkName: "pages/account/index/profile/index" */))
const _f2e88c90 = () => interopDefault(import('../pages/account/index/shopping-lists/index.vue' /* webpackChunkName: "pages/account/index/shopping-lists/index" */))
const _b33a9b8a = () => interopDefault(import('../pages/account/index/delivery-address/create.vue' /* webpackChunkName: "pages/account/index/delivery-address/create" */))
const _769b7460 = () => interopDefault(import('../pages/account/index/profile/billing.vue' /* webpackChunkName: "pages/account/index/profile/billing" */))
const _6d60fb3e = () => interopDefault(import('../pages/account/index/delivery-address/_id/edit.vue' /* webpackChunkName: "pages/account/index/delivery-address/_id/edit" */))
const _520e5263 = () => interopDefault(import('../pages/account/index/orders/_id/overview.vue' /* webpackChunkName: "pages/account/index/orders/_id/overview" */))
const _0cc3388c = () => interopDefault(import('../pages/account/index/orders/_id/payment.vue' /* webpackChunkName: "pages/account/index/orders/_id/payment" */))
const _1eafca49 = () => interopDefault(import('../pages/account/index/shopping-lists/_id/edit.vue' /* webpackChunkName: "pages/account/index/shopping-lists/_id/edit" */))
const _c6657ace = () => interopDefault(import('../pages/account/index/orders/_id/delivery/_deliveryId/tracking.vue' /* webpackChunkName: "pages/account/index/orders/_id/delivery/_deliveryId/tracking" */))
const _67788662 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _6d52c58d = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _77d1248c = () => interopDefault(import('../pages/new-products/index.vue' /* webpackChunkName: "pages/new-products/index" */))
const _3acadbd2 = () => interopDefault(import('../pages/recipes/index.vue' /* webpackChunkName: "pages/recipes/index" */))
const _af4f9818 = () => interopDefault(import('../pages/special-offers/index.vue' /* webpackChunkName: "pages/special-offers/index" */))
const _e92e26b8 = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _9498f670 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _4fb032d2 = () => interopDefault(import('../pages/auth/register/index.vue' /* webpackChunkName: "pages/auth/register/index" */))
const _3ec6efec = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _2d452c78 = () => interopDefault(import('../pages/blog/newest-recipes/index.vue' /* webpackChunkName: "pages/blog/newest-recipes/index" */))
const _a02722c2 = () => interopDefault(import('../pages/info/delivery.vue' /* webpackChunkName: "pages/info/delivery" */))
const _db486e8a = () => interopDefault(import('../pages/info/faq.vue' /* webpackChunkName: "pages/info/faq" */))
const _410d368e = () => interopDefault(import('../pages/info/history.vue' /* webpackChunkName: "pages/info/history" */))
const _7474f9ae = () => interopDefault(import('../pages/info/imprint.vue' /* webpackChunkName: "pages/info/imprint" */))
const _79735202 = () => interopDefault(import('../pages/info/privacy-policy.vue' /* webpackChunkName: "pages/info/privacy-policy" */))
const _0965bf9f = () => interopDefault(import('../pages/info/terms-and-conditions.vue' /* webpackChunkName: "pages/info/terms-and-conditions" */))
const _cdfbb4a2 = () => interopDefault(import('../pages/info/who-we-are.vue' /* webpackChunkName: "pages/info/who-we-are" */))
const _6720697a = () => interopDefault(import('../pages/newsletter/subscribe.vue' /* webpackChunkName: "pages/newsletter/subscribe" */))
const _1c231cd6 = () => interopDefault(import('../pages/search/results.vue' /* webpackChunkName: "pages/search/results" */))
const _38f9be72 = () => interopDefault(import('../pages/auth/register/details.vue' /* webpackChunkName: "pages/auth/register/details" */))
const _b35b8058 = () => interopDefault(import('../pages/checkout/payment/order-confirmed.vue' /* webpackChunkName: "pages/checkout/payment/order-confirmed" */))
const _5a231373 = () => interopDefault(import('../pages/checkout/payment/payment-init.vue' /* webpackChunkName: "pages/checkout/payment/payment-init" */))
const _3cb533ae = () => interopDefault(import('../pages/checkout/payment/payment-init-error.vue' /* webpackChunkName: "pages/checkout/payment/payment-init-error" */))
const _32573478 = () => interopDefault(import('../pages/checkout/payment/saferpay/error.vue' /* webpackChunkName: "pages/checkout/payment/saferpay/error" */))
const _31ec96f3 = () => interopDefault(import('../pages/checkout/payment/saferpay/success.vue' /* webpackChunkName: "pages/checkout/payment/saferpay/success" */))
const _303b9e66 = () => interopDefault(import('../pages/delivery-problem-contact/_id/index.vue' /* webpackChunkName: "pages/delivery-problem-contact/_id/index" */))
const _2d332277 = () => interopDefault(import('../pages/blog/_type/category/_categoryId/index.vue' /* webpackChunkName: "pages/blog/_type/category/_categoryId/index" */))
const _6e60dab3 = () => interopDefault(import('../pages/blog/_type/post/_id/_slug.vue' /* webpackChunkName: "pages/blog/_type/post/_id/_slug" */))
const _910fdf10 = () => interopDefault(import('../pages/brands/_id/_slug.vue' /* webpackChunkName: "pages/brands/_id/_slug" */))
const _20057dc8 = () => interopDefault(import('../pages/categories/_id/_slug.vue' /* webpackChunkName: "pages/categories/_id/_slug" */))
const _b8c5a2e0 = () => interopDefault(import('../pages/products/_id/_slug.vue' /* webpackChunkName: "pages/products/_id/_slug" */))
const _d9038e6e = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _95621288,
    name: "index___de"
  }, {
    path: "/en",
    component: _95621288,
    name: "index___en"
  }, {
    path: "/fr",
    component: _95621288,
    name: "index___fr"
  }, {
    path: "/lb",
    component: _95621288,
    name: "index___lb"
  }, {
    path: "/de/account",
    component: _2b76814c,
    name: "account___de",
    children: [{
      path: "delivery-address",
      component: _0c5834e3,
      name: "account-index-delivery-address___de"
    }, {
      path: "invoices",
      component: _2988a824,
      name: "account-index-invoices___de"
    }, {
      path: "orders",
      component: _0eb1b06d,
      name: "account-index-orders___de"
    }, {
      path: "payment-methods",
      component: _ee602b8e,
      name: "account-index-payment-methods___de"
    }, {
      path: "profile",
      component: _25b24677,
      name: "account-index-profile___de"
    }, {
      path: "shopping-lists",
      component: _f2e88c90,
      name: "account-index-shopping-lists___de"
    }, {
      path: "delivery-address/create",
      component: _b33a9b8a,
      name: "account-index-delivery-address-create___de"
    }, {
      path: "profile/billing",
      component: _769b7460,
      name: "account-index-profile-billing___de"
    }, {
      path: "delivery-address/:id?/edit",
      component: _6d60fb3e,
      name: "account-index-delivery-address-id-edit___de"
    }, {
      path: "orders/:id?/overview",
      component: _520e5263,
      name: "account-index-orders-id-overview___de"
    }, {
      path: "orders/:id?/payment",
      component: _0cc3388c,
      name: "account-index-orders-id-payment___de"
    }, {
      path: "shopping-lists/:id?/edit",
      component: _1eafca49,
      name: "account-index-shopping-lists-id-edit___de"
    }, {
      path: "orders/:id?/delivery/:deliveryId?/tracking",
      component: _c6657ace,
      name: "account-index-orders-id-delivery-deliveryId-tracking___de"
    }]
  }, {
    path: "/de/checkout",
    component: _67788662,
    name: "checkout___de"
  }, {
    path: "/de/contact",
    component: _6d52c58d,
    name: "contact___de"
  }, {
    path: "/de/new-products",
    component: _77d1248c,
    name: "new-products___de"
  }, {
    path: "/de/recipes",
    component: _3acadbd2,
    name: "recipes___de"
  }, {
    path: "/de/special-offers",
    component: _af4f9818,
    name: "special-offers___de"
  }, {
    path: "/en/account",
    component: _2b76814c,
    name: "account___en",
    children: [{
      path: "delivery-address",
      component: _0c5834e3,
      name: "account-index-delivery-address___en"
    }, {
      path: "invoices",
      component: _2988a824,
      name: "account-index-invoices___en"
    }, {
      path: "orders",
      component: _0eb1b06d,
      name: "account-index-orders___en"
    }, {
      path: "payment-methods",
      component: _ee602b8e,
      name: "account-index-payment-methods___en"
    }, {
      path: "profile",
      component: _25b24677,
      name: "account-index-profile___en"
    }, {
      path: "shopping-lists",
      component: _f2e88c90,
      name: "account-index-shopping-lists___en"
    }, {
      path: "delivery-address/create",
      component: _b33a9b8a,
      name: "account-index-delivery-address-create___en"
    }, {
      path: "profile/billing",
      component: _769b7460,
      name: "account-index-profile-billing___en"
    }, {
      path: "delivery-address/:id?/edit",
      component: _6d60fb3e,
      name: "account-index-delivery-address-id-edit___en"
    }, {
      path: "orders/:id?/overview",
      component: _520e5263,
      name: "account-index-orders-id-overview___en"
    }, {
      path: "orders/:id?/payment",
      component: _0cc3388c,
      name: "account-index-orders-id-payment___en"
    }, {
      path: "shopping-lists/:id?/edit",
      component: _1eafca49,
      name: "account-index-shopping-lists-id-edit___en"
    }, {
      path: "orders/:id?/delivery/:deliveryId?/tracking",
      component: _c6657ace,
      name: "account-index-orders-id-delivery-deliveryId-tracking___en"
    }]
  }, {
    path: "/en/checkout",
    component: _67788662,
    name: "checkout___en"
  }, {
    path: "/en/contact",
    component: _6d52c58d,
    name: "contact___en"
  }, {
    path: "/en/new-products",
    component: _77d1248c,
    name: "new-products___en"
  }, {
    path: "/en/recipes",
    component: _3acadbd2,
    name: "recipes___en"
  }, {
    path: "/en/special-offers",
    component: _af4f9818,
    name: "special-offers___en"
  }, {
    path: "/fr/account",
    component: _2b76814c,
    name: "account___fr",
    children: [{
      path: "delivery-address",
      component: _0c5834e3,
      name: "account-index-delivery-address___fr"
    }, {
      path: "invoices",
      component: _2988a824,
      name: "account-index-invoices___fr"
    }, {
      path: "orders",
      component: _0eb1b06d,
      name: "account-index-orders___fr"
    }, {
      path: "payment-methods",
      component: _ee602b8e,
      name: "account-index-payment-methods___fr"
    }, {
      path: "profile",
      component: _25b24677,
      name: "account-index-profile___fr"
    }, {
      path: "shopping-lists",
      component: _f2e88c90,
      name: "account-index-shopping-lists___fr"
    }, {
      path: "delivery-address/create",
      component: _b33a9b8a,
      name: "account-index-delivery-address-create___fr"
    }, {
      path: "profile/billing",
      component: _769b7460,
      name: "account-index-profile-billing___fr"
    }, {
      path: "delivery-address/:id?/edit",
      component: _6d60fb3e,
      name: "account-index-delivery-address-id-edit___fr"
    }, {
      path: "orders/:id?/overview",
      component: _520e5263,
      name: "account-index-orders-id-overview___fr"
    }, {
      path: "orders/:id?/payment",
      component: _0cc3388c,
      name: "account-index-orders-id-payment___fr"
    }, {
      path: "shopping-lists/:id?/edit",
      component: _1eafca49,
      name: "account-index-shopping-lists-id-edit___fr"
    }, {
      path: "orders/:id?/delivery/:deliveryId?/tracking",
      component: _c6657ace,
      name: "account-index-orders-id-delivery-deliveryId-tracking___fr"
    }]
  }, {
    path: "/fr/checkout",
    component: _67788662,
    name: "checkout___fr"
  }, {
    path: "/fr/contact",
    component: _6d52c58d,
    name: "contact___fr"
  }, {
    path: "/fr/new-products",
    component: _77d1248c,
    name: "new-products___fr"
  }, {
    path: "/fr/recipes",
    component: _3acadbd2,
    name: "recipes___fr"
  }, {
    path: "/fr/special-offers",
    component: _af4f9818,
    name: "special-offers___fr"
  }, {
    path: "/lb/account",
    component: _2b76814c,
    name: "account___lb",
    children: [{
      path: "delivery-address",
      component: _0c5834e3,
      name: "account-index-delivery-address___lb"
    }, {
      path: "invoices",
      component: _2988a824,
      name: "account-index-invoices___lb"
    }, {
      path: "orders",
      component: _0eb1b06d,
      name: "account-index-orders___lb"
    }, {
      path: "payment-methods",
      component: _ee602b8e,
      name: "account-index-payment-methods___lb"
    }, {
      path: "profile",
      component: _25b24677,
      name: "account-index-profile___lb"
    }, {
      path: "shopping-lists",
      component: _f2e88c90,
      name: "account-index-shopping-lists___lb"
    }, {
      path: "delivery-address/create",
      component: _b33a9b8a,
      name: "account-index-delivery-address-create___lb"
    }, {
      path: "profile/billing",
      component: _769b7460,
      name: "account-index-profile-billing___lb"
    }, {
      path: "delivery-address/:id?/edit",
      component: _6d60fb3e,
      name: "account-index-delivery-address-id-edit___lb"
    }, {
      path: "orders/:id?/overview",
      component: _520e5263,
      name: "account-index-orders-id-overview___lb"
    }, {
      path: "orders/:id?/payment",
      component: _0cc3388c,
      name: "account-index-orders-id-payment___lb"
    }, {
      path: "shopping-lists/:id?/edit",
      component: _1eafca49,
      name: "account-index-shopping-lists-id-edit___lb"
    }, {
      path: "orders/:id?/delivery/:deliveryId?/tracking",
      component: _c6657ace,
      name: "account-index-orders-id-delivery-deliveryId-tracking___lb"
    }]
  }, {
    path: "/lb/checkout",
    component: _67788662,
    name: "checkout___lb"
  }, {
    path: "/lb/contact",
    component: _6d52c58d,
    name: "contact___lb"
  }, {
    path: "/lb/new-products",
    component: _77d1248c,
    name: "new-products___lb"
  }, {
    path: "/lb/recipes",
    component: _3acadbd2,
    name: "recipes___lb"
  }, {
    path: "/lb/special-offers",
    component: _af4f9818,
    name: "special-offers___lb"
  }, {
    path: "/de/auth/forgot-password",
    component: _e92e26b8,
    name: "auth-forgot-password___de"
  }, {
    path: "/de/auth/login",
    component: _9498f670,
    name: "auth-login___de"
  }, {
    path: "/de/auth/register",
    component: _4fb032d2,
    name: "auth-register___de"
  }, {
    path: "/de/auth/reset-password",
    component: _3ec6efec,
    name: "auth-reset-password___de"
  }, {
    path: "/de/blog/newest-recipes",
    component: _2d452c78,
    name: "blog-newest-recipes___de"
  }, {
    path: "/de/info/delivery",
    component: _a02722c2,
    name: "info-delivery___de"
  }, {
    path: "/de/info/faq",
    component: _db486e8a,
    name: "info-faq___de"
  }, {
    path: "/de/info/history",
    component: _410d368e,
    name: "info-history___de"
  }, {
    path: "/de/info/imprint",
    component: _7474f9ae,
    name: "info-imprint___de"
  }, {
    path: "/de/info/privacy-policy",
    component: _79735202,
    name: "info-privacy-policy___de"
  }, {
    path: "/de/info/terms-and-conditions",
    component: _0965bf9f,
    name: "info-terms-and-conditions___de"
  }, {
    path: "/de/info/who-we-are",
    component: _cdfbb4a2,
    name: "info-who-we-are___de"
  }, {
    path: "/de/newsletter/subscribe",
    component: _6720697a,
    name: "newsletter-subscribe___de"
  }, {
    path: "/de/search/results",
    component: _1c231cd6,
    name: "search-results___de"
  }, {
    path: "/en/auth/forgot-password",
    component: _e92e26b8,
    name: "auth-forgot-password___en"
  }, {
    path: "/en/auth/login",
    component: _9498f670,
    name: "auth-login___en"
  }, {
    path: "/en/auth/register",
    component: _4fb032d2,
    name: "auth-register___en"
  }, {
    path: "/en/auth/reset-password",
    component: _3ec6efec,
    name: "auth-reset-password___en"
  }, {
    path: "/en/blog/newest-recipes",
    component: _2d452c78,
    name: "blog-newest-recipes___en"
  }, {
    path: "/en/info/delivery",
    component: _a02722c2,
    name: "info-delivery___en"
  }, {
    path: "/en/info/faq",
    component: _db486e8a,
    name: "info-faq___en"
  }, {
    path: "/en/info/history",
    component: _410d368e,
    name: "info-history___en"
  }, {
    path: "/en/info/imprint",
    component: _7474f9ae,
    name: "info-imprint___en"
  }, {
    path: "/en/info/privacy-policy",
    component: _79735202,
    name: "info-privacy-policy___en"
  }, {
    path: "/en/info/terms-and-conditions",
    component: _0965bf9f,
    name: "info-terms-and-conditions___en"
  }, {
    path: "/en/info/who-we-are",
    component: _cdfbb4a2,
    name: "info-who-we-are___en"
  }, {
    path: "/en/newsletter/subscribe",
    component: _6720697a,
    name: "newsletter-subscribe___en"
  }, {
    path: "/en/search/results",
    component: _1c231cd6,
    name: "search-results___en"
  }, {
    path: "/fr/auth/forgot-password",
    component: _e92e26b8,
    name: "auth-forgot-password___fr"
  }, {
    path: "/fr/auth/login",
    component: _9498f670,
    name: "auth-login___fr"
  }, {
    path: "/fr/auth/register",
    component: _4fb032d2,
    name: "auth-register___fr"
  }, {
    path: "/fr/auth/reset-password",
    component: _3ec6efec,
    name: "auth-reset-password___fr"
  }, {
    path: "/fr/blog/newest-recipes",
    component: _2d452c78,
    name: "blog-newest-recipes___fr"
  }, {
    path: "/fr/info/delivery",
    component: _a02722c2,
    name: "info-delivery___fr"
  }, {
    path: "/fr/info/faq",
    component: _db486e8a,
    name: "info-faq___fr"
  }, {
    path: "/fr/info/history",
    component: _410d368e,
    name: "info-history___fr"
  }, {
    path: "/fr/info/imprint",
    component: _7474f9ae,
    name: "info-imprint___fr"
  }, {
    path: "/fr/info/privacy-policy",
    component: _79735202,
    name: "info-privacy-policy___fr"
  }, {
    path: "/fr/info/terms-and-conditions",
    component: _0965bf9f,
    name: "info-terms-and-conditions___fr"
  }, {
    path: "/fr/info/who-we-are",
    component: _cdfbb4a2,
    name: "info-who-we-are___fr"
  }, {
    path: "/fr/newsletter/subscribe",
    component: _6720697a,
    name: "newsletter-subscribe___fr"
  }, {
    path: "/fr/search/results",
    component: _1c231cd6,
    name: "search-results___fr"
  }, {
    path: "/lb/auth/forgot-password",
    component: _e92e26b8,
    name: "auth-forgot-password___lb"
  }, {
    path: "/lb/auth/login",
    component: _9498f670,
    name: "auth-login___lb"
  }, {
    path: "/lb/auth/register",
    component: _4fb032d2,
    name: "auth-register___lb"
  }, {
    path: "/lb/auth/reset-password",
    component: _3ec6efec,
    name: "auth-reset-password___lb"
  }, {
    path: "/lb/blog/newest-recipes",
    component: _2d452c78,
    name: "blog-newest-recipes___lb"
  }, {
    path: "/lb/info/delivery",
    component: _a02722c2,
    name: "info-delivery___lb"
  }, {
    path: "/lb/info/faq",
    component: _db486e8a,
    name: "info-faq___lb"
  }, {
    path: "/lb/info/history",
    component: _410d368e,
    name: "info-history___lb"
  }, {
    path: "/lb/info/imprint",
    component: _7474f9ae,
    name: "info-imprint___lb"
  }, {
    path: "/lb/info/privacy-policy",
    component: _79735202,
    name: "info-privacy-policy___lb"
  }, {
    path: "/lb/info/terms-and-conditions",
    component: _0965bf9f,
    name: "info-terms-and-conditions___lb"
  }, {
    path: "/lb/info/who-we-are",
    component: _cdfbb4a2,
    name: "info-who-we-are___lb"
  }, {
    path: "/lb/newsletter/subscribe",
    component: _6720697a,
    name: "newsletter-subscribe___lb"
  }, {
    path: "/lb/search/results",
    component: _1c231cd6,
    name: "search-results___lb"
  }, {
    path: "/de/auth/register/details",
    component: _38f9be72,
    name: "auth-register-details___de"
  }, {
    path: "/de/checkout/payment/order-confirmed",
    component: _b35b8058,
    name: "checkout-payment-order-confirmed___de"
  }, {
    path: "/de/checkout/payment/payment-init",
    component: _5a231373,
    name: "checkout-payment-payment-init___de"
  }, {
    path: "/de/checkout/payment/payment-init-error",
    component: _3cb533ae,
    name: "checkout-payment-payment-init-error___de"
  }, {
    path: "/en/auth/register/details",
    component: _38f9be72,
    name: "auth-register-details___en"
  }, {
    path: "/en/checkout/payment/order-confirmed",
    component: _b35b8058,
    name: "checkout-payment-order-confirmed___en"
  }, {
    path: "/en/checkout/payment/payment-init",
    component: _5a231373,
    name: "checkout-payment-payment-init___en"
  }, {
    path: "/en/checkout/payment/payment-init-error",
    component: _3cb533ae,
    name: "checkout-payment-payment-init-error___en"
  }, {
    path: "/fr/auth/register/details",
    component: _38f9be72,
    name: "auth-register-details___fr"
  }, {
    path: "/fr/checkout/payment/order-confirmed",
    component: _b35b8058,
    name: "checkout-payment-order-confirmed___fr"
  }, {
    path: "/fr/checkout/payment/payment-init",
    component: _5a231373,
    name: "checkout-payment-payment-init___fr"
  }, {
    path: "/fr/checkout/payment/payment-init-error",
    component: _3cb533ae,
    name: "checkout-payment-payment-init-error___fr"
  }, {
    path: "/lb/auth/register/details",
    component: _38f9be72,
    name: "auth-register-details___lb"
  }, {
    path: "/lb/checkout/payment/order-confirmed",
    component: _b35b8058,
    name: "checkout-payment-order-confirmed___lb"
  }, {
    path: "/lb/checkout/payment/payment-init",
    component: _5a231373,
    name: "checkout-payment-payment-init___lb"
  }, {
    path: "/lb/checkout/payment/payment-init-error",
    component: _3cb533ae,
    name: "checkout-payment-payment-init-error___lb"
  }, {
    path: "/de/checkout/payment/saferpay/error",
    component: _32573478,
    name: "checkout-payment-saferpay-error___de"
  }, {
    path: "/de/checkout/payment/saferpay/success",
    component: _31ec96f3,
    name: "checkout-payment-saferpay-success___de"
  }, {
    path: "/en/checkout/payment/saferpay/error",
    component: _32573478,
    name: "checkout-payment-saferpay-error___en"
  }, {
    path: "/en/checkout/payment/saferpay/success",
    component: _31ec96f3,
    name: "checkout-payment-saferpay-success___en"
  }, {
    path: "/fr/checkout/payment/saferpay/error",
    component: _32573478,
    name: "checkout-payment-saferpay-error___fr"
  }, {
    path: "/fr/checkout/payment/saferpay/success",
    component: _31ec96f3,
    name: "checkout-payment-saferpay-success___fr"
  }, {
    path: "/lb/checkout/payment/saferpay/error",
    component: _32573478,
    name: "checkout-payment-saferpay-error___lb"
  }, {
    path: "/lb/checkout/payment/saferpay/success",
    component: _31ec96f3,
    name: "checkout-payment-saferpay-success___lb"
  }, {
    path: "/de/delivery-problem-contact/:id",
    component: _303b9e66,
    name: "delivery-problem-contact-id___de"
  }, {
    path: "/en/delivery-problem-contact/:id",
    component: _303b9e66,
    name: "delivery-problem-contact-id___en"
  }, {
    path: "/fr/delivery-problem-contact/:id",
    component: _303b9e66,
    name: "delivery-problem-contact-id___fr"
  }, {
    path: "/lb/delivery-problem-contact/:id",
    component: _303b9e66,
    name: "delivery-problem-contact-id___lb"
  }, {
    path: "/de/blog/:type?/category/:categoryId",
    component: _2d332277,
    name: "blog-type-category-categoryId___de"
  }, {
    path: "/en/blog/:type?/category/:categoryId",
    component: _2d332277,
    name: "blog-type-category-categoryId___en"
  }, {
    path: "/fr/blog/:type?/category/:categoryId",
    component: _2d332277,
    name: "blog-type-category-categoryId___fr"
  }, {
    path: "/lb/blog/:type?/category/:categoryId",
    component: _2d332277,
    name: "blog-type-category-categoryId___lb"
  }, {
    path: "/de/blog/:type?/post/:id?/:slug?",
    component: _6e60dab3,
    name: "blog-type-post-id-slug___de"
  }, {
    path: "/en/blog/:type?/post/:id?/:slug?",
    component: _6e60dab3,
    name: "blog-type-post-id-slug___en"
  }, {
    path: "/fr/blog/:type?/post/:id?/:slug?",
    component: _6e60dab3,
    name: "blog-type-post-id-slug___fr"
  }, {
    path: "/lb/blog/:type?/post/:id?/:slug?",
    component: _6e60dab3,
    name: "blog-type-post-id-slug___lb"
  }, {
    path: "/de/brands/:id?/:slug?",
    component: _910fdf10,
    name: "brands-id-slug___de"
  }, {
    path: "/de/categories/:id?/:slug?",
    component: _20057dc8,
    name: "categories-id-slug___de"
  }, {
    path: "/de/products/:id?/:slug?",
    component: _b8c5a2e0,
    name: "products-id-slug___de"
  }, {
    path: "/en/brands/:id?/:slug?",
    component: _910fdf10,
    name: "brands-id-slug___en"
  }, {
    path: "/en/categories/:id?/:slug?",
    component: _20057dc8,
    name: "categories-id-slug___en"
  }, {
    path: "/en/products/:id?/:slug?",
    component: _b8c5a2e0,
    name: "products-id-slug___en"
  }, {
    path: "/fr/brands/:id?/:slug?",
    component: _910fdf10,
    name: "brands-id-slug___fr"
  }, {
    path: "/fr/categories/:id?/:slug?",
    component: _20057dc8,
    name: "categories-id-slug___fr"
  }, {
    path: "/fr/products/:id?/:slug?",
    component: _b8c5a2e0,
    name: "products-id-slug___fr"
  }, {
    path: "/lb/brands/:id?/:slug?",
    component: _910fdf10,
    name: "brands-id-slug___lb"
  }, {
    path: "/lb/categories/:id?/:slug?",
    component: _20057dc8,
    name: "categories-id-slug___lb"
  }, {
    path: "/lb/products/:id?/:slug?",
    component: _b8c5a2e0,
    name: "products-id-slug___lb"
  }, {
    path: "/en/*",
    component: _d9038e6e,
    name: "all___en"
  }, {
    path: "/lb/*",
    component: _d9038e6e,
    name: "all___lb"
  }, {
    path: "/de/*",
    component: _d9038e6e,
    name: "all___de"
  }, {
    path: "/fr/*",
    component: _d9038e6e,
    name: "all___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
