
export default {
  name: "ProductPageNameAttribute",
  props: {
    productName: {
      type: String,
      required: true
    }
  }
}
