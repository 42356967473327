import Vue from 'vue';
import GlobalModal from "@/layouts/shop/components/tvmodalPlugin/GlobalModal";
import ModalBase from "@/layouts/shop/components/tvmodalPlugin/ModalBase";
import { spawn } from "@/plugins/tvModal/utils";

export default (ctx, inject) => {
  const modals = document.createElement('div')
  modals.setAttribute('id', 'modals')
  document.body.appendChild(modals)
  const ModalProgrammatic = {
    show (props) {
      if (typeof props === 'string') props = { title: props }
      return spawn('modals', props, GlobalModal, Vue)
    },
  }
  inject('modal', ModalProgrammatic)
  Vue.component('ModalBase', ModalBase)
}
