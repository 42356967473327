export default {
  methods: {
    /**
     * Returns the localized name of the week based on a number from 1-7.
     * @param number
     * @return string
     */
    getWeekDayByNumber(number) {
      switch (number) {
        case 1:
          return this.$t('datetime.weekdays.monday');
        case 2:
          return this.$t('datetime.weekdays.tuesday');
        case 3:
          return this.$t('datetime.weekdays.wednesday');
        case 4:
          return this.$t('datetime.weekdays.thursday');
        case 5:
          return this.$t('datetime.weekdays.friday');
        case 6:
          return this.$t('datetime.weekdays.saturday');
        case 7:
          return this.$t('datetime.weekdays.sunday');
        default:
          return '';
      }
    },

    syncDayjsLocale() {
      let locale = this.$i18n.locale;

      if(!["de", "fr", "en"].includes(locale)) {
        locale = "fr";
      }

      this.$dayjs.locale(locale);
    }
  }
};
