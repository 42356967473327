
import Banner from "@/layouts/shop/components/banners/Banner";
export default {
  name: "BannerList",
  components: { Banner },
  props: {
    listClasses: {
      type: String,
      default: 'flex flex-wrap gap-4'
    },
    bannerClasses: {
      type: String,
    },

    /**
     * Limits the amount of banners that are shown
     * Amount defined in "amount" prop
     */
    limit: {
      type: Boolean,
      default: true
    },
    amount: {
      type: Number,
      default: 1
    },

    placement: {
      type: String,
      default: "Main"
    }
  },
  computed: {
    getBanners() {
      let banners = this
        .$store.getters['pageData/getBannersForPage'](this.$pageDataHelper.getPageIdentifierByRoute(this.$route))
        .filter(b => b.placement === this.placement);

      return this.limit
        ? banners.slice(0, this.amount)
        : banners;
    }
  },
}
