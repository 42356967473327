
export default {
  name: "ProductCardInfoContents",
  props: {
    sellingBundle: {
      type: Object,
      required: true
    },
    textSizeClass: {
      type: String,
      default: 'text-sm'
    },
    iconSizeClass: {
      type: String,
      default: 'text-md'
    },
    textColorClass: {
      type: String,
      default: 'text-gray-500 dark:text-gray-300'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    showNet: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getSellingBundle() {
      return this.sellingBundle;
    },
    isContentVariable() {
      return this.getSellingBundle.isVariable || false;
    },
    getUnitString() {
      if (!this.getSellingBundle)
        return null;

      return (this.getSellingBundle.quantity > 1 ? this.getSellingBundle.quantity + 'x' : '') + this.$getUnitString(this.getSellingBundle.content, this.getSellingBundle.unit);
    },
    getNetWeight() {
      if (!this.getSellingBundle.netContent)
        return null;

      return this.$getUnitString(this.getSellingBundle.netContent, this.getSellingBundle.unit);
    },
  },
}
