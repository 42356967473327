/**
 * This file contains validation rules for form fields.
 * You can import new rules that can then be used in any form field
 * or extend the validator with your own rules.
 *
 * Documentation: https://vee-validate.logaretm.com/v3/guide/basics.html
 */

import {configure, extend} from 'vee-validate';
import { required, min, max, email } from 'vee-validate/dist/rules';


export default ({app}) => {
  let i18n = app.i18n;


  configure({
    defaultMessage: (field, values) => {
      // override the field name.
      values._field_ = i18n.t(`fields.${field}`);

      return i18n.t(`validation.${values._rule_}`, values);
    }
  });
}



/**
 * Requires that a value is set
 */
extend('required', {
  ...required,
});

/**
 * Required that the string contains at least one number.
 */
extend('street_and_number', value => {
  let regex = /.*[0-9].*/;
  if(regex.test(value)) {
    return true;
  }

  return false;
});

/**
 * Requires that the field contains a valid e-mail.
 */
extend('email', {
  ...email,
});

/**
 * Required that the field contains at least x characters.
 */
extend('min', {
  ...min,
});

/**
 * Requires that the field contains no more than x characters.
 */
extend('max', {
  ...max,
});


/**
 * Requires that the field contains either a signed or unsigned number.
 */
extend('luxembourgish_postal_code', value => {
  if(value.length === 4) {
    return true;
  }

  return false;
});


/**
 * Requires that the field contains only a number (with or without decimals).
 */
extend("decimal", {
  validate: (value, { decimals = 2, separator = ',' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    // noinspection Annotator
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    return {
      valid: regex.test(value),
    };
  },
  message: 'Veuillez entrer une numero.'
})


extend("password", {
  validate: (value) => {
    if(value === undefined || value === null) {
      value = "";
    }

    const hasCorrectLength = value.length >= 6;

    return {
      valid: hasCorrectLength
    }
  }
});

extend("vat_number", {
  validate: (value) => {
    if(value === undefined || value === null) {
      value = "";
    }

    const regex = /^[A-Z]{2}/;
    const hasCorrectFormat = regex.test(value);

    return {
      valid: hasCorrectFormat
    }
  }
});
