


import ProductFilterActive from "@/layouts/shop/components/filters/products/common/ProductFilterActive";
import ProductFilterMenu from "@/layouts/shop/components/filters/products/common/ProductFilterMenu";
import { FILTER_TYPE_ALLERGEN, FILTER_TYPE_LABEL } from "@/store/filters";
export default {
  name: "ProductFilterBar",
  components: { ProductFilterMenu, ProductFilterActive },
  props: {
    type: {
      default: "labels",
      validator: val => [
        FILTER_TYPE_LABEL,
        FILTER_TYPE_ALLERGEN
      ].includes(val)
    },
  },
  data: () => ({
    displayFilterCount: 2
  }),
  created() {
    this.$store.commit('filters/setMenuOpen', {
      type: this.type,
      open: false
    });
  },
  methods: {
    toggleMenu() {
      this.$store.commit('filters/setMenuOpen', {
        type: this.type,
        open: !this.isMenuOpen
      });
    },
    closeMenuIfOpened() {
      if(this.isMenuOpen) {
        this.$store.commit('filters/setMenuOpen', {
          type: this.type,
          open: false
        });
      }
    }
  },
  computed: {
    FILTER_TYPE_LABEL: () => FILTER_TYPE_LABEL,
    FILTER_TYPE_ALLERGEN: () => FILTER_TYPE_ALLERGEN,

    getActiveFilters() {
      return this.$store.getters["filters/getActiveFiltersWithProperties"].filter(f => f.type === this.type);
    },
    getAmountOfActiveFilters() {
      return this.getActiveFilters.length;
    },
    hasActiveFilters() {
      return this.getActiveFilters.length > 0;
    },
    getShownActiveFilters() {
      let filters = this.getActiveFilters;

      if(filters.length > this.displayFilterCount) {
        return filters.slice(0, this.displayFilterCount);
      }

      return filters;
    },
    getAmountOfNotShownFilters() {
      if(this.getActiveFilters.length > this.displayFilterCount) {
        return this.getActiveFilters.length - this.displayFilterCount;
      }
      return 0;
    },
    isMenuOpen() {
      switch (this.type) {
        case FILTER_TYPE_LABEL:
          return this.$store.getters["filters/isLabelMenuOpen"];
        case FILTER_TYPE_ALLERGEN:
          return this.$store.getters["filters/isAllergenMenuOpen"];
      }
    }
  }
}
