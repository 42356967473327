
import ShopButton from "../../components/buttons/ShopButton";
import ShopCart from "../../components/cart/ShopCart";
import ShopMobileCartBarDetails from "./ShopMobileCartBarDetails";
import ShopSkeleton from "../../components/skeleton/ShopSkeleton";
import ShopMobileCartBarActionButton from "@/layouts/shop/components/checkout/CheckoutContinueButton.vue";
import CheckoutContinueButton from "@/layouts/shop/components/checkout/CheckoutContinueButton.vue";
import {mapGetters} from "vuex";
export default {
  name: "ShopMobileCartBar",
  components: { CheckoutContinueButton, ShopMobileCartBarActionButton, ShopSkeleton, ShopMobileCartBarDetails, ShopCart, ShopButton },
  props: {
    showOpenCartButton: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    showCart: false
  }),
  mounted() {
    // Make Body Scrollable again if user changed page while Cart was shown
    if(document.body.classList.contains("overflow-y-hidden")) {
      this.makeBodyScrollAble();
    }
  },
  watch: {
    '$route' () {
      if(this.showCart) {
        this.closeCart();
      }
    }
  },
  computed: {
    ...mapGetters('checkout', [
      'isInCheckout'
    ])
  },
  methods: {
    toggleCart() {
      this.showCart = !this.showCart;
      return this.showCart ? this.prohibitBodyScroll() : this.makeBodyScrollAble();
    },
    closeCart() {
      this.showCart = false;
      this.makeBodyScrollAble()
    },
    makeBodyScrollAble() {
      document.body.classList.remove("overflow-y-hidden");
    },
    prohibitBodyScroll() {
      document.body.classList.add("overflow-y-hidden");
    }
  }
}
