export default {
    paymentMethods: {
        saferpay: 'saferpay',
        payconiq: 'payconiq',
        invoice: 'invoice',
        monthlyInvoice: 'monthlyInvoice',
        wallet: 'wallet',
    },
    products: {
        daysThresholdForProductToBeNew: 60,

        grid: {
          banners: {
            minBetween: 5,
            maxBetween: 8,
            patternSize: 6
          }
        }
    },
    order: {
        minimumOrderAmountByZone: {
            1: 5000,
            2: 5000,
            3: 5000,
            4: 5000,
            5: 5000,
            6: 5000,
            7: 10000,
            8: 10000,
            11: 5000,
        }
    },

    accountLinks: [
      {i18nPathLabel: 'account.nav.my_orders', icon: 'shopping-cart', routePath: {name: 'account-index-orders'}},
      {i18nPathLabel: 'account.nav.my_invoices', icon: 'file-alt', routePath: {name: 'account-index-invoices'}},
      {i18nPathLabel: 'account.nav.shopping_lists', icon: 'clipboard-list', routePath: {name: 'account-index-shopping-lists'}},
      {i18nPathLabel: 'account.nav.delivery_addresses', icon: 'shipping-fast', routePath: {name: 'account-index-delivery-address'}},
      {i18nPathLabel: 'account.nav.billing', icon: 'address-card', routePath: {name: 'account-index-profile-billing'}},
      {i18nPathLabel: 'account.nav.profile', icon: 'user', routePath: {name: 'account-index-profile'}},
      {i18nPathLabel: 'account.nav.payment_methods', icon: 'money-check', routePath: {name: 'account-index-payment-methods'}},
    ],

    footer: {
        enableAppStoreLinks: true,
    },
    jobOffersCategoryId: 5655,
    isMinimumOrderAmountReached(orderAmount, zone = 1) {
        return orderAmount >= this.getMinimumAmountForZone(zone);
    },
    getMinimumAmountForZone(zone = 1) {
        let min = 5000;

        if (this.order.minimumOrderAmountByZone[zone]) {
            min = this.order.minimumOrderAmountByZone[zone];
        }

        return min;
    },
    getTranslationKeyForPaymentMethod(method, inCheckout = false) {
        switch (method) {
            case "saferpay":
                if (inCheckout) {
                  return 'checkout.payment_method_cc_new';
                }
              return 'checkout.payment_method_cc';

          case "payconiq":
                return 'checkout.payment_method_payconiq';
            case "bankTransfer":
                return 'checkout.payment_method_bankTransfer';
            case "invoice":
                return 'checkout.payment_method_invoice';
            case "monthlyInvoice":
                return 'checkout.payment_method_monthly_invoice';
            case "wallet":
              return 'checkout.payment_method_wallet';
            case "paymentAdjustment":
              return 'checkout.payment_method_paymentAdjustment';
            default:
                return 'checkout.payment_method_no_selection';

        }
    },

    getPaymentMethodImage(method) {
        switch (method.toLowerCase()) {
            case "saferpay":
                return '~/assets/images/dummy/paymentmethods/credit-card.png';
            case "payconiq":
                return '~/assets/images/dummy/paymentmethods/payconiq.png';
            case "bankTransfer":
                return '~/assets/images/dummy/paymentmethods/invoice.png';
            case "invoice":
                return '~/assets/images/dummy/paymentmethods/invoice.png';
            case "monthlyInvoice":
                return '~/assets/images/dummy/paymentmethods/monthly-invoice.png';
            case "wallet":
                return '~/assets/images/dummy/paymentmethods/wallet.png';
            default:
                return null;
        }
    }
};
