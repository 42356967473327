
import LoadingSpinner from "@/layouts/shop/components/loading/LoadingSpinner";
import BlogPostImagePlaceholder from "@/layouts/shop/components/recipes/BlogPostImagePlaceholder";

export default {
  name: "ShopHeaderImage",
  components: { BlogPostImagePlaceholder, LoadingSpinner },
  props: {
    imageUrl: {
      type: String
    },
    roundedClass: {
      type: String,
      default: "rounded-xl"
    },
    shadow: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    imageLoaded: false,
  }),
  created() {
    if(this.imageUrl === null) {
      this.imageLoaded = true;
    }
  },
  methods: {
    onImageLoaded() {
      this.imageLoaded = true;
    }
  },
}
