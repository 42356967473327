

export default {
  name: "ChristmasNotificationBanners",
  computed: {
    isUserVip() {
      // Dynamically compute visibility based on $auth state
      return this.$auth.loggedIn && this.$auth.user?.isVip;
    },
    getUsername() {
      if ( this.$auth.user) {
        return this.$auth.user.firstName != null ? this.$auth.user.firstName : this.$auth.user.companyName
      }

      return '';
    }
  }
}
