import { render, staticRenderFns } from "./ShopCartGiftListItem.vue?vue&type=template&id=4b235018&scoped=true&"
import script from "./ShopCartGiftListItem.vue?vue&type=script&lang=js&"
export * from "./ShopCartGiftListItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b235018",
  null
  
)

export default component.exports