
import PaymentMethodImage
  from "@/layouts/shop/components/account/orders/single/orderTransactions/PaymentMethodImage.vue";
import configHelper from "@/helpers/configHelper.js";
import ShopCheckbox from "@/layouts/shop/components/forms/inputs/base/ShopCheckbox.vue";
import ShopCheckboxButton from "@/layouts/shop/components/forms/inputs/checkbox/ShopCheckboxButton.vue";

export default {
  name: "CheckoutPaymentSelectedMethod",
  components: {ShopCheckboxButton, ShopCheckbox, PaymentMethodImage},
  props: {
    paymentMethod: {
      type: String,
      required: true,
    },
    // Optionally, can be set when with paymentMethod === "saferpay"
    cardDetails: {
      type: Object,
      default: null
    },
    amountToPay: {
      type: Number,
      required: true
    }
  },
  watch: {
    paymentMethod: function () {
      this.saveNewCreditCard = true;
    }
  },
  computed: {
    getCreditCardType() {
      if(this.paymentMethod !== "saferpay" || this.cardDetails === null) {
        return null;
      }

      return this.cardDetails.paymentProvider;
    },
    getCardExpiryDate() {
      if(!this.cardDetails) {
        return null;
      }
      return this.cardDetails.expireMonth.toString().length === 1
        ? "0" + this.cardDetails.expireMonth.toString() + "." + this.cardDetails.expireYear
        : this.cardDetails.expireMonth + "." + this.cardDetails.expireYear;
    },
    getFormattedMaskedNumber() {
      if(!this.cardDetails) {
        return null;
      }

      const number = this.cardDetails.maskedNumber.toUpperCase().match(/.{1,4}/g);
      return number.join(' ');
    },
    getLocalizedPaymentMethod() {
      return this.$t(configHelper.getTranslationKeyForPaymentMethod(this.paymentMethod, true));
    },

    saveNewCreditCard: {
      set(bool) {
        this.$store.commit('checkout/setSaveNewCreditCard', bool);
      },
      get() {
        return this.$store.getters['checkout/getSaveNewCreditCard'];
      }
    }
  },
}
