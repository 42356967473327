
import ShopTextInputElement from "@/layouts/shop/components/forms/inputs/ShopTextInputElement.vue";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";

export default {
  name: "CheckoutPromoCodeForm",
  components: {ShopButton, ShopTextInputElement},
  data: () => ({
    promoCodeInput: null,
  }),
  mounted() {
    this.$refs.promoCodeInput.focus();
  },
  methods: {
    applyPromoCode() {
      if (this.isInputEmpty) {
        return false;
      }

      this.$store.dispatch('checkout/applyPromoCode', this.promoCodeInput);
    },
  },
  watch: {
    isPromoCodeApplied: function (newBool, oldBool) {
      if(newBool && !oldBool) {
        this.$emit('applied');
      }
    }
  },
  computed: {
    isInputEmpty() {
      return this.promoCodeInput === null || (typeof this.promoCodeInput === "string" && this.promoCodeInput.length === 0);
    },
    // Loading indicator while promo code is being applied.
    isApplyingPromoCode() {
      return this.$store.getters['checkout/getIsApplyingPromoCode'];
    },
    isPromoCodeApplied() {
      return this.$store.getters["cart/isPromoCodeApplied"];
    },
    getPromoCodeCartDate() {
      return {
        applied: this.$store.getters["cart/isPromoCodeApplied"],
        valid: this.$store.getters["cart/isPromoCodeValid"],
        campaignName: this.$store.getters["cart/getPromoCodeCampaignName"],
        code: this.$store.getters["cart/getPromoCode"],
      };
    }
  },
}
