
import ShopModal from "@/layouts/shop/components/modal/ShopModal";
import { mapGetters, mapMutations } from "vuex";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton";
import ShopInputElement from "@/layouts/shop/components/forms/inputs/base/ShopInputElement";
import ShoppingListModalList from "@/layouts/shop/components/buttons/product/shoppinglist/modal/ShoppingListModalList";
import LoadingSpinner from "@/layouts/shop/components/loading/LoadingSpinner";

export default {
  name: "ShoppingListModal",
  components: { LoadingSpinner, ShoppingListModalList, ShopInputElement, ShopButton, ShopModal },
  data: () => ({
    mode: "manage",
    newListName: '',
    errorMessage: '',
    loading: false
  }),
  computed: {
    ...mapGetters('shoppingLists', [
      'hasSelectedProduct',
      'hasSelectedCart'
    ]),
    getLists() {
      return this.$store.getters['shoppingLists/getShoppingLists'];
    },
  },
  watch: {
    mode: function (mode) {
      if(mode === 'createlist' || this.hasSelectedCart) {
        this.$nextTick().then(() => {
          this.$refs.createListInputElement.focus();
        })
      }
    },
  },
  methods: {
    ...mapMutations('shoppingLists', ['unselectProduct', 'unselectCart']),
    cleanupData() {
      if (this.$store.getters['shoppingLists/hasSelectedCart']) {
        this.$store.commit('shoppingLists/unselectCart');
      }

      this.unselectProduct();
    },
    closeModal() {
      this.mode = 'manage';
      this.newListName = '';
      this.errorMessage = '';

      this.$refs.modal.closeMe();
    },
    addList() {
      this.loading = true;
      this.errorMessage = '';

      this.$store.dispatch('shoppingLists/createList', this.newListName)
        .then(() => {
          this.newListName = '';
          this.mode = 'manage';
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            this.errorMessage = err.response.data.error;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createListFromCart() {
      this.loading = true;
      this.errorMessage = '';

      this.$store.dispatch('shoppingLists/createListFromCart', this.newListName)
        .then(() => {
          this.closeModal();
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            this.errorMessage = err.response.data.error;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
}
