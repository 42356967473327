
import CheckoutSelectionCard from "@/layouts/shop/components/checkout/common/CheckoutSelectionCard.vue";
import configHelper from "@/helpers/configHelper";
export default {
  name: 'CheckoutPaymentMethodSelection',
  components: { CheckoutSelectionCard },
  props: {
    type: {
      type: String,
      required: true
    },
    value: {
      type: [String]
    },
    img: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    showAsterisk: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    select() {
      if(this.disabled) {
        return;
      }
      this.$emit('input', this.type);
    }
  },
  computed: {
    isSelected() {
      return this.value === this.type;
    },
    getLabelByType() {
      return this.$t(configHelper.getTranslationKeyForPaymentMethod(this.type));
    }
  }
}
