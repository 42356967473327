export default (context, inject) => {
  inject('getUrlOfImage', (mediaObject, size = 'medium', type = 'primary') => {
    let imageUrl = null;

    if (Array.isArray(mediaObject) && mediaObject.length === 0) {
      return null;
    }

    if (mediaObject?.formats) {
      return mediaObject
        .formats
        ?.find(f => f.size === size)
        ?.url;
    }

    let pr = mediaObject?.image;
    if(pr && type in pr) {
      pr = pr[type];
    }

    if (Array.isArray(pr)) {
      if (pr[0]?.formats) {
        imageUrl = pr[0]
          .formats
          ?.find(f => f.size === size)
          ?.url;

      } else {
        return pr[0].url;
      }
    }


    if (imageUrl !== null) {
      return imageUrl;
    }

    return mediaObject
      ?.image
      ?.formats
      ?.find(f => f.size === size)
      ?.url;
  });


  inject('getLargestImageUrls', mediaObject => {
    return mediaObject.flatMap(img => {
      const large = img.formats.find(f => f.size === "large");
      const medium = img.formats.find(f => f.size === "medium");
      const small = img.formats.find(f => f.size === "small");

      if(large) {
        return large.url;
      }

      if(medium) {
        return medium.url;
      }

      return small.url;
    })
  });

  inject('getSmallestImageUrls', mediaObject => {
    return mediaObject.flatMap(img => {
      const small = img.formats.find(f => f.size === "small");
      const medium = img.formats.find(f => f.size === "medium");
      const large = img.formats.find(f => f.size === "large");

      if(small) {
        return small.url;
      }

      if(medium) {
        return medium.url;
      }

      return large.url;
    })
  });
};
