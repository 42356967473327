
import QuestionMarkButton from "@/layouts/shop/components/buttons/QuestionMarkButton.vue";

export default {
  name: "ShopCartFeeListItem",
  components: {QuestionMarkButton},
  props: {
    fee: {
      type: Object,
      required: true
    },
  },
  methods: {
    showFeeDescription() {
      if(!this.fee.description) {
        return false;
      }

      this.$modal.show({
        type: 'danger',
        title: this.fee.name,
        body: this.fee.description,
        primary: {
          label: this.$t('common.ok'),
          theme: 'danger',
          action: () => {}
        }
      });
    }
  },
}
