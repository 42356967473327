
import QuestionMarkButton from "@/layouts/shop/components/buttons/QuestionMarkButton.vue";

export default {
  name: "ShopCartGiftListItem",
  components: {QuestionMarkButton},
  props: {
    gift: {
      type: Object,
      required: true
    },
  },
  methods: {
    showGiftDescription() {
      if(!this.hasDescription) {
        return false;
      }

      this.$modal.show({
        type: 'success',
        title: this.gift.name,
        body: this.gift.description,
        primary: {
          label: this.$t('common.ok'),
          theme: 'primary',
          action: () => {}
        }
      });
    },
  },
  computed: {
    hasDescription() {
      return (this.gift?.description || null) !== null;
    },
    getPrice() {
      if ((this.gift.price?.amount ?? null) !== null) {
        return this.$moneyHelper.centsToEuroString(this.gift.price.amount);
      }

      return this.$moneyHelper.centsToEuroString(0);
    }
  },
}
