
export default {
  name: "TimeLineDateDot",
  props: {
    date: {
      type: String,
      required: true
    },
    direction: {
      type: String,
      default: 'left',
      validator: val => ['left', 'right'].includes(val)
    },
  }
}
