
import ProductCardShortDateBadge
  from "@/layouts/shop/components/products/ProductCardInfos/ProductCardImage/ProductCardShortDateBadge";
import ProductCardGiftCampaignBadge
  from "@/layouts/shop/components/products/ProductCardInfos/ProductCardImage/ProductCardGiftCampaignBadge";

export default {
  name: "ProductCardImage",
  components: { ProductCardGiftCampaignBadge, ProductCardShortDateBadge },
  props: {
    product: {
      type: Object,
      required: true
    },
    /**
     * If set to false, image doesn't have a fixed size.
     * This is used for promoted products.
     */
    doubleSize: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getImageUrl() {
      if(this.doubleSize) {
        return this.$getUrlOfImage(this.product.images, 'large');
      }
      return this.$getUrlOfImage(this.product.images);
    },
    getTagsToDisplay() {
      if (!this.product.tags || (Array.isArray(this.product.tags) && !this.product.tags.length))
        return [];

      return this.product.tags.filter(tag => tag.type === "label" && tag.image !== undefined && tag.image !== null);
    },
    getCampaigns() {
      return this.product.campaigns;
    },
    isShortDate() {
      return this.getCampaigns?.isShortDate ?? false;
    },
    hasCampaignsToDisplay() {
      return this.getCampaigns?.toDisplay?.length > 0 || false;
    },
    getCampaignsToDisplay() {
      if(!this.hasCampaignsToDisplay)
        return [];

      return this.getCampaigns.toDisplay;
    },
    isDiscounted() {
      return this.getCampaigns?.discount?.discountedPrice ?? false;
    },
  },
}
