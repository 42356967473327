
import LoadingSpinner from "../../../loading/LoadingSpinner";

export default {
  name: "AccountCard",
  components: { LoadingSpinner },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
  },
}
