
import LoadingSpinner from "@/layouts/shop/components/loading/LoadingSpinner";

export default {
  name: "PaymentInitializedSaferpayPreAuthorize",
  components: { LoadingSpinner },
  mounted() {
    this.$nextTick(() => {
      this.saveSaferPayTokenAndRedirect();
    });
  },
  methods: {
    saveSaferPayTokenAndRedirect() {
      if (!this.getPaymentInitResponse?.token) {
        return false;
      }

      window._localStorage.setItem('saferPayToken', this.getPaymentInitResponse.token);
      window.location.href = this.getPaymentInitResponse.redirectUrl;
    }
  },
  computed: {
    getPaymentInitResponse() {
      return this.$store.getters['checkout/getPaymentInitResponse'];
    }
  },
}
