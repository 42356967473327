import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_7d45084c from 'nuxt_plugin_plugin_7d45084c' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_0e678326 from 'nuxt_plugin_sentryserver_0e678326' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_aea3dcc4 from 'nuxt_plugin_sentryclient_aea3dcc4' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_nuxtcookiecontrol_0097afcc from 'nuxt_plugin_nuxtcookiecontrol_0097afcc' // Source: ./nuxt-cookie-control.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_a2a23dec from 'nuxt_plugin_cookieuniversalnuxt_a2a23dec' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_templatesplugina93b24b2_2a503039 from 'nuxt_plugin_templatesplugina93b24b2_2a503039' // Source: ./templates.plugin.a93b24b2.js (mode: 'client')
import nuxt_plugin_recaptcha_38df52d1 from 'nuxt_plugin_recaptcha_38df52d1' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_toast_0c089211 from 'nuxt_plugin_toast_0c089211' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_axios_3fd84562 from 'nuxt_plugin_axios_3fd84562' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_dayjsplugin_47f0213b from 'nuxt_plugin_dayjsplugin_47f0213b' // Source: ./dayjs-plugin.js (mode: 'all')
import nuxt_plugin_pluginutils_30b95919 from 'nuxt_plugin_pluginutils_30b95919' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_c46ff7b8 from 'nuxt_plugin_pluginrouting_c46ff7b8' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_0867f9cb from 'nuxt_plugin_pluginmain_0867f9cb' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_fontawesome_38290724 from 'nuxt_plugin_fontawesome_38290724' // Source: ./fontawesome.js (mode: 'all')
import nuxt_plugin_directivesclient_62899358 from 'nuxt_plugin_directivesclient_62899358' // Source: ../plugins/directives.client.js (mode: 'client')
import nuxt_plugin_mountclient_95e406fe from 'nuxt_plugin_mountclient_95e406fe' // Source: ../plugins/mount.client.js (mode: 'client')
import nuxt_plugin_veevalidate_1a0c1998 from 'nuxt_plugin_veevalidate_1a0c1998' // Source: ../plugins/vee-validate.js (mode: 'all')
import nuxt_plugin_axios_5659d192 from 'nuxt_plugin_axios_5659d192' // Source: ../plugins/axios.js (mode: 'all')
import nuxt_plugin_repositories_a519ad4e from 'nuxt_plugin_repositories_a519ad4e' // Source: ../plugins/repositories.js (mode: 'all')
import nuxt_plugin_filters_2dd71012 from 'nuxt_plugin_filters_2dd71012' // Source: ../plugins/filters.js (mode: 'all')
import nuxt_plugin_productHelper_126f42d0 from 'nuxt_plugin_productHelper_126f42d0' // Source: ../plugins/productHelper.js (mode: 'all')
import nuxt_plugin_imageHelper_bfd1f538 from 'nuxt_plugin_imageHelper_bfd1f538' // Source: ../plugins/imageHelper.js (mode: 'all')
import nuxt_plugin_moneyHelper_548d0f9f from 'nuxt_plugin_moneyHelper_548d0f9f' // Source: ../plugins/moneyHelper.js (mode: 'all')
import nuxt_plugin_pageDataHelper_142abffa from 'nuxt_plugin_pageDataHelper_142abffa' // Source: ../plugins/pageDataHelper.js (mode: 'all')
import nuxt_plugin_pageData_5cca1170 from 'nuxt_plugin_pageData_5cca1170' // Source: ../plugins/pageData.js (mode: 'all')
import nuxt_plugin_brevoTrackingclient_b0e45066 from 'nuxt_plugin_brevoTrackingclient_b0e45066' // Source: ../plugins/brevoTracking.client.js (mode: 'client')
import nuxt_plugin_modalclient_e430a95e from 'nuxt_plugin_modalclient_e430a95e' // Source: ../plugins/tvModal/modal.client.js (mode: 'client')
import nuxt_plugin_infiniteLoadingclient_a2a6261c from 'nuxt_plugin_infiniteLoadingclient_a2a6261c' // Source: ../plugins/infiniteLoading.client.js (mode: 'client')
import nuxt_plugin_panzoom_1b6b4afb from 'nuxt_plugin_panzoom_1b6b4afb' // Source: ../plugins/panzoom.js (mode: 'all')
import nuxt_plugin_auth_501c2f8e from 'nuxt_plugin_auth_501c2f8e' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_auth_7f7561ce from 'nuxt_plugin_auth_7f7561ce' // Source: ../plugins/auth.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"layout","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: function() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    return {
      title: this.$t('seo.default.title'),
      htmlAttrs: i18nHead.htmlAttrs,
      meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' },
      {
        hid: 'description',
        name: 'description',
        content: this.$t('seo.default.description') },

      { name: 'format-detection', content: 'telephone=no' },
      { name: 'msapplication-TileColor', content: '#da532c' },
      { name: 'theme-color', content: '#ffffff' },
      ...i18nHead.meta],

      script: [
      { src: "/local-storage-poorlyfill.js" }],

      link: [
      { rel: 'icon', type: 'image/x-icon', href: '/favicon/favicon.ico' },
      { rel: 'apple-touch-icon', sizes: '114x114', href: '/favicon/apple-touch-icon.png' },
      { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon/favicon-32x32.png' },
      { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon/favicon-16x16.png' },
      { rel: 'manifest', href: '/favicon/site.webmanifest', crossOrigin: 'use-credentials' },
      { rel: 'mask-icon', href: '/favicon/safari-pinned-tab.svg', color: '#5bbad5' },
      ...i18nHead.link] };
  },

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_7d45084c === 'function') {
    await nuxt_plugin_plugin_7d45084c(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_0e678326 === 'function') {
    await nuxt_plugin_sentryserver_0e678326(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_aea3dcc4 === 'function') {
    await nuxt_plugin_sentryclient_aea3dcc4(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtcookiecontrol_0097afcc === 'function') {
    await nuxt_plugin_nuxtcookiecontrol_0097afcc(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_a2a23dec === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_a2a23dec(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_templatesplugina93b24b2_2a503039 === 'function') {
    await nuxt_plugin_templatesplugina93b24b2_2a503039(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_38df52d1 === 'function') {
    await nuxt_plugin_recaptcha_38df52d1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_0c089211 === 'function') {
    await nuxt_plugin_toast_0c089211(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3fd84562 === 'function') {
    await nuxt_plugin_axios_3fd84562(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjsplugin_47f0213b === 'function') {
    await nuxt_plugin_dayjsplugin_47f0213b(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_30b95919 === 'function') {
    await nuxt_plugin_pluginutils_30b95919(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_c46ff7b8 === 'function') {
    await nuxt_plugin_pluginrouting_c46ff7b8(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_0867f9cb === 'function') {
    await nuxt_plugin_pluginmain_0867f9cb(app.context, inject)
  }

  if (typeof nuxt_plugin_fontawesome_38290724 === 'function') {
    await nuxt_plugin_fontawesome_38290724(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_directivesclient_62899358 === 'function') {
    await nuxt_plugin_directivesclient_62899358(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_mountclient_95e406fe === 'function') {
    await nuxt_plugin_mountclient_95e406fe(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_1a0c1998 === 'function') {
    await nuxt_plugin_veevalidate_1a0c1998(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_5659d192 === 'function') {
    await nuxt_plugin_axios_5659d192(app.context, inject)
  }

  if (typeof nuxt_plugin_repositories_a519ad4e === 'function') {
    await nuxt_plugin_repositories_a519ad4e(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_2dd71012 === 'function') {
    await nuxt_plugin_filters_2dd71012(app.context, inject)
  }

  if (typeof nuxt_plugin_productHelper_126f42d0 === 'function') {
    await nuxt_plugin_productHelper_126f42d0(app.context, inject)
  }

  if (typeof nuxt_plugin_imageHelper_bfd1f538 === 'function') {
    await nuxt_plugin_imageHelper_bfd1f538(app.context, inject)
  }

  if (typeof nuxt_plugin_moneyHelper_548d0f9f === 'function') {
    await nuxt_plugin_moneyHelper_548d0f9f(app.context, inject)
  }

  if (typeof nuxt_plugin_pageDataHelper_142abffa === 'function') {
    await nuxt_plugin_pageDataHelper_142abffa(app.context, inject)
  }

  if (typeof nuxt_plugin_pageData_5cca1170 === 'function') {
    await nuxt_plugin_pageData_5cca1170(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_brevoTrackingclient_b0e45066 === 'function') {
    await nuxt_plugin_brevoTrackingclient_b0e45066(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_modalclient_e430a95e === 'function') {
    await nuxt_plugin_modalclient_e430a95e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_infiniteLoadingclient_a2a6261c === 'function') {
    await nuxt_plugin_infiniteLoadingclient_a2a6261c(app.context, inject)
  }

  if (typeof nuxt_plugin_panzoom_1b6b4afb === 'function') {
    await nuxt_plugin_panzoom_1b6b4afb(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_501c2f8e === 'function') {
    await nuxt_plugin_auth_501c2f8e(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_7f7561ce === 'function') {
    await nuxt_plugin_auth_7f7561ce(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
