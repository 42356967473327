
import ProductPageCategoryItem from "@/layouts/shop/components/products/ProductPage/Category/ProductPageCategoryItem";
export default {
  name: "ProductPageCategoryList",
  components: { ProductPageCategoryItem },
  props: {
    categories: {
      type: Array
    }
  }
}
