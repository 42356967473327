
export default {
  name: "OrderTransactionType",
  props: {
    payedAmount: {
      type: Number,
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getClassesByAmount() {
      if (this.isZero) {
        return "bg-red-500 text-white";
      }

      if (this.isPositive) {
        return "bg-luxcaddy-500 text-white";
      }

      if (this.isNull) {
        return "bg-yellow-500 text-white";
      }

      if(this.isNegative) {
        return "bg-purple-500 text-white";
      }

    },
    isNegative() {
      return this.payedAmount < 0;
    },
    isZero() {
      return this.payedAmount  === 0;
    },
    isPositive() {
      return this.payedAmount  > 0;
    },
    isNull() {
      return this.payedAmount === null;
    },
    getText() {
      if(this.isPositive) {
        return this.$t('checkout.payed');
      }

      if(this.isNull) {
        return this.$t('checkout.authorized');
      }

      if(this.isZero) {
        return this.$t('checkout.cancelled');
      }
       if(this.isNegative) {
        return this.$t('checkout.refund');
      }

      return this.$t('checkout.payment');
    },
    getPadding() {
      if(this.small) {
        return "px-1.5 py-0.5";
      }

      return "px-3 py-1";
    },
    getTextSize() {
      if(this.small) {
        return "text-xs";
      }

      return "text-sm";
    }
  }
}
