
import ShopButton from "@/layouts/shop/components/buttons/ShopButton";
import AccountCard from "@/layouts/shop/components/account/sub/AccountCard/AccountCard";
import AccountCardSubText from "@/layouts/shop/components/account/sub/AccountCard/AccountCardSubText";
import AccountCardTitle from "@/layouts/shop/components/account/sub/AccountCard/AccountCardTitle";
export default {
  name: "ShoppingListListItem",
  components: { AccountCardTitle, AccountCardSubText, AccountCard, ShopButton },
  props: {
    list: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      addToCartLoading: false
    }
  },
  methods: {
    edit() {
      this.$router.push(
        this.localePath({
          name: 'account-index-shopping-lists-id-edit',
          params: {
            id: this.list.id
          }
        })
      )
    },
    addToCart(listId) {
      this.addToCartLoading = true;
      this.$shoppingListRepository.addToCart(listId)
        .then(() => this.$toast.success(this.$i18n.t('cart.add_all_to_cart')))
        .finally(() => { this.addToCartLoading = false; });
    },
  },
}
