
export default {
  name: "CheckoutCampaignSelection",
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  methods: {
    /**
     * Includes or excluded the campaign from the order.
     */
    toggleCampaign() {
      this.$store.commit('cart/toggleCampaignExclusion', this.campaign.id);
      this.$emit('change');
    }
  },
  computed: {
    isExcluded() {
      return this.$store.getters["cart/getExcludedCampaignsList"]
        .flatMap(c => c.id)
        .includes(this.campaign.id);
    }
  },
}
