import { render, staticRenderFns } from "./ShopInput.vue?vue&type=template&id=007af3d2&scoped=true&"
import script from "./ShopInput.vue?vue&type=script&lang=js&"
export * from "./ShopInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "007af3d2",
  null
  
)

export default component.exports