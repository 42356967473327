
import ShopButton from "../components/buttons/ShopButton";
import ShopNavMenu from "../partials/nav/ShopNavMenu";
import ShopNavLoginButton from "../partials/nav/ShopNavLoginButton";
import ShopNavRegisterButton from "../partials/nav/ShopNavRegisterButton";
import LogoSvg from "../svgs/LogoSvg";
import ShopNavUserButton from '../partials/nav/ShopNavUserButton'
import ShopMobileSidebar from "./ShopMobileSidebar";
import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';
import ShopSearchInput from "@/layouts/shop/partials/search/ShopSearchInput.vue";

export default {
  name: "ShopMobileTopNav",
  components: {
    ShopSearchInput,
    ShopNavUserButton,
    LogoSvg,
    ShopNavRegisterButton,
    ShopNavLoginButton,
    ShopNavMenu,
    ShopButton,
    ShopMobileSidebar
  },
  beforeDestroy() {
    clearAllBodyScrollLocks();
  },
  data: () => ({
    showNavLinks: false,
    showSearchBar: false,
    showBackButton: false,
  }),
  watch: {
    showNavLinks(bool) {
      return bool ? this.prohibitBodyScroll() : this.makeBodyScrollAble();
    },
    $route(val) {
      let routeName = val.name;

      // Hide Search bar when leaving search page
      if(routeName === null || !routeName.includes('search')) {
        this.showSearchBar = false;
      }

      // user enters index page
      if(['index___de', 'index___en', 'index___lb', 'index___fr'].includes(routeName)) {
        this.showBackButton = false;
      } else {
        this.showBackButton = true;
      }

      // Close menu when route changes
      this.showNavLinks = false;
    }
  },
  methods: {
    goBackInHistory() {
      this.$router.go(-1);
    },
    redirectToHome() {
      this.$router.push(this.localePath({ name: 'index' }));
    },
    toggleNavLinks() {
      this.showNavLinks = !this.showNavLinks;
      this.$emit('menutoggled');
    },
    toggleSearchBar() {
      this.showSearchBar = !this.showSearchBar;

      if(this.showSearchBar) {
        this.$nextTick(() => {
          if(this.$refs.searchBar && this.$refs.searchBar.$refs.input) {
            this.$refs.searchBar.$refs.input.focusInput();
          }
        })
      }
    },
    prohibitBodyScroll() {
      this.$nextTick(() => {
        disableBodyScroll(this.$refs.mobileMenu);
        document.body.style.width = '100%';
      });
    },
    makeBodyScrollAble() {
      enableBodyScroll(this.$refs.mobileMenu);
      document.body.style.removeProperty('width');
    },
  },
}
