
import SingleFaqInModalIcon from "@/layouts/shop/components/faq/ShopFaqSingle/SingleFaqInModalIcon.vue";
import CheckoutPackagingOption
  from "@/layouts/shop/components/checkout/packaging/sub/selection/CheckoutPackagingOptionMandatory.vue";
import CheckoutPackagingOptionMandatory
  from "@/layouts/shop/components/checkout/packaging/sub/selection/CheckoutPackagingOptionMandatory.vue";
import CheckoutPackagingOptionOptional
  from "@/layouts/shop/components/checkout/packaging/sub/selection/CheckoutPackagingOptionOptional.vue";

export default {
  name: "CheckoutPackagingSelectionMenu",
  components: {
    CheckoutPackagingOptionOptional,
    CheckoutPackagingOptionMandatory, CheckoutPackagingOption, SingleFaqInModalIcon
  },
  props: {
    type: {
      type: String,
      validator: val => ["mandatory", "optional"].includes(val)
    }
  },
  computed: {
    getPackagingGroups() {
      let packagingGroups = this.$store.getters["cart/getPackagingGroups"] ?? [];

      return packagingGroups.filter(group => {
        return this.type === "mandatory" ? group.isMandatory : !group.isMandatory;
      });
    }
  },
}
