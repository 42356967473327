
import SelectInput from "./SelectInput";

export default {
  name: "TitleSelectInput",
  components: {SelectInput},
  props: {
    value: {
      type: String,
    },
    customTitles: {
      type: Array,
      default: () => []
    },
    validationRules: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false
    },
    // This is required if two fields with the same label are used twice.
    // if no suffix is set, the first instance of that field will not be used.
    identifierSuffix: {
      type: String,
      default: null
    },
  },
  computed: {
    getTitles() {
      if (this.customTitles.length)
        return this.customTitles;

      return [
        {label: "---", value: null},
        {label: this.$t('common.title_mr'), value: 'M'},
        {label: this.$t('common.title_mrs'), value: 'F'},
        {label: this.$t('common.title_other'), value: 'Other'},
      ];
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
}
