
import AccountOrderItemSingle
  from "@/layouts/shop/components/account/orders/single/orderItems/AccountOrderItemSingle.vue";

export default {
  name: "AccountOrderItemsList",
  components: {AccountOrderItemSingle},
  props: {
    items: {
      type: Array,
      required: true,
    }
  },
}
