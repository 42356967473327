export const state = () => ({
  /**
   * Holds multiple Objects:
   * pageIdentifier: null|{}
   * banners: []
   */
  storedPages: [],

  openOrders: [],
  ordersWithDeliveryTracking: []
})

let comparePageIdentifier = function (identifierOne, identifierTwo) {
  return JSON.stringify(identifierOne) === JSON.stringify(identifierTwo);
}

let findStoredPageByPageIdentifier = function (state, pageIdentifier) {
  return state.storedPages.find(page => comparePageIdentifier(page.pageIdentifier, pageIdentifier));
}

/**
 * Sorts banners kind of randomly but taking their weight value into account.
 * The higher the weight of the banner, the more probable it is that it will be
 * first in list.
 *
 * Might have to look into a better sorting algorithm at a later point,
 * but for now this will do.
 * @param banners
 */
let sortBannersByProbability = function (banners) {
  const randomShuffleFn = () => Math.random() - 0.5;

  const shuffleFn = (candidateA, candidateB) =>
    Math.random() * (candidateB.weight + candidateA.weight) - candidateA.weight;

  return [...banners].sort(randomShuffleFn).sort(shuffleFn);
}

export const mutations = {
  resetOpenOrders(state) {
    state.openOrders = [];
  },

  resetOrdersWithDeliveryTracking(state) {
    state.ordersWithDeliveryTracking = [];
  },

  resetPageData(state) {
    state.storedPages = [];
  },

  resetPageDataExceptIdentifier(state, pageIdentifier) {
    state.storedPages = state.storedPages.filter(p => comparePageIdentifier(p.pageIdentifier, pageIdentifier));
  },

  addPageData(state, { pageIdentifier, banners, orders, ordersWithDeliveryTracking }) {
    const exisingPage = findStoredPageByPageIdentifier(state, pageIdentifier);

    // Only update banners when not already loaded so content
    // is not moving wildly around.
    if (!exisingPage) {
      state.storedPages.push({
        pageIdentifier,
        banners: sortBannersByProbability(banners)
      })
    }

    state.openOrders = orders;
    state.ordersWithDeliveryTracking = ordersWithDeliveryTracking;
  },
}

export const getters = {
  hasLoadedPageDataForIdentifier: (state) => (pageIdentifier) => findStoredPageByPageIdentifier(state, pageIdentifier) !== undefined,
  getBannersForPage: (state) => (pageIdentifier) => findStoredPageByPageIdentifier(state, pageIdentifier)?.banners || [],

  hasOpenOrders: state => state.openOrders.length > 0,
  getOpenOrders: state => state.openOrders,

  hasOrdersWithDeliveryTracking: state => state.ordersWithDeliveryTracking.length > 0,
  getOrdersWithDeliveryTracking: state => state.ordersWithDeliveryTracking,
}

export const actions = {
  fetchPageData({ commit }, pageIdentifier) {
    this.$pageDataRepository
      .getPageData(pageIdentifier)
      .then((res) => {
      if(res && res.data) {
        commit('addPageData', {
          pageIdentifier,
          banners: res.data?.banners || [],
          orders: res.data?.orders || [],
          ordersWithDeliveryTracking: res.data?.ordersWithTracking || [],
        });
      }
    });
  }
}
