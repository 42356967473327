
import ShopButton from "@/layouts/shop/components/buttons/ShopButton";
export default {
  name: "AccountOrderNotPaidAlert",
  components: { ShopButton },
  props: {
    orderId: {
      type: Number,
      required: true
    },
    orderStatus: {
      type: String,
      required: true
    },
  },
  methods: {
    navigateToPaymentPage() {
      this.$router.push(
        this.localePath({ name: 'account-index-orders-id-payment', params: { id: this.orderId } })
      );
    }
  }
}
