
import {mapGetters, mapMutations} from "vuex";
import ImageFullScreenModalTopBar
  from "@/layouts/shop/components/products/ProductPage/Image/Fullscreen/Sub/ImageFullScreenModalTopBar.vue";
import ImageFullScreenModalContainer
  from "@/layouts/shop/components/products/ProductPage/Image/Fullscreen/Sub/ImageFullScreenModalContainer.vue";

export default {
  name: "ImageFullScreenModal",
  components: {ImageFullScreenModalContainer, ImageFullScreenModalTopBar},
  props: {
    images: {
      type: Array,
      required: true
    },
    preselectedImage: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: null
    }
  },
  beforeDestroy() {
    this.$store.commit('imageModal/clearImages');
  },
  watch: {
    // Focus the modal on Open so you can close by using ESC
    isImageModalOpen: function (bool) {
      if(bool) {
        this.$nextTick(() => this.$refs.imageModal.focus());
      }
    },
    // When user selected an image on small version already, same image should show in Fullscreen Modal
    preselectedImage: function (imageIndex) {
      if(imageIndex !== null) {
        this.setCurrentImageAndNumber(
          this.$getLargestImageUrls(this.images)[imageIndex],
          imageIndex + 1
        );
      }
    }
  },
  head() {
    // Prevent scrolling when Modal open.
    return {
      bodyAttrs: {
        class: this.isImageModalOpen ? 'overflow-y-hidden' : ''
      },
      htmlAttrs: {
        class: this.isImageModalOpen ? 'overflow-y-hidden' : ''
      }
    }
  },
  created() {
    this.$store.commit('imageModal/setImages', this.images);
    this.$store.commit('imageModal/setModalTitle', this.title);
  },
  methods: {
    ...mapMutations('imageModal', [
      'setImageModelOpen'
    ]),
    setCurrentImageAndNumber(imageUrl, number) {
      this.$store.commit('imageModal/setCurrentImage', imageUrl);
      this.$store.commit('imageModal/setCurrentImageNumber', number);
    },
    /**
     * Allows to go to next / previous image
     * If last image + cycle next -> set to first image
     * If first image + cycle previous -> set to last image
     * @param amount
     * @returns {boolean}
     */
    cycleImage(amount) {
      let currentNumber = this.$store.getters['imageModal/getCurrentImageNumber'];
      let currentIndex = currentNumber === null ? null : currentNumber - 1;
      let availableImageCount = this.$store.getters["imageModal/getImagesCount"];

      if(currentIndex === null || availableImageCount <= 1) {
        return false;
      }

      let newIndex = currentIndex + amount;
      let highestAvailableIndex = availableImageCount - 1;

      if(newIndex > highestAvailableIndex) {
        newIndex = 0;
      }

      if(newIndex < 0) {
        newIndex = highestAvailableIndex;
      }

      this.setCurrentImageAndNumber(
        this.$getLargestImageUrls(this.images)[newIndex],
        newIndex + 1
      );
    }
  },
  computed: {
    ...mapGetters('imageModal', [
      'isImageModalOpen'
    ])
  },
}
