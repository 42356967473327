
import ShopButton from "../../ShopButton";
export default {
  name: "ShoppingListAddProductButton",
  components: { ShopButton },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    isProductOnAnyList() {
      return this.$store.getters['shoppingLists/isProductOnAnyList'](this.product.id);
    }
  }
}
