
export default {
  name: "CookieConsent",
  mounted() {
    if(process.browser) {
      window.addEventListener('marketing-cookies', (e) => {
        if(e.detail.enabled) {
          // Cookies for marketing accepted
          this.$fb.enable();
        } else {
          // Cookies for marketing refused
          this.$fb.disable();
        }
      })
    }
  },
}
