
import {mapGetters} from "vuex";
import configHelper from "@/helpers/configHelper.js";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";

export default {
  name: "CheckoutCartErrorCard",
  components: {ShopButton},
  watch: {
    isWarningShown: function (bool) {
      this.$nextTick(() => {
        if (bool && this.$refs.card) {
          this.$refs.card.scrollIntoView({block: "center", behavior: 'smooth'});
        }
      });
    }
  },
  computed: {
    ...mapGetters('checkout', [
      'isMinimumOrderAmountReached',
      'getDeliveryContacts',
      'getSelectedDeliveryContactId'
    ]),
    isWarningShown() {
      return (this.getSelectedDeliveryContact !== null && !this.hasReachedOrderAmount) || this.hasCartErrors;
    },
    hasReachedOrderAmount() {
      return this.isMinimumOrderAmountReached;
    },
    hasCartErrors() {
      return this.$store.getters["cart/getHasCartErrors"];
    },
    getSelectedDeliveryContact() {
      if(this.getSelectedDeliveryContactId === null) {
        return null;
      }

      return this.getDeliveryContacts?.find(d => d.id === this.getSelectedDeliveryContactId) || null;
    },
    getMinimumAmount() {
      if (!this.getSelectedDeliveryContact) {
        return null;
      }

      return this.$moneyHelper.centsToEuro(configHelper.getMinimumAmountForZone(this.getSelectedDeliveryContact.zoneId));
    }
  },
}
