import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faMedal as freeFasFaMedal,
  faMoneyCheck as freeFasFaMoneyCheck,
  faFilm as freeFasFaFilm,
  faBan as freeFasFaBan,
  faSearchPlus as freeFasFaSearchPlus,
  faTag as freeFasFaTag,
  faFileAlt as freeFasFaFileAlt,
  faAddressCard as freeFasFaAddressCard,
  faPercentage as freeFasFaPercentage,
  faCircleNotch as freeFasFaCircleNotch,
  faCircle as freeFasFaCircle,
  faBoxes as freeFasFaBoxes,
  faExclamationTriangle as freeFasFaExclamationTriangle,
  faWallet as freeFasFaWallet,
  faMinus as freeFasFaMinus,
  faClock as freeFasFaClock,
  faPhone as freeFasFaPhone,
  faLock as freeFasFaLock,
  faPencilAlt as freeFasFaPencilAlt,
  faTruckLoading as freeFasFaTruckLoading,
  faEnvelope as freeFasFaEnvelope,
  faLevelUpAlt as freeFasFaLevelUpAlt,
  faExclamationCircle as freeFasFaExclamationCircle,
  faExclamation as freeFasFaExclamation,
  faShoppingCart as freeFasFaShoppingCart,
  faCartPlus as freeFasFaCartPlus,
  faCog as freeFasFaCog,
  faCogs as freeFasFaCogs,
  faFileInvoice as freeFasFaFileInvoice,
  faEuroSign as freeFasFaEuroSign,
  faClipboardList as freeFasFaClipboardList,
  faEye as freeFasFaEye,
  faEyeSlash as freeFasFaEyeSlash,
  faPlus as freeFasFaPlus,
  faChevronLeft as freeFasFaChevronLeft,
  faChevronRight as freeFasFaChevronRight,
  faChevronUp as freeFasFaChevronUp,
  faCoffee as freeFasFaCoffee,
  faArrowLeft as freeFasFaArrowLeft,
  faArrowRight as freeFasFaArrowRight,
  faSignOutAlt as freeFasFaSignOutAlt,
  faLink as freeFasFaLink,
  faHome as freeFasFaHome,
  faHeadset as freeFasFaHeadset,
  faShieldAlt as freeFasFaShieldAlt,
  faMapMarkedAlt as freeFasFaMapMarkedAlt,
  faGift as freeFasFaGift,
  faUser as freeFasFaUser,
  faFrownOpen as freeFasFaFrownOpen,
  faChevronDown as freeFasFaChevronDown,
  faMoon as freeFasFaMoon,
  faSun as freeFasFaSun,
  faDesktop as freeFasFaDesktop,
  faShippingFast as freeFasFaShippingFast,
  faQuestion as freeFasFaQuestion,
  faQuestionCircle as freeFasFaQuestionCircle,
  faGlobe as freeFasFaGlobe,
  faSearch as freeFasFaSearch,
  faDotCircle as freeFasFaDotCircle,
  faCheck as freeFasFaCheck,
  faTrash as freeFasFaTrash,
  faInfoCircle as freeFasFaInfoCircle,
  faInfo as freeFasFaInfo,
  faSave as freeFasFaSave,
  faList as freeFasFaList,
  faListAlt as freeFasFaListAlt,
  faShoppingBag as freeFasFaShoppingBag,
  faStar as freeFasFaStar,
  faBars as freeFasFaBars,
  faTimes as freeFasFaTimes,
  faTimesCircle as freeFasFaTimesCircle,
  faUtensils as freeFasFaUtensils,
  faWeight as freeFasFaWeight
} from '@fortawesome/free-solid-svg-icons'

import {
  faFacebookF as freeFabFaFacebookF,
  faInstagram as freeFabFaInstagram,
  faBloggerB as freeFabFaBloggerB,
  faGooglePlay as freeFabFaGooglePlay,
  faApple as freeFabFaApple
} from '@fortawesome/free-brands-svg-icons'

import {
  faStar as freeFarFaStar,
  faCalendarTimes as freeFarFaCalendarTimes,
  faClock as freeFarFaClock,
  faUserCircle as freeFarFaUserCircle,
  faCalendar as freeFarFaCalendar
} from '@fortawesome/free-regular-svg-icons'

library.add(
  freeFasFaMedal,
  freeFasFaMoneyCheck,
  freeFasFaFilm,
  freeFasFaBan,
  freeFasFaSearchPlus,
  freeFasFaTag,
  freeFasFaFileAlt,
  freeFasFaAddressCard,
  freeFasFaPercentage,
  freeFasFaCircleNotch,
  freeFasFaCircle,
  freeFasFaBoxes,
  freeFasFaExclamationTriangle,
  freeFasFaWallet,
  freeFasFaMinus,
  freeFasFaClock,
  freeFasFaPhone,
  freeFasFaLock,
  freeFasFaPencilAlt,
  freeFasFaTruckLoading,
  freeFasFaEnvelope,
  freeFasFaLevelUpAlt,
  freeFasFaExclamationCircle,
  freeFasFaExclamation,
  freeFasFaShoppingCart,
  freeFasFaCartPlus,
  freeFasFaCog,
  freeFasFaCogs,
  freeFasFaFileInvoice,
  freeFasFaEuroSign,
  freeFasFaClipboardList,
  freeFasFaEye,
  freeFasFaEyeSlash,
  freeFasFaPlus,
  freeFasFaChevronLeft,
  freeFasFaChevronRight,
  freeFasFaChevronUp,
  freeFasFaCoffee,
  freeFasFaArrowLeft,
  freeFasFaArrowRight,
  freeFasFaSignOutAlt,
  freeFasFaLink,
  freeFasFaHome,
  freeFasFaHeadset,
  freeFasFaShieldAlt,
  freeFasFaMapMarkedAlt,
  freeFasFaGift,
  freeFasFaUser,
  freeFasFaFrownOpen,
  freeFasFaChevronDown,
  freeFasFaMoon,
  freeFasFaSun,
  freeFasFaDesktop,
  freeFasFaShippingFast,
  freeFasFaQuestion,
  freeFasFaQuestionCircle,
  freeFasFaGlobe,
  freeFasFaSearch,
  freeFasFaDotCircle,
  freeFasFaCheck,
  freeFasFaTrash,
  freeFasFaInfoCircle,
  freeFasFaInfo,
  freeFasFaSave,
  freeFasFaList,
  freeFasFaListAlt,
  freeFasFaShoppingBag,
  freeFasFaStar,
  freeFasFaBars,
  freeFasFaTimes,
  freeFasFaTimesCircle,
  freeFasFaUtensils,
  freeFasFaWeight,
  freeFabFaFacebookF,
  freeFabFaInstagram,
  freeFabFaBloggerB,
  freeFabFaGooglePlay,
  freeFabFaApple,
  freeFarFaStar,
  freeFarFaCalendarTimes,
  freeFarFaClock,
  freeFarFaUserCircle,
  freeFarFaCalendar
)

config.autoAddCss = false

Vue.component('faIcon', FontAwesomeIcon)
Vue.component('faIconLayers', FontAwesomeLayers)
Vue.component('faIconLayersText', FontAwesomeLayersText)
