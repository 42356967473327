
export default {
  name: "ListGroupNavItem",
  props: {
    to: {
      type: [Object, String],
      required: false
    },
    executeFuncOnClick: {
      type: Function,
      required: false
    },
    icon: {
      type: [String, Array],
      required: true
    },
    isActiveProp: {
      type: Boolean,
      default: false
    },
    isActiveClasses: {
      type: String,
      default: 'bg-gray-100 dark:bg-luxcaddy-300 dark:text-gray-700'
    },
    isNotActiveClasses: {
      type: String,
      default: 'hover:bg-gray-50 dark:hover:bg-luxcaddy-300 dark:hover:text-gray-900'
    },
    showChevron: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    navigate() {
      if(!this.to) {
        this.executeFuncOnClick();
        return;
      }

      this.$router.push(this.to);
    }
  },
  computed: {
    isActive() {
      if(!this.to) {
        return this.isActiveProp;
      }

      return this.$route.path === this.localePath(this.to);
    }
  },
}
