
import ShopCard from "@/layouts/shop/components/cards/ShopCard.vue";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";
import CheckoutTitle from "@/layouts/shop/components/checkout/common/ui/CheckoutTitle.vue";
import SingleFaqInModalIcon from "@/layouts/shop/components/faq/ShopFaqSingle/SingleFaqInModalIcon.vue";
import CheckoutPackagingSelectionModal
  from "@/layouts/shop/components/checkout/packaging/sub/CheckoutPackagingSelectionModal.vue";
import CheckoutPackagingSelectedBagsList
  from "@/layouts/shop/components/checkout/packaging/sub/selected/CheckoutPackagingSelectedBagsList.vue";
import CheckoutPackagingSelectionMenu
  from "@/layouts/shop/components/checkout/packaging/sub/CheckoutPackagingSelectionMenu.vue";

export default {
  name: "CheckoutPackagingStep",
  components: {
    CheckoutPackagingSelectionMenu,
    CheckoutPackagingSelectedBagsList,
    CheckoutPackagingSelectionModal, SingleFaqInModalIcon, CheckoutTitle, ShopButton, ShopCard
  },
  props: {
    type: {
      type: String,
      validator: val => ["mandatory", "optional"].includes(val)
    },
  },
  methods: {
    openSelectModal() {
      this.$refs.selectPackagingModal.openModal();
    }
  },
  computed: {
    shouldStepBeShown() {
      if(this.type === "mandatory") {
        return true;
      }

      // Hide Step if there is no selection on optional bags for the user.
      return (this.$store.getters["cart/getPackagingGroups"] ?? []).filter(group => !group.isMandatory).length !== 0;
    },
    isConditionMet() {
      if (!this.isMandatory) {
        return true;
      }

      return this.getSelectedBagsByGroupType.length > 0;
    },
    getSelectedBagsByGroupType() {
      const mandatoryGroupBags = (this.$store.getters["cart/getPackagingGroups"] ?? [])
        .filter(group => group.isMandatory)
        .flatMap(group => group.options);

      if(!mandatoryGroupBags) {
        return [];
      }

      const bagIds =  this.$store.getters["checkout/getFilteredSelectedBags"].flatMap(bag => bag.id);

      return mandatoryGroupBags.filter(option => bagIds.includes(option.id));
    },
    isMandatory() {
      return this.type === "mandatory";
    }
  }
}
