
import ShopButton from "../buttons/ShopButton";
import ShopCart from "./ShopCart";
export default {
  name: "ShopCartButtonWithOverlay",
  components: { ShopCart, ShopButton },
  data: () => ({
    cartShown: false
  }),
  methods: {
    onClickOutside() {
      this.cartShown = false;
    }
  },
  computed: {
    getTotalAmount() {
      return this.$auth.loggedIn
        ? this.$store.getters["cart/getTotals"]?.amount || 0
        : this.$store.getters["guestCart/getTotals"]?.amount || 0;
    },
  },
}
