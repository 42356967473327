
export default {
  name: "ImageFullScreenModalSingleImage",
  data() {
    return {
      panZoomInstance: null
    }
  },
  props: {
    imageUrl: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.resetZoom();
  },
  watch: {
    imageUrl: function () {
     this.resetZoom();
    }
  },
  methods: {
    onInit: function(panzoomInstance) {
      this.panZoomInstance = panzoomInstance;
    },
    resetZoom() {
      if(this.panZoomInstance) {
        this.$nextTick(() => {
          this.panZoomInstance.zoomAbs( 0, 0, 0.1);
          this.panZoomInstance.moveTo( 0, 0);

          // Sometimes, zoom reset is not working instantly.
          this.$nextTick(() => {
            this.panZoomInstance.zoomAbs( 0, 0, 0.1);
            this.panZoomInstance.moveTo( 0, 0);
          })
        })
      }
    }
  },
  computed: {
    getPanZoomOptions() {
      return {
        minZoom: 1,
        maxZoom: 2.5,
        bounds: true,
        boundsPadding: 0.25,
      };
    }
  },
}
