
export default {
  name: "BannerLink",
  props: {
    banner: { type: Object, required: true }
  },
  methods: {
    sendGaEvent() {
      if(process.client && window && typeof window.gtag === 'function') {
        window.gtag('event', 'click_banner', {
          'banner_id': this.banner.id,
          'event_category': 'banner_click',
        });
      }
    }
  },
  computed: {
    isExternalLink() {
      return this.banner.link.url !== undefined;
    },
    getExternalLink() {
      if(!this.isExternalLink) {
        return null;
      }
      return this.banner.link.url;
    },
    getInternalLink() {
      if(this.isExternalLink) {
        return null;
      }

      return this.localePath({
        name: this.$pageDataHelper.getRouteNameByPageIdentifier(this.banner.link),
        params: this.banner.link.params
      })
    }
  }
}
