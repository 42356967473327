
import CheckoutAddressSelection from '@/layouts/shop/components/checkout/deliveryAddress/CheckoutAddressSelection.vue'
import CheckoutContinueButton from "@/layouts/shop/components/checkout/CheckoutContinueButton.vue";
import DeliveryContactCreateButton
  from "@/layouts/shop/components/account/deliveryContacts/AccountAreaCreateButton.vue";
import ShopCard from "@/layouts/shop/components/cards/ShopCard.vue";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";
import ShopModal from "@/layouts/shop/components/modal/ShopModal.vue";
import CheckoutTimeslotSelector
  from "@/layouts/shop/components/checkout/timeSlot/selector/CheckoutTimeslotSelector.vue";
import CheckoutTimeslotDiscountCampaign
  from "@/layouts/shop/components/checkout/timeSlot/selector/sub/CheckoutTimeslotDiscountCampaign.vue";
import {getTimeslotWithMatchingTime} from "@/helpers/checkout/timeslotHelper.js";
import CheckoutTitle from "@/layouts/shop/components/checkout/common/ui/CheckoutTitle.vue";

export default {
  name: 'CheckoutTimeslotStep',
  components: {
    CheckoutTitle,
    CheckoutTimeslotDiscountCampaign,
    CheckoutTimeslotSelector,
    ShopModal,
    ShopButton,
    ShopCard,
    DeliveryContactCreateButton,
    CheckoutContinueButton,
    CheckoutAddressSelection
  },
  watch: {
    // Zone changed because user selected different contact
    // -> load new contacts
    getZoneByDeliveryContact: {
      immediate: true,
      handler(zone) {
        if (zone === null) {
          this.$store.commit('checkout/setTimeslots', null);
          this.$emit('loaded');
          return null;
        }

        // Select current timeslot, since this is gonna be null after we loaded new zone timeslots
        const currentTimeSlot = this.getSelectedTimeslot || null;

        return this.$store
          .dispatch('checkout/fetchTimeslots', zone)
          .then(() => {
            // User had already a timeslot selected
            if (currentTimeSlot) {
              const allTimeSlots = this.getAllTimeSlots;

              // We check if one of the new timeslots has the same data and select that one
              const newTimeslot = getTimeslotWithMatchingTime(allTimeSlots, currentTimeSlot.startDate, currentTimeSlot.endDate);
              this.$store.commit('checkout/setSelectedTimeslotId', newTimeslot?.id || null);

              // No matching timeslot, inform user he needs to reselect
              if(newTimeslot === null) {
                this.$modal.show({
                  type: 'danger',
                  title: this.$t('checkout.timeSlotDeliveryAddressChanged.title'),
                  body: this.$t('checkout.timeSlotDeliveryAddressChanged.text'),
                  primary: {
                    label: this.$t('common.confirm'),
                    theme: 'danger',
                    action: () => {}
                  },
                });
              }
            }
          })
          .finally(() => {
            this.$emit('loaded');
          });
      }
    }
  },
  methods: {
    unselectTimeslot() {
      this.$store.commit('checkout/setSelectedTimeslotId', null);
    }
  },
  mounted() {
    if(this.getZoneByDeliveryContact === null) {
      this.$emit('loaded');
    }
  },
  computed: {
    hasSelectedDeliveryContact(){
      return this.$store.getters['checkout/hasSelectedDeliveryContact'];
    },
    getZoneByDeliveryContact() {
      const zone = this.$store.getters['checkout/getZoneByDeliveryContact'];

      // Invalid postal code probably, return zone 1
      if(this.hasSelectedDeliveryContact && zone === null) {
        return 1;
      }

      return zone;
    },
    getSelectedTimeslotId() {
      return this.$store.getters['checkout/getSelectedTimeslotId'];
    },
    getSelectedTimeslot() {
      return this.$store.getters['checkout/getSelectedTimeslot'];
    },
    getAllTimeSlots() {
      return this.$store.getters["checkout/getTimeslots"];
    }
  },
}
