
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";
import AddModifyCartQuantityProductButton
  from "@/layouts/shop/components/buttons/product/cart/AddModifyCartQuantityProductButton.vue";

export default {
  name: "ProductCartButtons",
  components: { AddModifyCartQuantityProductButton, ShopButton},
  props: {
    product: {
      type: Object,
      required: true
    },
    algoliaQueryId: {
      type: String,
      default: null
    },
    forBlog: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    canBeBought() {
      return this.product.canBeBought;
    },
  },
}
