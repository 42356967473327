
import ProductCardInfoBadge from "@/layouts/shop/components/products/ProductCardInfos/ProductCardInfoBadge";
export default {
  name: "ProductCardInfoDeliveryTimeBadge",
  components: { ProductCardInfoBadge },
  props: {
    deliveryTime: {
      type: Number,
      required: true
    }
  }
}
