
import ShopCard from "@/layouts/shop/components/cards/ShopCard.vue";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";
import CheckoutTitle from "@/layouts/shop/components/checkout/common/ui/CheckoutTitle.vue";
import CheckoutPaymentStepReview
  from "@/layouts/shop/components/checkout/payment/sub/CheckoutPaymentStepReview.vue";
import {mapGetters} from "vuex";
import CheckoutPaymentMethodSelectionModal
  from "@/layouts/shop/components/checkout/payment/sub/selection/CheckoutPaymentMethodSelectionModal.vue";
import SingleFaqInModalIcon from "@/layouts/shop/components/faq/ShopFaqSingle/SingleFaqInModalIcon.vue";
import CheckoutPaymentPromoCodeModal
  from "@/layouts/shop/components/checkout/payment/sub/promocode/CheckoutPaymentPromoCodeModal.vue";
import CheckoutPromoCodeButton
  from "@/layouts/shop/components/checkout/payment/sub/promocode/CheckoutPromoCodeButton.vue";
import TextBannerList from "@/layouts/shop/components/banners/Text/TextBannerList.vue";

export default {
  name: "CheckoutPaymentStep",
  components: {
    TextBannerList,
    CheckoutPromoCodeButton,
    CheckoutPaymentPromoCodeModal,
    SingleFaqInModalIcon,
    CheckoutPaymentMethodSelectionModal, CheckoutPaymentStepReview, CheckoutTitle, ShopButton, ShopCard
  },
  created() {
    // Set default
    const defaultPaymentMethod = this.$store.getters["checkout/getDefaultPaymentMethod"];
    this.$store.commit('checkout/setSelectedPaymentMethod', defaultPaymentMethod);

    if(defaultPaymentMethod === "saferpay") {
      this.$store.commit('checkout/setSelectedCreditCardDetails', this.$store.getters["checkout/getDefaultCardDetails"]);
    }
  },
  data() {
    return {
      showPaymentSelectionModal: false,
      showPromoCodeModal: false,
      isChangeButtonLoading: false
    }
  },
  methods: {
    onClickChange() {
      this.isChangeButtonLoading = true;
      this.$store.dispatch('checkout/fetchSavedCreditCards').then(() => {
        this.isChangeButtonLoading = false;
        this.$nextTick(() => this.showPaymentSelectionModal = true);
      });
    }
  },
  computed: {
    ...mapGetters('checkout', [
      'getSelectedPaymentMethod'
    ]),
    hasSelectedPaymentMethod() {
      return this.getSelectedPaymentMethod !== null;
    }
  },
  mounted() {
    this.$emit('loaded');
  },
}
