
export default {
  name: "DeliveryRefreshTimer",
  data: () => ({
    lastRefreshSecondsInterval: null,
    secondsSinceLastRefresh: 0,

    timeLimit: 30,
    timeLeft: 30,
  }),
  beforeDestroy() {
    clearInterval(this.lastRefreshSecondsInterval);
    this.lastRefreshSecondsInterval = null;
  },
  mounted() {
    // Increase counter every sec, refresh when counter reaches 30 seconds
    let vm = this;
    this.lastRefreshSecondsInterval = setInterval(function () {
      vm.secondsSinceLastRefresh++;

      vm.timeLeft = vm.timeLimit - vm.secondsSinceLastRefresh;
      if (vm.getSecondsUntilRefresh === 0) {
        vm.$emit('refresh');
        vm.secondsSinceLastRefresh = 0;
      }

    }, 1000);
  },
  computed: {
    remainingPathColor() {
        return "green"
    },
    setCircleDasharray() {
      return `${(this.timeFraction * 283).toFixed(0)} 283`;
    },

    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.timeLimit

      return rawTimeFraction -
        (1 / this.timeLimit) * (1 - rawTimeFraction)
    },
    getSecondsUntilRefresh() {
      // Update every 30 seconds
      return this.timeLeft;
    }
  },
}
