
import ShopModal from "@/layouts/shop/components/modal/ShopModal.vue";

export default {
  name: "ShopCartItemFixQuantityModal",
  components: {ShopModal},
  props: {
    productId: {
      type: Number,
      default: null
    },
    maxAvailableStock: {
      type: Number,
      default: 99
    }
  },
}
