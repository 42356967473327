
export default {
  name: 'CheckoutSelectionCard',
  computed: {
    getClasses() {
      if(this.disabled) {
        return 'border-gray-200 opacity-40';
      }

      let classes = 'transform transition lg:hover:-translate-y-1 cursor-pointer ';

      return this.isSelected
        ? classes + 'bg-luxcaddy-50 border-luxcaddy-500'
        : classes + 'border-gray-200 hover:border-gray-400';
    }
  },
  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    inputName: {
      type: String,
      required: true,
      default: ""
    },
    inputValue: {
      type: [Number, String],
    },
    showRadio: {
      type: Boolean,
      default: true
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
  },
}
