
import ShopCartGiftListItem from "@/layouts/shop/components/cart/includes/gifts/ShopCartGiftListItem.vue";

export default {
  name: "ShopCartGiftList",
  components: { ShopCartGiftListItem },
  computed: {
    getCampaigns() {
      return this.$auth.loggedIn
        ? this.$store.getters["cart/getCampaigns"]
        : this.$store.getters["guestCart/getCampaigns"];
    },
  },
}
