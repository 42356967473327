
import QuestionMarkButton from "@/layouts/shop/components/buttons/QuestionMarkButton.vue";

export default {
  name: "CheckoutTimeslotDiscountCampaign",
  components: {QuestionMarkButton},
  props: {
    timeslot: {
      type: Object,
      default: () => ({})
    },
    includeStar: {
      type: Boolean,
      default: false
    },
    textSizeClass: {
      type: String,
      default: "text-sm"
    },
    enableCampaignDescriptionButton: {
      type: Boolean,
      default: true
    },
    displayCampaignDescriptionImmediately: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    showCampaignDescription(campaign) {
      if(campaign.description === null || !this.enableCampaignDescriptionButton) {
        this.$emit('click-timeslot');
        return false;
      }

      this.$modal.show({
        type: 'success',
        title: campaign.name,
        body: campaign.description,
        primary: {
          label: this.$t('common.ok'),
          theme: 'primary',
          action: () => {}
        }
      });
    }
  },
  computed: {
    getDiscountOrFixedCampaigns() {
      return (this.timeslot.loadedCampaigns || []).filter(c => ["fixed", "discount"].includes(c.type.toLowerCase()));
    },
    hasDiscountOrFixedCampaign() {
      return this.getDiscountOrFixedCampaigns.length > 0;
    }
  },
}
