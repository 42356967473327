
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";

export default {
  name: "TextBanner",
  components: {ShopButton},
  props: {
    banner: {
      type: Object,
      required: true
    }
  },
  methods: {
    navigateToBannerLink() {
      if (!this.hasLink) {
        return;
      }
      if(this.banner.link?.url) {
        window.open(this.banner.link.url, '_blank').focus();
      } else {
        this.$router.push(
          this.localePath({
            name: this.$pageDataHelper.getRouteNameByPageIdentifier(this.banner.link),
            params: this.banner.link.params
          })
        );
      }
    }
  },
  computed: {
    hasLink() {
      return this.banner.link.length !== 0;
    },

    isInfo() {
      return this.banner.weight <= 33;
    },
    isWarning() {
      return this.banner.weight > 33 && this.banner.weight <= 66;
    },
    isDanger() {
      return this.banner.weight > 66;
    },
    getBoxClasses() {
      if (this.isInfo) {
        return 'bg-white border-gray-400';
      }
      if (this.isWarning) {
        return 'bg-white border-yellow-400';
      }
      if (this.isDanger) {
        return 'bg-white border-red-400';
      }
    },
    getIconBoxColor() {
      if (this.isInfo) {
        return 'bg-gray-400';
      }
      if (this.isWarning) {
        return 'bg-yellow-400';
      }
      if (this.isDanger) {
        return 'bg-red-400';
      }
    },
    getIcon() {
      if (this.isInfo) {
        return 'info';
      }
      if (this.isWarning || this.isDanger) {
        return 'exclamation';
      }
    }
  },
}
