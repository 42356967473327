
export default {
  name: "ShopInputErrorMessage",
  props: {
    errors: {
      type: Array,
      required: true
    }
  },
  computed: {
  }
}
