
import SameLevelCategoryBarCategory from "@/layouts/shop/components/categories/bar/SameLevelCategoryBarCategory.vue";

export default {
  name: "SameLevelCategoryBar",
  components: {SameLevelCategoryBarCategory},
  props: {
    currentCategoryId: {
      type: Number,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    getCategories() {
      return this.categories;
    },
  },
  methods: {
    onActiveChildMounted(scrollLeftValue) {
      this.$refs.bar.scrollLeft = scrollLeftValue;
    },
  },
}
