
export default {
  name: "ProductPriceDisplay",
  props: {
    product: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      validator: val => ['default', 'big', 'small'].includes(val)
    },
    centerOnMobile: {
      type: Boolean,
      default: false
    },
    wrapperClasses: {
      type: String,
      default: "flex items-center space-x-2"
    },
  },
  computed: {
    getTextClass() {
      switch (this.size) {
        case "big":
          return 'text-3xl'
        case "small":
          return 'text-lg';
        default:
          return "text-xl";
      }
    },

    getSellingData() {
      return this.product.sellingData;
    },
    getSellingPrice() {
      return this.getSellingData.price;
    },
    getCampaigns() {
      return this.product.campaigns;
    },
    isDiscounted() {
      return this.getCampaigns?.discountedPrice ?? false;
    },
    getDiscountedPrice() {
      if (!this.isDiscounted)
        return null;

      return this.getCampaigns.discountedPrice;
    },
    getLowestPriceLast30Days() {
      if (!this.isDiscounted)
        return null;

      return this.getCampaigns.lowestPriceInLast30Days;
    },
  },
}
