
import TimeLineDateDot from "@/layouts/shop/components/timeline/TimeLineDateDot";

export default {
  name: "TimeLineCard",
  components: { TimeLineDateDot },
  props: {
    direction: {
      type: String,
      default: 'left',
      validator: val => ['left', 'right'].includes(val)
    },
    date: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: null
    }
  },
  computed: {
    isLeftDirection() {
      return this.direction === "left";
    }
  },
}
