
export default {
  name: "OrderListItemStatus",
  props: {
    status: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getClassesByStatus() {
      switch(this.status) {
        case "initialized":
          return "bg-red-500 text-white";
        case "canceled":
          return "bg-red-700 text-red-200";
        case "confirmed":
          return "bg-amber-400 text-black";
        case "inFulfilment":
          return "bg-violet-400 text-white";
        case "fulfilmentFinalized":
          return "bg-sky-500 text-white";
        case "delivered":
          return "bg-luxcaddy-500 text-white";
        case "enRoute":
          return "bg-pink-500 text-white";
      }
    },
    getTextByStatus() {
        if(this.$te('account.orders.statuses.' + this.status)){
          return this.$t('account.orders.statuses.' + this.status);
        }
        return 'Unknown status';
    },
    getPadding() {
      if(this.small) {
        return "px-3 py-1";
      }
      return "text-sm md:text-md px-2 py-1 md:px-4 md:py-2";
    },
    getTextSize() {
      if(this.small) {
        return "text-sm";
      }
      return "text-lg";
    }
  }
}
