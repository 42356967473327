
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";

export default {
  name: "GoogleTranslateBox",
  components: {ShopButton},
  props: {
    /**
     * The option to translate is by default only shown when the users
     * set langauge is either english or german.
     */
    shownForLanguages: {
      type: Array,
      default: () => ["en", "de"]
    }
  },
  methods: {
    openPageInTranslate() {
      window.open(
        `https://translate.google.com/translate?sl=fr&tl=en&u=${this.getFullPath}`,
        '_blank'
      ).focus();
    }
  },
  computed: {
    getShouldShow() {
      return this.shownForLanguages.includes(this.$i18n.locale);
    },
    getFullPath() {
      if(!process.client)
        return "";

      return window.location.href;
    }
  },
}
