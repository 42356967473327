
import ShopCard from "@/layouts/shop/components/cards/ShopCard";
export default {
  name: "AccountAreaCreateButton",
  components: { ShopCard },
  props: {
    labelKey: {
      type: String,
      default: 'account.delivery_addresses.create'
    }
  },
  methods: {
    emitClick() {
      this.$emit('click');
    }
  },
}
