
import ShopFormWrapper from "../../components/forms/ShopFormWrapper";
import SelectInput from "../../components/forms/inputs/SelectInput";
import ShopTextInputElement from "../../components/forms/inputs/ShopTextInputElement";
import CountrySelectInput from "../../components/forms/inputs/CountrySelectInput";
import FloorSelectInput from "../../components/forms/inputs/FloorSelectInput";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton";
import ShopTextAreaElement from "@/layouts/shop/components/forms/inputs/base/ShopTextAreaElement";
import TitleSelectInput from "@/layouts/shop/components/forms/inputs/TitleSelectInput.vue";
import addressValidationMixin from "@/mixins/addressValidationMixin";
import LoadingSpinner from "@/layouts/shop/components/loading/LoadingSpinner.vue";
import PhoneCountryCodePrefixSelectInput from "@/layouts/shop/components/forms/PhoneCountryCodePrefixSelectInput.vue";

export default {
  name: "DeliveryAddressForm",
  components: {
    PhoneCountryCodePrefixSelectInput,
    LoadingSpinner,
    TitleSelectInput,
    ShopTextAreaElement,
    ShopButton,
    FloorSelectInput,
    CountrySelectInput,
    ShopFormWrapper,
    ShopTextInputElement,
    SelectInput
  },
  ShopTextInputElement, SelectInput, ShopFormWrapper,
  mixins: [addressValidationMixin],
  props: {
    mode: {
      type: String,
      default: 'create'
    },
    // In which context the create form is called
    context: {
      type: String,
      default: 'account',
      validator: val => ['account', 'checkout'].includes(val)
    },
    id: {
      type: Number,
    },
    hideSubmitButton: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    title: null,
    familyName: null,
    firstName: null,
    companyName: null,
    phonePrefix: null,
    phone: null,
    numberAndStreet: null,
    postalCode: null,
    city: null,
    floor: null,
    country: 'lu',
    instructions: null,
    /////////////////////////
    copyInvoiceAddressButtonLoading: false,
    initialLoading: false,
  }),
  async fetch() {
    if (!this.isCreateMode) {
      this.emitLoading(true);
      this.initialLoading = true;

      return await this.$deliveryContactRepository.getSingleContact(this.id).then((res) => {
        const d = res.data;

        this.title = d.title;
        this.familyName = d.familyName;
        this.firstName = d.firstName;
        this.companyName = d.companyName;
        this.phone = d.phone || null;
        this.phonePrefix = d.phonePrefix || null;
        this.numberAndStreet = d.street;
        this.postalCode = d.postalCode;
        this.city = d.city;
        this.floor = d.floor === null ? null : d.floor.toString();
        //this.country = d.country;
        // For now hardcode this as we only deliver to Luxembourg
        this.country = 'lu';
        this.instructions = d.instructions;
      }).finally(() => {
        this.emitLoading(false);
        this.initialLoading = false;
      });
    }
  },
  methods: {
    submit(callback) {
      return this.isCreateMode
        ? this.createContact(callback)
        : this.updateContact(callback);
    },
    createContact(callback) {
      this.emitLoading(true);
      this.$deliveryContactRepository.createContact({
        title: this.title,
        firstName: this.firstName,
        familyName: this.familyName,
        companyName: this.companyName,
        phonePrefix: this.phonePrefix,
        phoneNumber: this.phone,
        street: this.numberAndStreet,
        postalCode: this.postalCode,
        city: this.city,
        country: this.country,
        floor: this.floor,
        instructions: this.instructions,
      }).then((res) => {
        this.onAfterCreated(res.data.id);
        this.$toast.success(this.$t('account.delivery_addresses.form.contact_created'));
      }).finally(() => {
        callback();
        this.emitLoading(false);
      });
    },
    updateContact(callback) {
      this.emitLoading(true);
      this.$deliveryContactRepository.updateContact(this.id, {
        title: this.title,
        firstName: this.firstName,
        familyName: this.familyName,
        companyName: this.companyName,
        phonePrefix: this.phonePrefix,
        phoneNumber: this.phone,
        street: this.numberAndStreet,
        postalCode: this.postalCode,
        city: this.city,
        country: this.country,
        floor: this.floor,
        instructions: this.instructions,
      }).then(() => {
        this.onAfterUpdated(this.id);
        this.$toast.success(this.$t('account.delivery_addresses.form.contact_updated'));
      }).finally(() => {
        callback();
        this.emitLoading(false);
      });
    },
    emitLoading(bool) {
      this.$emit('loading', bool);
    },
    onAfterCreated(idToPreselect = null) {
      if (this.context === "account") {
        this.$router.push(this.localePath({ name: 'account-index-delivery-address' }));
      } else if (this.context === "checkout") {
        this.$emit('created', idToPreselect);
      }
    },
    onAfterUpdated(idToPreselect = null) {
      if (this.context === "account") {
        this.$router.push(this.localePath({ name: 'account-index-delivery-address' }));
      } else if (this.context === "checkout") {
        this.$emit('updated', idToPreselect);
      }
    },
    copyInvoiceAddress() {
      if (!this.isCreateMode) {
        return false;
      }

      this.copyInvoiceAddressButtonLoading = true;

      this.$accountAndProfileRepository.getProfileData().then(res => {
        let invoiceAddress = res.data.invoiceAddress;

        this.title = invoiceAddress.title;
        this.familyName = invoiceAddress.familyName;
        this.firstName = invoiceAddress.firstName;
        this.companyName = invoiceAddress.company;
        this.phone = invoiceAddress.phoneNumber;
        this.numberAndStreet = invoiceAddress.street;
        this.postalCode = invoiceAddress.postalCode;
        this.city = invoiceAddress.city;
        this.country = invoiceAddress.country;

        // These values are not part of Invoice address, just reset.
        this.floor = null;
        this.instructions = "";
      }).finally(() => this.copyInvoiceAddressButtonLoading = false);
    }
  },
  computed: {
    isCreateMode() {
      return this.mode === "create";
    },
    shouldDisableInputs() {
      return this.copyInvoiceAddressButtonLoading;
    }
  },
}
