
import OrderListItemStatus from "./OrderListItemStatus";
import ShopButton from "../../../buttons/ShopButton";
import ShopCartActionButton from "../../../cart/includes/ShopCartActionButton";
import DropDownMenu from "../../../dropdown/DropDownMenu";
import AccountCard from "@/layouts/shop/components/account/sub/AccountCard/AccountCard";
import AccountCardTitle from "@/layouts/shop/components/account/sub/AccountCard/AccountCardTitle";
import AccountCardSubText from "@/layouts/shop/components/account/sub/AccountCard/AccountCardSubText";
import AccountOrderNotPaidAlert from "@/layouts/shop/components/account/orders/sub/AccountOrderNotPaidAlert";
import AccountModifyOrderButton
  from "@/layouts/shop/components/account/orders/sub/ModifyOrder/AccountModifyOrderButton";

export default {
  name: "OrderListItem",
  components: {
    AccountModifyOrderButton,
    AccountOrderNotPaidAlert,
    AccountCardSubText,
    AccountCardTitle,
    AccountCard,
    DropDownMenu,
    ShopCartActionButton,
    ShopButton,
    OrderListItemStatus
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    navigateToOrderOverviewLoading: false,
    openDeliveryTrackingLoading: false,
  }),
  methods: {
    navigateToOrderOverview() {
      this.navigateToOrderOverviewLoading = true;
      this.$router.push(this.localePath({
        name: 'account-index-orders-id-overview',
        params: { id: this.order.id }
      }));
    },
    openDeliveryTracking() {
      this.openDeliveryTrackingLoading = true;
      this.$router.push(
        this.localePath({
          name: 'account-index-orders-id-delivery-deliveryId-tracking',
          params: {
            id: this.order.id,
            deliveryId: this.order.deliveryId,
          }
        })
      );
    }
  },
  computed: {
    getDeliveryDate() {
      const timeslot = this.order.timeSlot;

      return timeslot === null
        ? null
        : `${this.$dayjs(timeslot.startDate).format('DD.MM.YYYY HH:mm')} - ${this.$dayjs(timeslot.endDate).format('HH:mm')}`;
    },
    hasDelivery() {
      return this.order.deliveryId !== null;
    },
    getTotalAmount() {
      return this.$moneyHelper.centsToEuroString(this.order.total.amount);
    },
    getStatus() {
      if (this.order.status === 'confirmed' && this.isInSupplierOrder) {
        return 'inFulfilment'
      }

      return this.order.status
    },
    isDelivered() {
      return this.order.status === 'delivered';
    },
    isEnRoute() {
      return this.order.status === 'enRoute';
    },
    isFulfilmentFinalized() {
      return this.order.fulfilmentStatus === 'fulfilmentFinalized';
    },
    isDeliveryTrackingAvailable() {
      return this.hasDelivery && [
          'fulfilmentFinalized',
          'enRoute',
      ].includes(this.order.status);
    },
    canCancel() {
      return this.order.canCancel;
    },
    isInSupplierOrder() {
      return this.order.isInSupplierOrder;
    }
  }
}
