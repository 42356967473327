
import LoadingSpinner from "../loading/LoadingSpinner";

export default {
  name: "ShopCard",
  components: { LoadingSpinner },
  props: {
    type: {
      type: String,
      validator: val => ["default", "primary", "primary-light", "secondary", "custom", "error"].includes(val),
      default: "default"
    },
    loading: {
      type: Boolean,
      default: false
    },
    disablePadding: {
      type: Boolean,
      default: false
    },
    disableBorder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getClassByType() {
      switch (this.$props.type) {
        case "primary":
          return this.disableBorder ? "bg-luxcaddy text-white" : "bg-luxcaddy border border-luxcaddy text-white";
        case "primary-light":
          return this.disableBorder ? "bg-luxcaddy-100" : "bg-luxcaddy-100 border border-luxcaddy-100";
        case "secondary":
          return "bg-gray-50 dark:bg-gray-700";
        case "error":
          return "bg-red-200 border-red-500 border-2 dark:bg-red-700";
        case "custom":
          return this.disableBorder ? "" : "border border-gray-200 dark:border-gray-700"
      }
      return this.disableBorder ? "bg-white dark:bg-gray-800" : "bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700";
    }
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    }
  },
}
