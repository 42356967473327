
import ProductCardInfoBadge from "@/layouts/shop/components/products/ProductCardInfos/ProductCardInfoBadge";
import localeMixin from "@/mixins/localeMixin";
export default {
  name: "ProductCardInfoExcludedDeliveryDaysBadge",
  components: { ProductCardInfoBadge },
  mixins: [localeMixin],
  props: {
    excludedDeliveryDays: {
      type: Array,
      required: true
    }
  },
  computed: {
    getNameOfFirstExcludedDay() {
      return this.getWeekDayByNumber(this.excludedDeliveryDays[0]);
    },
    getAmountOfAdditionalExcludedDays() {
      return this.excludedDeliveryDays.length - 1;
    }
  },
}
