
import CategoryCard from "@/layouts/shop/components/cards/CategoryCard/CategoryCard";

export default {
  name: "CategoryGrid",
  components: {CategoryCard},
  props: {
    type: {
      type: String,
      validator: val => ["products", "posts", "recipes"].includes(val)
    },
    categories: {
      type: Array,
      required: true
    },
    showImages: {
      type: Boolean,
      default: true
    }
  }
}
