
// import SelectInput from '../forms/inputs/SelectInput'
import ShopButton from "@/layouts/shop/components/buttons/ShopButton";
import RightArrowItemReferenceSvg from "@/layouts/shop/svgs/RightArrowItemReferenceSvg";
import ProductCardInfoContents from "../products/ProductCardInfos/ProductCardInfoContents";
import QuestionMarkButton from "@/layouts/shop/components/buttons/QuestionMarkButton.vue";
import AddModifyCartQuantityProductButton
  from "@/layouts/shop/components/buttons/product/cart/AddModifyCartQuantityProductButton.vue";
import SingleFaqInModalIcon from "@/layouts/shop/components/faq/ShopFaqSingle/SingleFaqInModalIcon.vue";
import ShopCartItemFixQuantityModal from "@/layouts/shop/components/cart/ShopCartItemFixQuantityModal.vue";

export default {
  name: "ShopCartItem",
  components: {
    ShopCartItemFixQuantityModal,
    SingleFaqInModalIcon,
    AddModifyCartQuantityProductButton,
    QuestionMarkButton, ProductCardInfoContents, RightArrowItemReferenceSvg, ShopButton
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isReferenceItem: {
      type: Boolean,
      default: false
    },
    showQuantityButtons: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    hovered: false,
  }),
  methods: {
    showItemCartError() {
      if (!this.item.isUnavailable)
        return false;

      const errorMessage = this
        .getItemErrors
        .flatMap(e => e.msg)
        .join('. ');

      const modalObject = {
        type: 'danger',
        title: this.$t('product.not_available'),
        body: errorMessage,
      };

      const vm = this;

      // Product not completely unavailable, but
      // user has too many units (quantity) in cart
      if (this.isAtLeastOneUnitAvailable) {
        modalObject.secondary = {
          label: vm.$t('cart.delete'),
          theme: 'primary',
          action: () => {
            vm.itemQuantity = 0;
          }
        };

        modalObject.primary = {
          label: vm.$t('cart.reduce_quantity_to', {quantity: this.item.maxAvailableQuantity}),
          theme: 'primary',
          action: () => {
            vm.itemQuantity = this.item.maxAvailableQuantity;
          }
        };
      }
      else {
        // Product is completely unavailable
        modalObject.primary = {
          label: vm.$t('cart.delete'),
          theme: 'primary',
          action: () => {
            vm.itemQuantity = 0;
          }
        };
        modalObject.secondary = {
          label: this.$t('common.cancel'),
          theme: 'primary',
          action: () => {
          }
        };
      }

      this.$modal.show(modalObject);
    },


    showDiscountOrGiftDescription() {
      this.$modal.show({
        type: 'success',
        title: this.getItemName,
        body: this.getItemDescription,
        primary: {
          label: this.$t('common.ok'),
          theme: 'primary',
          action: () => {
          }
        }
      });
    },

    navigateToProductPage() {
      if (!this.isOfTypeProduct) {
        return false;
      }

      this.$router.push(
        this.localePath({
          name: 'products-id-slug',
          params: {
            id: this.item.id,
            slug: this.item.slug
          }
        })
      );
    }

  },

  computed: {
    isAtLeastOneUnitAvailable() {
      return this.item.maxAvailableQuantity !== null && this.item.maxAvailableQuantity > 0;
    },

    getQuantityInCart() {
      if (!this.$auth.loggedIn) {
        return this.$store.getters["guestCart/getItemQuantityInCart"](this.item.id);
      }

      return this.$store.getters["cart/getItemQuantityInCart"](this.item.id);
    },

    /**
     * Returns the item name.
     * @returns {*}
     */
    getItemName() {
      return this.item.name;
    },

    /**
     * Returns the item description .
     * @returns {*}
     */
    getItemDescription() {
      return this.item.description || null;
    },

    /**
     * Returns true if there are cart related errors for this item.
     * @returns {boolean}
     */
    hasItemErrors() {
      return this.getItemErrors.length > 0;
    },

    /**
     * Returns cart related notifications for this item.
     * @returns {*|*[]}
     */
    getItemErrors() {
      return this.item.notifications?.errors || [];
    },

    /**
     * Returns the item price.
     *
     * @returns {string}
     */
    getPrice() {
      return this.$moneyHelper.centsToEuroString(this.getUnformattedPrice);
    },

    getUnformattedPrice() {
      if ((this.item.price?.amount ?? null) !== null) {
        return this.item.price.amount
      }
      return this.item.sellingData.price;
    },

    getUnitPrice() {
      if ((this.item.unitPrice?.amount ?? null) !== null) {
        return this.$moneyHelper.centsToEuroString(this.item.unitPrice.amount);
      }

      return this.getPrice;
    },

    isPositivePrice() {
      return this.item.price?.amount > 0;
    },

    /**
     * Returns the primary image url-
     * @returns {*}
     */
    getImageUrl() {
      if (this.item.media) {
        return this.$getUrlOfImage(this.item.media, "small");
      }

      return this.$getUrlOfImage(this.item, "small");
    },

    /**
     * Returns the type of the item.
     * @returns {*}
     */
    getItemType() {
      return this.item?.type || null;
    },

    /**
     * Determines if this item is a regular product.
     * @returns {boolean}
     */
    isOfTypeProduct() {
      return this.getItemType === null
        ? false
        : this.getItemType.toLowerCase() === "product";
    },

    isOfTypePackage() {
      return this.getItemType === null
        ? false
        : this.getItemType.toLowerCase() === "package";
    },

    isOfTypeWeightMargin() {
      return this.getItemType === null
        ? false
        : this.getItemType.toLowerCase() === "weightmargin";
    },

    isOfTypeDiscountOrGiftAndHasDescription() {
      const type = this.getItemType === null
        ? false
        : this.getItemType.toLowerCase();

      return (type === "discount" || type === "fixed" || type === "gift") && this.getItemDescription !== null;
    },

    /**
     * Getter and setter for the quantity of this item that is in the cart.
     */
    itemQuantity: {
      get() {
        return this.item.quantity;
      },
      set(quantity) {
        if (quantity > this.item.maxBuyableQuantity) {
          this.$modal.show({
            type: 'danger',
            title: this.$t('common.error'),
            body: this.$t('product.error_max_buyable_quantity', {max_buyable_quantity: this.item.maxBuyableQuantity}),
            primary: {
              label: 'OK',
              theme: 'danger',
              action: () => this.$emit('disabled-confirm'),
            },
          });

          quantity = this.item.maxBuyableQuantity;
        }

        if (this.$auth.loggedIn) {
          this.$store.dispatch('cart/setCartItemQuantity', {
            item: this.item,
            quantity: quantity
          });
        } else {
          this.$store.dispatch('guestCart/setCartItemQuantity', {
            item: this.item,
            quantity: quantity
          })
        }
      }
    },

    /**
     * Determines if the cart is viewed in the checkout.
     * @returns {any}
     */
    isInCheckout() {
      return this.$store.getters['checkout/isInCheckout'];
    },

    getItemClass() {
      if (!this.isPositivePrice) {
        return 'text-amber-600 font-medium';
      }

      if (this.item.isReferenceItem) {
        return 'text-gray-400';
      }
    },

    getPriceClass() {
      let priceClass = this.isReferenceItem ? '3xl:text-xs 4xl:text-sm' : 'font-medium 3xl:text-sm 4xl:text-md';


      if (!this.isPositivePrice) {
        priceClass += ' text-amber-600'
      } else {
        priceClass += ' text-luxcaddy'
      }

      return priceClass;
    }
  }
}
