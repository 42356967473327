
import SelectInput from "../../../../forms/inputs/SelectInput.vue";
import CheckoutSelectionRadioButton
  from "@/layouts/shop/components/checkout/common/CheckoutSelectionRadioButton.vue";
import CheckoutPackagingPricingDetails
  from "@/layouts/shop/components/checkout/packaging/sub/selection/CheckoutPackagingPricingDetails.vue";
import CheckoutPackagingRefundableBadge
  from "@/layouts/shop/components/checkout/packaging/sub/selection/CheckoutPackagingRefundableBadge.vue";

export default {
  name: 'CheckoutPackagingOptionMandatory',
  components: {
    CheckoutPackagingRefundableBadge,
    CheckoutPackagingPricingDetails,
    CheckoutSelectionRadioButton, SelectInput },
  props: {
    option: {
      type: Object,
      required: true
    },
    group: {
      type: Object,
      required: true
    },
  },
  methods: {
    select(quantity) {
      if (this.group.onlyOneChoice) {
        this.unselectOthersFromGroup();
      }

      this.$store.commit('checkout/addSelectedBag', {
        id: this.option.id,
        quantity: quantity
      });
    },
    onPackageOptionClick() {
      if (this.showRadioButton || this.showRadioButtonWithQuantitySelection) {
        this.selectZeroOrComputed();
      } else if(this.showCheckbox) {
        this.selectZeroOrComputedOrUnselect();
      }
      this.$emit('selected');
    },
    unselectOthersFromGroup() {
      let ids = this.group
        .options
        .flatMap(p => p.id)
        .filter(id => id !== this.option.id);

      ids.forEach(id => this.$store.commit('checkout/removeSelectedBag', id));
    },
    selectZeroOrComputed() {
      let quantity = 1;

      if(this.option.maximumQuantity === 1) {
        quantity = 1;
      } else if (this.isComputedQuantity) {
        quantity = this.option.quantity;
      }

      return this.select(quantity);
    },
    // Checkbox
    selectZeroOrComputedOrUnselect() {
      if(this.isSelected) {
        this.unselect();
      } else {
        this.selectZeroOrComputed();
      }
    },
    unselect() {
      this.$store.commit('checkout/removeSelectedBag', this.option.id);
    }
  },
  computed: {
    isSelected() {
      return this.$store.getters['checkout/getSelectedBagById'](this.option.id) !== undefined;
    },
    selectedQuantity: {
      get() {
        if (!this.isSelected) {
          if (this.isComputedQuantity)
            return this.getComputedQuantity;

          return 0;
        }

        return this.$store.getters['checkout/getSelectedBagById'](this.option.id).quantity;
      },
      set(quantity) {
        this.$store.commit('checkout/addSelectedBag', {
          id: this.option.id,
          quantity: quantity
        });
      }
    },
    getName() {
      return this.option.name;
    },
    getDescription() {
      return this.option.description;
    },
    isComputedQuantity() {
      return this.option.isComputedQuantity;
    },
    getComputedQuantity() {
      return this.isComputedQuantity ? this.option.quantity : null;
    },
    showAsFixed() {
      return !this.isComputedQuantity && this.option.maximumQuantity === 1;
    },
    getImageUrl() {
      return this.$getUrlOfImage(this.option, 'small');
    },
    showRadioButton() {
      if (this.group.isMandatory && this.option.isComputedQuantity)
        return true;

      return !this.option.isComputedQuantity && this.option.maximumQuantity === 1;
    },
    showRadioButtonWithQuantitySelection() {
      return this.group.isMandatory && !this.option.isComputedQuantity && this.option.maximumQuantity > 1;
    },
    showQuantitySelection() {
      return !this.group.isMandatory && !this.option.isComputedQuantity && this.option.maximumQuantity > 1;
    },
    showCheckbox() {
      return !this.group.isMandatory;
    },
    getSelectableQuantities() {
      if (!this.showRadioButtonWithQuantitySelection && !this.showQuantitySelection)
        return []

      if (this.isComputedQuantity) {
        return [this.option.quantity];
      }

      let options = Array.from(Array(this.option.maximumQuantity + 1).keys());

      if(this.isSelected) {
        options = options.filter(q => q !== 0);
      }

      return options;
    },
    isFirstInGroup() {
      return this.group.options[0].id === this.option.id;
    },
    isAnyOptionInGroupSelected() {
      let groupOptionIds = this.group.options.flatMap(go => go.id);
      let selectedBags = this.$store.getters['checkout/getSelectedBags'].flatMap(bi => bi.id);

      return selectedBags.some(bagId => groupOptionIds.includes(bagId));
    },
    hasMultipleOptionsInGroup() {
      return this.group.options.length > 1;
    },
    getPricePerUnit() {
      return this.option.priceWithVat;
    },
    getTotalPrice() {
      if(this.showAsFixed) {
        return this.option.maximumQuantity * this.getPricePerUnit;
      }

      return this.selectedQuantity * this.getPricePerUnit;
    }
  }
}
