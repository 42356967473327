// reset expanded categories when navigating away from a category page

export default function (context) {
  if(context?.route?.name ?? null) {
    if(!context.route.name.includes('categories-id-slug')) {
      context.store.commit('sidebar/unsetExpandedCategories');
    }
  }

  // Reset filters every time the page is changed
  // context.store.commit('filters/resetFilters');
}
