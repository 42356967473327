const url = "/categories";

export default $api => ({
  async getProductCategories(searchQuery = null) {
    let call = `${url}?type=product`;

    if(searchQuery !== null) {
      call = call += `&filter[name]=${searchQuery}`
    }

    return await $api.$get(call);
  },

  async getSingleCategory(categoryId) {
    return await $api.$get(`${url}/${categoryId}`);
  },

  async getProductsByCategory(categoryId) {
    return await $api.$get(`${url}/${categoryId}/products`)
  },
});
