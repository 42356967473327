
import AccountOrderTransactionSingle
  from "@/layouts/shop/components/account/orders/single/orderTransactions/AccountOrderTransactionSingle.vue";

export default {
  name: "AccountOrderTransactionsList",
  components: {AccountOrderTransactionSingle},
  props: {
    transactions: {
      type: Array,
      required: true
    }
  }
}
