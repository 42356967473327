
import BlogPostPreviewCard from "@/layouts/shop/components/recipes/BlogPostPreviewCard";
export default {
  name: "BlogPostGrid",
  components: { BlogPostPreviewCard },
  props: {
    posts: {
      type: Array,
      default: () => ([])
    }
  }
}
