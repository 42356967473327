
export default {
  name: "InvoiceStatusBadge",
  props: {
    status: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getClassesByStatus() {
      switch(this.status) {
        case "paid":
          return "bg-luxcaddy-500 text-white";
        case "unpaid":
          return "bg-red-700 text-red-100";
        case "overPaid":
          return "bg-yellow-500 text-white";
        case "partiallyPaid":
          return "bg-yellow-500 text-white";
        case "paymentPending":
          return "bg-yellow-500 text-white";
      }
    },
    getTextByStatus() {
      if(this.$te('account.invoices.statuses.' + this.status)){
        return this.$t('account.invoices.statuses.' + this.status);
      }
      return '???';
    },
    getPadding() {
      if(this.small) {
        return "px-3 py-1";
      }
      return "px-4 py-2";
    },
    getTextSize() {
      if(this.small) {
        return "text-sm";
      }
      return "text-lg";
    }
  }
}
