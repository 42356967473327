
import DeliveryContactCreateButton
  from "@/layouts/shop/components/account/deliveryContacts/AccountAreaCreateButton.vue";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";
import CheckoutAddressSelection from "@/layouts/shop/components/checkout/deliveryAddress/CheckoutAddressSelection.vue";
import ShopModal from "@/layouts/shop/components/modal/ShopModal.vue";
import DeliveryAddressForm from "@/layouts/shop/forms/account/DeliveryAddressForm.vue";

export default {
  name: "CheckoutAddressSelectionModal",
  components: {DeliveryAddressForm, ShopModal, CheckoutAddressSelection, ShopButton, DeliveryContactCreateButton},
  data() {
    return {
      // Use this copy to prevent the address to change before user clicks confirm
      selectedDeliveryAddressIdCopy: null,
    }
  },
  created() {
    this.selectedDeliveryAddressIdCopy = this.selectedDeliveryContactId;
  },
  methods: {
    onSelect(id) {
      this.selectedDeliveryContactId = id;
      this.destroyModal();
    },
    destroyModal() {
      this.$emit('close');
      this.$destroy();
    }
  },
  computed: {
    selectedDeliveryContactId: {
      get() {
        return this.$store.getters['checkout/getSelectedDeliveryContactId']
      },
      set(id) {
        this.$store.commit('checkout/setSelectedDeliveryContactId', id);
      }
    },
    getDeliveryContacts() {
      return this.$store.getters["checkout/getDeliveryContacts"];
    },
    getSelectedDeliveryContact() {
      return this.getDeliveryContacts.find(d => d.id === this.selectedDeliveryContactId);
    },
  },
}
