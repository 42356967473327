import { render, staticRenderFns } from "./AccountAreaCreateButton.vue?vue&type=template&id=3a840f2a&scoped=true&"
import script from "./AccountAreaCreateButton.vue?vue&type=script&lang=js&"
export * from "./AccountAreaCreateButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a840f2a",
  null
  
)

export default component.exports