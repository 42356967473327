const url = "/my/profile";

export default $api => ({
  async getProfileData() {
    let call = `${url}`;

    return await $api.$get(call);
  },
  async updateAccountData(email, language, password = null, passwordConfirmation = null) {
    if(typeof password === "string" && !password.length) {
      password = null;
    }
    if(typeof passwordConfirmation === "string" && !passwordConfirmation.length) {
      passwordConfirmation = null;
    }

    let call = `${url}/update-account`;

    let payload = {
      email: email,
      language: language,
    };

    if(password) {
      payload.password = password;
    }
    if(passwordConfirmation) {
      payload.password_confirmation = passwordConfirmation;
    }

    return await $api.$patch(call, payload);
  },

  async updateInvoiceAddress(payload) {
    let call = `${url}/update-profile`;
    return await $api.$patch(call, payload);
  },

  async requestAccountDeletion(password) {
    return await $api.$post(`${url}/delete-profile`, {
      password
    });
  },
});
