
import ShopCartItemList from "./ShopCartItemList";
import ShopCartItem from "./ShopCartItem";
import ShopCard from "../cards/ShopCard";
export default {
  name: "ItemsWithErrorList",
  components: { ShopCard, ShopCartItem, ShopCartItemList },
  computed: {
    getItemsWithError() {
      return this.$store.getters["cart/getItemsWithError"];
    }
  },
}
