let pageMap = [
  // Home Page
  { routeName: "index", pageIdentifier: { name: "Home", params: [] } },
  // Checkout payment page
  { routeName: "checkout", pageIdentifier: { name: "PaymentSelection", params: [] } },
  // Product Category Page
  { routeName: "categories-id-slug", pageIdentifier: { name: "ProductCategory", params: ['id'] } },
  // Product Page
  { routeName: "products-id-slug", pageIdentifier: { name: "Product", params: ['id'] } },
  // Brand Page
  { routeName: "brands-id-slug", pageIdentifier: { name: "Brand", params: ['id'] } },
  // Blog Post Detail Page
  { routeName: "blog-type-post-id-slug", pageIdentifier: { name: "BlogPost", params: ['id'] } },
]

const functions = {
  /**
   * Returns the Page Identifier generate from the route object.
   * The Page identifier is needed to retrieve the relevant page data from the backend.
   *
   * @param route
   * @returns {null|{name: string, params: {id}}}
   */
  getPageIdentifierByRoute(route) {
    if (route === undefined || route.name === undefined || route.name === null) {
      return null;
    }

    const routeName = route.name.split('__')[0];

    let pageIdentifier = pageMap.find(p => p.routeName === routeName)?.pageIdentifier;

    if (!pageIdentifier) {
      return null;
    }

    // Clone to not accidentally modify the existing pageMap
    pageIdentifier = { ...pageIdentifier };

    // Map all params from the route.
    let params = pageIdentifier.params?.length > 0 ? {} : null;
    if (Array.isArray(pageIdentifier.params)) {
      pageIdentifier.params.forEach(param => {
        params[param] = route.params[param];
      });
    }
    pageIdentifier.params = params;

    return pageIdentifier;
  },

  getRouteNameByPageIdentifier(pageIdentifier) {
    const correctPage = pageMap.find(page => {
      if (page.pageIdentifier.name === pageIdentifier.name) {
        if (page.pageIdentifier.params) {
          let hasAllParams = true;

          if (pageIdentifier.params !== null) {
            page.pageIdentifier.params.forEach(p => {
              if (!pageIdentifier.params[p]) {
                hasAllParams = false;
              }
            });
          }


          return hasAllParams;
        }
      }
      return false;
    });

    return correctPage?.routeName || null;
  }
}

export default (context, inject) => {
  inject('pageDataHelper', functions)
}
