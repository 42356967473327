
import CheckoutSelectionRadioButton
  from "@/layouts/shop/components/checkout/common/CheckoutSelectionRadioButton.vue";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";
import QuantitySelector from "@/layouts/shop/components/quantitySelector/QuantitySelector.vue";
import CheckoutPackagingPricingDetails
  from "@/layouts/shop/components/checkout/packaging/sub/selection/CheckoutPackagingPricingDetails.vue";
import CheckoutPackagingRefundableBadge
  from "@/layouts/shop/components/checkout/packaging/sub/selection/CheckoutPackagingRefundableBadge.vue";

export default {
  name: 'CheckoutPackagingOptionOptional',
  components: {
    CheckoutPackagingRefundableBadge,
    CheckoutPackagingPricingDetails,
    QuantitySelector, ShopButton, CheckoutSelectionRadioButton},
  props: {
    option: {
      type: Object,
      required: true
    },
    group: {
      type: Object,
      required: true
    },
  },
  methods: {
    select(quantity) {
      if (this.group.onlyOneChoice) {
        this.unselectOthersFromGroup();
      }

      this.$store.commit('checkout/addSelectedBag', {
        id: this.option.id,
        quantity: quantity
      });
    },
    unselectOthersFromGroup() {
      let ids = this.group
        .options
        .flatMap(p => p.id)
        .filter(id => id !== this.option.id);

      ids.forEach(id => this.$store.commit('checkout/removeSelectedBag', id));
    },
  },
  computed: {
    isSelected() {
      return this.$store.getters['checkout/getSelectedBagById'](this.option.id) !== undefined;
    },
    selectedQuantity: {
      get() {
        if (!this.isSelected) {
          if (this.isComputedQuantity)
            return this.getComputedQuantity;

          return 0;
        }

        return this.$store.getters['checkout/getSelectedBagById'](this.option.id).quantity;
      },
      set(quantity) {
        this.$store.commit('checkout/addSelectedBag', {
          id: this.option.id,
          quantity: quantity
        });
      }
    },
    getName() {
      return this.option.name;
    },
    getDescription() {
      return this.option.description;
    },
    isComputedQuantity() {
      return this.option.isComputedQuantity;
    },
    getComputedQuantity() {
      return this.isComputedQuantity ? this.option.quantity : null;
    },
    showAsFixed() {
      return !this.isComputedQuantity && this.option.maximumQuantity === 1;
    },
    getImageUrl() {
      return this.$getUrlOfImage(this.option, 'small');
    },
    showQuantitySelection() {
      return !this.group.isMandatory && !this.option.isComputedQuantity && this.option.maximumQuantity > 1;
    },
    getMaxSelectableQuantity() {
      if (this.isComputedQuantity) {
        return this.option.quantity;
      }

      return this.option.maximumQuantity;
    },
    isFirstInGroup() {
      return this.group.options[0].id === this.option.id;
    },
    isAnyOptionInGroupSelected() {
      let groupOptionIds = this.group.options.flatMap(go => go.id);
      let selectedBags = this.$store.getters['checkout/getSelectedBags'].flatMap(bi => bi.id);

      return selectedBags.some(bagId => groupOptionIds.includes(bagId));
    },
    hasMultipleOptionsInGroup() {
      return this.group.options.length > 1;
    },
    getPricePerUnit() {
      return this.option.priceWithVat;
    },
    getTotalPrice() {
      if (this.showAsFixed) {
        return this.option.maximumQuantity * this.getPricePerUnit;
      }

      return this.selectedQuantity * this.getPricePerUnit;
    }
  }
}
