
import ShopButton from "@/layouts/shop/components/buttons/ShopButton";

export default {
  name: "ShopOpenOrderBanner",
  components: { ShopButton },
  methods: {
    navigateToPaymentPage() {
      this.$router.push(
        this.localePath({ name: 'account-index-orders-id-payment', params: { id: this.getOpenOrder.id } })
      );
    }
  },
  created() {
    if(!this.$auth.loggedIn && this.hasOpenOrders) {
      this.$store.commit('pageData/resetOpenOrders');
    }
  },
  computed: {
    hasOpenOrders() {
      return this.$store.getters['pageData/hasOpenOrders'];
    },
    getOpenOrder() {
      if (!this.hasOpenOrders)
        return [];

      return this.$store.getters['pageData/getOpenOrders'][0];
    },
  },
}
