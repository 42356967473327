
import CheckoutGiftFeeCard from "@/layouts/shop/components/checkout/confirmation/CheckoutGiftFeeCard.vue";
import CheckoutFeeSelection from "@/layouts/shop/components/checkout/confirmation/fees/CheckoutFeeSelection.vue";

export default {
  name: "CheckoutFeeManagement",
  components: {CheckoutFeeSelection, CheckoutGiftFeeCard},
  computed: {
    getFees() {
      return this.$store.getters["cart/getFees"];
    }
  },
}
