
import CheckoutAddressSelection from '@/layouts/shop/components/checkout/deliveryAddress/CheckoutAddressSelection.vue'
import CheckoutContinueButton from "@/layouts/shop/components/checkout/CheckoutContinueButton.vue";
import DeliveryContactCreateButton
  from "@/layouts/shop/components/account/deliveryContacts/AccountAreaCreateButton.vue";
import ShopCard from "@/layouts/shop/components/cards/ShopCard.vue";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";
import ShopModal from "@/layouts/shop/components/modal/ShopModal.vue";
import CheckoutAddressManagementModal
  from "@/layouts/shop/components/checkout/deliveryAddress/CheckoutAddressSelectionModal.vue";
import CheckoutTitle from "@/layouts/shop/components/checkout/common/ui/CheckoutTitle.vue";

export default {
  name: 'CheckoutAddressStep',
  components: {
    CheckoutTitle,
    CheckoutAddressManagementModal,
    ShopModal,
    ShopButton,
    ShopCard,
    DeliveryContactCreateButton,
    CheckoutContinueButton,
    CheckoutAddressSelection
  },
  async fetch() {
    await this.fetchDeliveryContacts();
  },

  fetchOnServer: false,
  data: () => ({
    modalShown: false,
  }),
  methods: {
    async fetchDeliveryContacts() {
      const vm = this;
      return this.$store.dispatch('checkout/fetchDeliveryContacts').then(() => {
        vm.$emit('loaded');
      });
    },
    hideAddressSelectionModal() {
      this.modalShown = false;
    },
    showAddressSelectionModal() {
      this.modalShown = true;
    },
    redirectToAddressManage() {
      return this.$router.push(
        this.localePath(
          {
            name: 'account-index-delivery-address'
          }
        )
      )
    }
  },

  computed: {
    selectedDeliveryContactId() {
      return this.$store.getters['checkout/getSelectedDeliveryContactId'];
    },
    getSelectedDeliveryContact() {
      return this.$store.getters["checkout/getDeliveryContacts"].find(d => d.id === this.selectedDeliveryContactId) || null;
    },
  },
}
