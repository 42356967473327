
export default {
  name: "XmasLogoSvg",
  props: {
    useFullHeight: {
      type: Boolean,
      default: true
    }
  }
}
