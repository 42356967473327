
import ShopModal from "@/layouts/shop/components/modal/ShopModal.vue";
import CheckoutTimeSlotUnavailableItem
  from "@/layouts/shop/components/checkout/timeSlot/selector/sub/unavailableModal/CheckoutTimeSlotUnavailableItem.vue";

export default {
  name: "CheckoutTimeslotSelectorUnavailableDateModal",
  components: {CheckoutTimeSlotUnavailableItem, ShopModal},
  props: {
    date: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      showModal: false,
      reloadCartOnClose: false
    }
  },
  watch: {
    areAllProblemsResolved: function (bool) {
      if(bool) {
        this.$emit('resolved');
        this.closeModal();
      }
    }
  },
  computed: {
    getFormattedDate() {
      return this.$dayjs(this.date).format("dddd DD MMMM");
    },
    getConcernedProductIds() {
      return this.items.flatMap(item => item.productId);
    },
    getConcernedItems() {
      return this.$store.getters["cart/getItemsWithoutFeesAndCampaigns"]
        .filter(item => this.getConcernedProductIds.includes(item.id))
        .map(item => {
          const errorMessage = this.items.find(x => x.productId === item.id).message;

          return {
            ...item,
            errorMessage
          }
        });
    },
    areAllProblemsResolved() {
      return this.getConcernedItems.length === 0;
    },
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    }
  },
}
