
export default {
  name: 'ShopFaqQuestion',
  props: {
    identifier: {
      type: String,
      default: 'none'
    },
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
  },
  data: () => ({
    expanded: false
  }),
  mounted() {
    // You can link to a question by passing query: {identifier: -QUESTION_ID-} to the route
    const route = this.$route;
    if(route.query && route.query.identifier && route.query.identifier === this.identifier) {
      this.expanded = true;

      if(process.client) {
        document.getElementById('faq_question_' + this.identifier).scrollIntoView({behavior: 'smooth'});
      }
    }
  }
}
