
import ShopButton from "../../buttons/ShopButton";

export default {
  name: "ShopInput",
  components: {ShopButton},
  props: {
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String,
      default: ""
    },
    rightInsetButton: {
      type: Boolean,
      default: false
    },
    rightInsetButtonFill: {
      type: Boolean,
      default: false
    },
    leftInsetButton: {
      type: Boolean,
      default: false
    },
    leftInsetButtonFill: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
    },
    additionalInputClasses: {
      type: [String, Array, Object],
      required: false
    },
    paddingClasses: {
      type: [String, Array, Object],
      default: 'px-4 py-3'
    },
    min: {type: Number},
    max: {type: Number},
    showLoadingIcon: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput(e) {
      let value = e.target.value;
      if (this.max && value > this.max) {
        value = this.max;
        this.$emit('maxExceeded');
      }

      this.$emit('input', value);
    },

    onBlur(e) {
      this.$emit('blur', e.target.value);
    },
    onFocusOut(e) {
      this.$emit('focusout', e.target.value);
    },
    focusInput() {
      this.$refs.input.focus();
    }
  },
  computed: {
    getBorderClasses() {
      if(this.leftInsetButton && this.rightInsetButton) {
        return "border-t border-b";
      }
      if(this.leftInsetButton) {
        return "border-t border-b border-r";
      }
      if(this.rightInsetButton) {
        return "border-t border-b border-l";
      }
      return "border";
    },
    getInputRoundedClasses() {
      if(this.leftInsetButton && this.rightInsetButton) {
        return "";
      }
      if(this.leftInsetButton) {
        return "rounded-r-lg";
      }
      if(this.rightInsetButton) {
        return "rounded-l-lg";
      }
      return "rounded-lg";
    }
  },
}
