
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";
import AccountCardTitle from "@/layouts/shop/components/account/sub/AccountCard/AccountCardTitle.vue";
import AccountCardSubText from "@/layouts/shop/components/account/sub/AccountCard/AccountCardSubText.vue";
import CheckoutSelectionRadioButton from "@/layouts/shop/components/checkout/common/CheckoutSelectionRadioButton.vue";

export default {
  name: 'CheckoutAddressSelection',
  components: {CheckoutSelectionRadioButton, AccountCardSubText, AccountCardTitle, ShopButton},
  props: {
    id: {
      type: Number,
      required: true
    },
    value: {
      type: Number,
    },
    deliveryContact: {
      type: Object,
      required: true
    }
  },
  mounted() {
    // Address selected
    if(this.isSelected) {
      this.$el.scrollIntoView({block: "center", behavior: 'smooth'})
    }
  },
  methods: {
    select() {
      this.$emit('select', this.id);
    },
  },
  computed: {
    isSelected() {
      return this.value === this.id;
    }
  }
}
