
import BlogPostTag from "@/layouts/shop/components/recipes/BlogPostTag";
import LoadingSpinner from "@/layouts/shop/components/loading/LoadingSpinner";
import BannerLink from "@/layouts/shop/components/banners/BannerLink.vue";

export default {
  name: "Banner",
  components: {BannerLink, LoadingSpinner, BlogPostTag},
  data: () => ({
    imageLoaded: false,
  }),
  props: {
    banner: {
      type: Object,
      required: true
    }
  },
  computed: {
    isBannerableTypeProduct() {
      return this.banner.bannerable_type === "Product";
    },
    // How the image should be displayed, based on if its a banner created of a product
    // or another model.
    getImageObjectClass() {
      if (this.isBannerableTypeProduct) {
        return 'object-contain';
      }

      return 'object-cover';
    }
  },
}
