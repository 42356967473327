
export default {
  name: "CheckoutTimeSlotPercentageBar",
  props: {
    percentage: {
      type: Number,
      required: true
    },
    width: {
      type: String,
      default: 'w-24'
    }
  },
  computed: {
    getPercentageBarColor() {
      if(this.isNearlyFull) {
        return "bg-amber-400";
      }
      if(this.isFull) {
        return "bg-red-400";
      }
      return "bg-luxcaddy-400";
    },
    isNearlyFull() {
      return this.percentage >= 90 && this.percentage <= 99.99
    },
    isFull() {
      return this.percentage >= 100;
    },
  },
}
