
import TextBanner from "@/layouts/shop/components/banners/Text/TextBanner.vue";

export default {
  name: "TextBannerList",
  components: {TextBanner},
  props: {
    placement: {
      type: String,
      default: "Main"
    }
  },
  computed: {
    getBanners() {
      return this
        .$store.getters['pageData/getBannersForPage'](this.$pageDataHelper.getPageIdentifierByRoute(this.$route))
        .filter(b => b.type === "text" && b.placement === this.placement);
    },
  },
}
