
import CheckoutPackagingSelectionMenu
  from "@/layouts/shop/components/checkout/packaging/sub/CheckoutPackagingSelectionMenu.vue";
import ShopModal from "@/layouts/shop/components/modal/ShopModal.vue";

export default {
  name: "CheckoutPackagingSelectionModal",
  components: {ShopModal, CheckoutPackagingSelectionMenu},
  props: {
    type: {
      type: String,
      validator: val => ["mandatory", "optional"].includes(val)
    }
  },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    }
  }

}
