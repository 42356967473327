import configHelper from "@/helpers/configHelper";

const url = `/my/orders`;

export default $api => ({
  
  async listOrders(page = 1, perPage = 10) {
    return await $api.$get(`${url}/?page=${page}&perPage=${perPage}`);
  },
  async getSingleOrder(orderId) {
    return await $api.$get(`${url}/${orderId}`);
  },
  async addToCart(orderId) {
    return await $api.$patch(`${url}/${orderId}/addToCart`);
  },
  async downloadInvoice(orderId) {
    return await $api.$get(`${url}/${orderId}/invoice/download`, {
      responseType: 'blob'
    },).then((data) => {
      const url = URL.createObjectURL(new Blob([data], {
        type: 'application/pdf'
      }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `invoice.pdf`)
      document.body.appendChild(link)
      link.click();
    });
  },
  async cancelOrder(orderId) {
    return await $api.$patch(`${url}/${orderId}/cancel`);
  }
});
