export const state = () => ({
  searchTerm: '',

  loading: false,
  suggestedProducts: [],
  suggestedCategories: [],
  suggestedRecipes: [],

})

export const mutations = {
  resetSearch(state) {
    state.searchTerm = null;
    state.suggestedProducts = null;
    state.suggestedCategories = null;
    state.suggestedRecipes = null;
    state.queryId = null;
  },
  setLoading(state, bool) {
    state.loading = bool;
  },

  setSearchTerm(state, search) {
    state.searchTerm = search;
  },
  setSuggestedProducts(state, products) {
    state.suggestedProducts = products ?? [];
  },
  setSuggestedCategories(state, categories) {
    state.suggestedCategories = categories ?? [];
  },
  setSuggestedRecipes(state, $recipes) {
    state.suggestedRecipes = $recipes ?? [];
  } ,
  setQueryId(state, $queryId) {
    state.queryId = $queryId ?? [];
  }
}

export const getters = {
  getSearchTerm: state => state.searchTerm,
  hasEnteredSearchTerm: state => {
    if(state.searchTerm === null) {
      return false;
    }

    return state.searchTerm.length > 0 ?? false;
  },

  isSearchLoading: state => state.loading,

  getSuggestedProducts: state => state.suggestedProducts,
  getSuggestedCategories: state => state.suggestedCategories,
  getSuggestedRecipes: state => state.suggestedRecipes,
  getQueryId: state => state.queryId,
}

export const actions = {
  /**
   * Search for a list of suggested products & categories and store the results.
   *
   * @param commit
   * @param query
   */
  search({ commit }, query) {
    commit('setLoading' , true);
    commit('resetSearch');
    commit('setSearchTerm', query);
    return this.$productRepository.search(query).then((res) => {
      commit('setSuggestedProducts', res.data.products);
      commit('setSuggestedCategories', res.data.categories);
      commit('setSuggestedRecipes', res.data.recipes);
      commit('setQueryId', res.data.queryId);
    }).finally(() => {
      commit('setLoading', false);
    });
  }
}
