export default (context, inject) => {
  inject('getPrimaryUrlImageFromProductMediaObject', (productMediaObject, size = 'medium') => {
    let imageUrl = null;

    let pr = productMediaObject
      ?.image
      ?.primary;

    if(Array.isArray(pr)) {
      imageUrl = pr[0]
        ?.formats
        ?.find(f => f.size === size)
        ?.url;
    }

    if(imageUrl !== null) {
      return imageUrl;
    }

    return productMediaObject
      ?.image
      ?.formats
      ?.find(f => f.size === size)
      ?.url;
  });

  /**
   * Divides Contents by 1000 if > 100 to transform g -> kg, ml -> l etc
   * Returns ${content}${unit}
   */
  inject('getUnitString', (content, unit) => {
    if(content >= 1000 && unit !== 'ut') {
      // Wrapping with parseFloat so that .00 is removed while other values stay toFixed(2)
      content = parseFloat((content / 1000).toFixed(2));

      switch(unit) {
        case "g":
          unit = "kg";
          break;
        case "ml":
          unit = "l";
          break;
      }
    }
    if(unit === 'ut') {
      unit = ' ' + context.app.i18n.t('product.unit');
    }

    return `${content}${unit}`;
  });
}
