
import ShopCard from "@/layouts/shop/components/cards/ShopCard";
export default {
  name: "ProductInfoCard",
  components: { ShopCard },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    activeTab: 'allergens'

  }),
  created() {
    this.activeTab = this.getIngredients ? 'ingredients' : 'allergens';
  },

  methods: {
    setActiveTab(tabName) {
      this.activeTab = tabName;
    }
  },
  computed: {
    getAllergenTags() {
      return this.product.tags
        .filter(t => t.type.toLowerCase() === "allergen")
        .filter(t => t.image);
    },
    getTracesOfTags() {
      return this.product.tags
        .filter(t => t.type.toLowerCase() === "tracesof")
    },
    hasAllergens() {
      return this.getAllergenTags.length > 0;
    },
    hasTracesOf() {
      return this.getTracesOfTags.length > 0;
    },
    getIngredients() {
      return this.product.ingredients;
    },
  },
}
