
import ShopPageTitle from "@/layouts/shop/components/pages/ShopPageTitle";
import ShopFormWrapper from "@/layouts/shop/components/forms/ShopFormWrapper";
import ShopInputElement from "@/layouts/shop/components/forms/inputs/base/ShopInputElement";
import ShopCheckbox from "@/layouts/shop/components/forms/inputs/base/ShopCheckbox";
import SelectInput from "@/layouts/shop/components/forms/inputs/SelectInput";
import EditShoppingListItem from "@/layouts/shop/components/account/shoppingLists/Edit/Items/EditShoppingListItem";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";

export default {
  name: "ShoppingListEdit",
  components: {
    ShopButton,
    EditShoppingListItem, SelectInput, ShopCheckbox, ShopInputElement, ShopFormWrapper, ShopPageTitle},
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    title: null,
    remindByEmail: false,
    remindBySms: false,
    reminderWeekDay: null,
    items: [],
  }),
  async fetch() {
    this.emitLoading(true);
    await this.$shoppingListRepository
      .getListDetail(this.id)
      .then(res => {
        this.title = res.data.title;
        this.remindByEmail = res.data.remindByEmail;
        this.remindBySms = res.data.remindBySms;
        this.reminderWeekDay = res.data.reminderWeekDay;
        this.items = res.data.items;
      }).finally(() => {
        this.emitLoading(false);
      });
  },
  computed: {
    getWeekdays() {
      return [
        {label: this.$t('datetime.weekdays.monday'), value: 1},
        {label: this.$t('datetime.weekdays.tuesday'), value: 2},
        {label: this.$t('datetime.weekdays.wednesday'), value: 3},
        {label: this.$t('datetime.weekdays.thursday'), value: 4},
        {label: this.$t('datetime.weekdays.friday'), value: 5},
        {label: this.$t('datetime.weekdays.saturday'), value: 6},
        {label: this.$t('datetime.weekdays.sunday'), value: 7},
      ];
    },
    getItems() {
      return this.items;
    }
  },
  methods: {
    removeItem(id) {
      this.items = this.items.filter(i => i.productId !== id);
    },

    saveList(callback) {
      this.emitLoading(true);
      this.$shoppingListRepository
        .update(this.id, {
          title: this.title,
          remindByEmail: this.remindByEmail,
          remindBySms: this.remindBySms,
          reminderWeekDay: this.reminderWeekDay,
        })
        .then(() => {
          this.$toast.success(this.$t('common.saved_successfully'));
        })
        .finally(() => {
          this.emitLoading(false);
          callback();
        });
    },

    emitLoading(bool) {
      this.$emit('loading', bool);
    },

    deleteShoppingList() {
      this.$shoppingListRepository.delete(this.id).then(() => {
        this.$toast.success(this.$t('common.deleted_successfully'));
        this.$router.push(
          this.localePath({
            name: 'account-index-shopping-lists'
            }
          )
        )
      });
    },

    confirmDeleteList() {
      this.$modal.show({
          type: 'danger',
          title: this.$t('shopping_lists.delete.title'),
          body: this.$t('shopping_lists.delete.text'),
          primary: {
            label: this.$t('common.confirm'),
            theme: 'danger',
            action: () => {
              this.deleteShoppingList();
            },
          },
          secondary: {
            label: this.$t('common.cancel'),
            theme: 'light',
            action: () => {
            }
          }
        }
      );
    }
  },
}
