
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";

export default {
  name: "ShopOrderDeliveryTrackingBanners",
  components: {ShopButton},
  created() {
    if (!this.$auth.loggedIn && this.hasOrdersWithDeliveryTracking) {
      this.$store.commit('pageData/resetOrdersWithDeliveryTracking');
    }
  },
  methods: {
    openDeliveryTracking(order) {
      this.$router.push(
        this.localePath({
          name: 'account-index-orders-id-delivery-deliveryId-tracking',
          params: {
            id: order.id,
            deliveryId: order.deliveryId,
          }
        })
      );
    }
  },
  computed: {
    hasOrdersWithDeliveryTracking() {
      return this.$store.getters['pageData/hasOrdersWithDeliveryTracking'];
    },
    getOrdersWithDeliveryTracking() {
      if (!this.hasOrdersWithDeliveryTracking)
        return [];

      return this.$store.getters['pageData/getOrdersWithDeliveryTracking'];
    },
  }
}
