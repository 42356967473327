
import TitleSelectInput from "@/layouts/shop/components/forms/inputs/TitleSelectInput.vue";
import CountrySelectInput from "@/layouts/shop/components/forms/inputs/CountrySelectInput.vue";
import FloorSelectInput from "@/layouts/shop/components/forms/inputs/FloorSelectInput.vue";
import ShopInputElement from "@/layouts/shop/components/forms/inputs/base/ShopInputElement.vue";
import {mapGetters} from "vuex";
import PhoneCountryCodePrefixSelectInput from "@/layouts/shop/components/forms/PhoneCountryCodePrefixSelectInput.vue";
import ShopTextAreaElement from "@/layouts/shop/components/forms/inputs/base/ShopTextAreaElement.vue";

export default {
  name: "RegisterAddressForm",
  components: {
    ShopTextAreaElement,
    PhoneCountryCodePrefixSelectInput,
    ShopInputElement, FloorSelectInput, CountrySelectInput, TitleSelectInput
  },
  props: {
    type: {
      type: String,
      validator: val => ["invoice", "delivery"].includes(val),
      required: true
    }
  },
  created() {
    // Prefil delivery instructions in the users language
    this.updateFormValue('deliveryInstruction', this.$t('auth.registration.default_delivery_instructions'));
  },
  mounted() {
    if(this.isPrivateCustomer) {
      if(this.$refs.firstNameInput) {
        this.$refs.firstNameInput.focus();
      }
    }
  },
  methods: {
    updateFormValue(property, value) {
      if (this.type === "invoice") {
        this.$store.commit('register/updateInvoiceAddressProperty', {property, value});
      } else if (this.type === "delivery") {
        this.$store.commit('register/updateDeliveryAddressProperty', {property, value});
      }
    },
    getFormValue(property) {
      if (this.type === "invoice") {
        return this.$store.getters["register/getInvoiceAddress"][property];
      } else if (this.type === "delivery") {
        return this.$store.getters["register/getDeliveryAddress"][property];
      }
    },
  },
  computed: {
    ...mapGetters('register', [
      'getAccountType'
    ]),
    getValidationRulesForNameAndTitle() {
      if (this.isPrivateCustomer) {
        return "required";
      }

      // Company
      let title = this.getFormValue('title');
      title = title === "" ? null : title;

      let firstName = this.getFormValue('firstName');
      firstName = firstName === "" ? null : firstName;

      let familyName = this.getFormValue('familyName');
      familyName = familyName === "" ? null : familyName;


      if(title !== null || firstName !== null || familyName !== null) {
        return "required";
      }

      return "";
    },
    isCompany() {
      return this.getAccountType === "company";
    },
    isPrivateCustomer() {
      return this.getAccountType === "private";
    },
    getPostalCodeValidationRules() {
      if(this.type === "delivery") {
        return "required|luxembourgish_postal_code"
      }

      return "required|min:4|max:7";
    },
    isOfTypeInvoice() {
      return this.type === "invoice";
    }
  },
}
