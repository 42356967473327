
import ProductCardInfoExplanation from "@/layouts/shop/components/products/ProductCardInfos/ProductCardInfoExplanation";
import localeMixin from "@/mixins/localeMixin";
export default {
  name: "ProductCardInfoExplanationExcludedDeliveryDays",
  components: { ProductCardInfoExplanation },
  mixins: [localeMixin],
  props: {
    excludedDeliveryDays: {
      type: Array,
      required: true
    }
  },
  computed: {
    /**
     * Returns an array of weekday names.
     * @returns {string}
     */
    getNamesOfWeekDays() {
      return this.excludedDeliveryDays.flatMap(d => {
        return this.getWeekDayByNumber(d);
      }).join(', ')
    },
  },
}
