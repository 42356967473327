
export default {
  name: "MobileCategoryMenuIndicator",
  data: () => ({
    showIndicator: false,
  }),
  mounted() {
    this.syncFromLocalStorage();
  },
  methods: {
    syncFromLocalStorage() {
      // Fallback to never show if localStorage not available
      if (!window._localStorage.getItem('indicatorMobiCatButtonHidden')) {
        this.showIndicator = true;
      }
    },
    hide() {
      this.showIndicator = false;
      if (!window._localStorage.getItem('indicatorMobiCatButtonHidden')) {
        window._localStorage.setItem('indicatorMobiCatButtonHidden', "true");
      }
    },
  }
}
