
import ShopNavMenuLink from './ShopNavMenuLink'
import ShopSkeleton from "../../components/skeleton/ShopSkeleton";
import ShopNavButtonMenuLink from "./ShopNavButtonMenuLink";
import configHelper from "@/helpers/configHelper.js";
import ListGroupNavItem from "@/layouts/shop/components/listGroupNav/ListGroupNavItem.vue";

export default {
  name: 'ShopNavUserButton',
  components: {ListGroupNavItem, ShopNavButtonMenuLink, ShopSkeleton, ShopNavMenuLink},
  props: {
    size: {
      type: String,
      default: "normal",
      validator: val => ["normal", "small"].includes(val)
    },
    useRelative: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loadingWalletAmount: false,
    }
  },
  methods: {
    logout() {
      this.$auth.logout().finally(() => {
        this.$auth.strategies.laravelSanctum.reset();
        this.$sentry.setUser(null);
        this.$store.commit('cart/resetCart');
        this.$store.commit('pageData/resetOpenOrders');
        this.$store.commit('pageData/resetOrdersWithDeliveryTracking');
      });
    },
    onMenuOpen() {
      this.loadingWalletAmount = true;
      this.$auth.fetchUser().finally(() => {
        this.loadingWalletAmount = false;
      });
    }
  },
  computed: {
    getUsername() {
      if(this.$auth.user.firstName) {
        return this.$auth.user.firstName;
      }

      return this.$auth.user.companyName;
    },
    getGreeting() {
      const hours = (new Date()).getHours();

      if (hours < 12) {
        return this.$t('greetings.good_morning');
      } else if (hours >= 12 && hours <= 17) {
        return this.$t('greetings.good_afternoon');
      } else {
        return this.$t('greetings.good_evening');
      }
    },
    getAccountLinks() {
      return configHelper.accountLinks;
    }
  },
}
