
export default {
  name: "ProductFilterSelection",
  data: () => ({
    isHovered: false
  }),
  props: {
    filter: {
      type: Object,
      required: true
    },
  },
  methods: {
    selectOrUnselect() {
      this.$store.commit('filters/selectOrUnselectFilterById', this.filter.id);
    }
  },
  computed: {
    isSelected() {
      return this.$store.getters['filters/getActiveFilters']
        .find(fId => fId === this.filter.id) !== undefined;
    }
  }
}
