import {findParentCategory} from "@/helpers/categoryHelper.js";

export const state = () => ({
  hasFetchedCategories: false,
  categories: []
})

export const mutations = {
  setCategories(state, categories) {
    state.categories = categories;
  },
  setHasFetchedCategories(state, bool) {
    state.hasFetchedCategories = bool;
  },
}

export const getters = {
  getCategories: state => state.categories,
  getSiblingCategories: state => (catId) => {
    const parentCategory = findParentCategory(state.categories, catId);

    // No parent, return all top-level.
    if(parentCategory === null) {
      return state.categories;
    }

    return parentCategory?.subCategories || [];
  },
  hasFetchedCategories: state => state.hasFetchedCategories,
}

export const actions = {
  fetchRecipes({ commit }, query) {
    return this.$blogRepository.getRecipeCategoryTree().then((res) => {
      commit('setCategories', res.data.subCategories);
      commit('setHasFetchedCategories', true);
    });
  }
}
