
import { debounce } from "@/helpers/debounceHelper";
import LoadingSvgInline from "../loading/LoadingSvgInline";

export default {
  name: "ShopButton",
  components: { LoadingSvgInline },
  props: {
    fill: { type: Boolean, default: false },
    type: {
      type: String,
      default: "primary",
      validator: val => ["primary", "secondary", "danger", 'light', 'lightdanger'].includes(val)
    },
    smallPadding: { type: Boolean, default: false },
    customPadding: { type: String, default: '' },

    disabled: { type: Boolean, default: false },
    disabledErrorType: { type: String, validator: val => ['toast', 'modal'].includes(val), default: 'toast' },
    disabledErrorTitle: { type: String, default: "" },
    disabledErrorMessage: { type: String, default: "" },
    emitClickEvenWhenDisabled: { type: Boolean, default: false},

    loading: { type: Boolean, default: false },
  },
  methods: {
    /**
     * Emits click. Will not emit when disabled.
     * Sends a Danger Toast with an error Message when given (and a disabled button is clicked).
     * @param e
     * @returns {boolean}
     */
    emitClick(e) {
      if (this.disabled) {
        if(this.emitClickEvenWhenDisabled) {
          this.$emit('click', e);
        }

        this.handleButtonDisabled();
        return false;
      }

      // Also don't emit action when loading.
      // Here, we won't emit so there's no error message.
      if(this.loading) {
        return false;
      }

      this.$emit('click', e);
    },

    handleButtonDisabled: debounce(function () {
      if (this.disabledErrorMessage?.length) {
        if (this.disabledErrorType === "toast") {
          this.$toast.show({
            type: 'danger',
            title: this.disabledErrorTitle.length ? this.disabledErrorTitle : this.$t('common.error'),
            message: this.disabledErrorMessage,
          });
        } else if (this.disabledErrorType === "modal") {
          this.$modal.show({
            type: 'danger',
            title: this.disabledErrorTitle.length ? this.disabledErrorTitle : this.$t('common.error'),
            body: this.disabledErrorMessage,
            primary: {
              label: 'OK',
              theme: 'danger',
              action: () => this.$emit('disabled-confirm'),
            },
          });
        }
      }
      return false;
    }, 240)
  },

  computed: {
    getFilledClassesByType() {
      switch (this.type) {
        case "primary": // Dark / Black
          return "bg-dark hover:bg-gray-700 text-white dark:text-white";
        case "secondary": // Luxcaddy Colors
          return "bg-luxcaddy hover:bg-luxcaddy-600 text-white dark:text-gray-500";
        case "danger":
          return "bg-red-500 hover:bg-red-600 text-white dark:text-gray-800";
        case "light":
          return "bg-gray-100 dark:bg-gray-500 hover:bg-gray-200 dark:hover:bg-gray-600 text-gray-800 dark:text-gray-300";
        case "lightdanger":
          return "bg-red-100 dark:bg-red-300 hover:bg-red-200 dark:hover:bg-red-400 text-gray-800 dark:text-gray-600 dark:hover:text-gray-800";
      }
    },
    getNotFilledClassesByType() {
      switch (this.type) {
        case "primary": // Dark / Black
          return "border-dark text-dark hover:bg-gray-100 dark:text-gray-300";
        case "secondary": // Luxcaddy Colors
          return "border-luxcaddy text-luxcaddy hover:bg-luxcaddy-100";
        case "danger":
          return "border-red-500 text-red-500 hover:bg-red-100";
        case "light":
          return "bg-gray-100 dark:bg-gray-500 hover:bg-gray-200 dark:hover:bg-gray-600 text-gray-800 dark:text-gray-300";
        case "lightdanger":
          return "bg-red-100 dark:bg-red-300 hover:bg-red-200 dark:hover:bg-red-400 text-gray-800 dark:text-gray-600 dark:hover:text-gray-800";
      }
    },
    getButtonTextColor() {
      if (this.disabled) {
        if (this.fill) {
          return "text-gray-600 dark:text-gray-800";
        }

        return "text-gray-600 dark:text-gray-200";
      }

      if (this.fill && this.type !== "light" && this.type !== "lightdanger" && this.type !== "primary") {
        return "text-white dark:text-gray-800";
      }
    },
  },
}
