
import ShopCallout from "@/layouts/shop/components/callouts/ShopCallout";

export default {
  name: "MainPageCalloutList",
  components: { ShopCallout },
  computed: {
    getCallouts() {
      return [
        {icon: 'shipping-fast', title: this.$t('home.callout.free_delivery'), subtitle: this.$t('home.callout.entire_country')},
        {icon: 'headset', title: "+352 26 45 90 33", subtitle: this.$t('home.callout.customer_service')},
        {icon: 'shield-alt', title: this.$t('home.callout.secure_payment'), subtitle: "Visa, Mastercard, Payconiq"},
        {icon: 'map-marked-alt', title: this.$t('home.callout.delivery_tracking'), subtitle: this.$t('home.callout.livetrack_your_delivery')},
      ].sort(function () {
        return Math.random() - 0.5;
      });
    }
  },
}
