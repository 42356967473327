
export default {
  name: "CheckoutFeeSelection",
  props: {
    fee: {
      type: Object,
      required: true
    }
  },
}
