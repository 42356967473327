
import SelectInput from "./SelectInput";
export default {
  name: "LanguageSelectInput",
  components: { SelectInput },
  props: {
    value: {
      type: String,
    },
    // When mounted and value is null,
    // will preselect the language if it matches the current locale
    preSelectCurrentLocale: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if(this.preSelectCurrentLocale && this.value === null) {
      const currentLocale = this.$i18n.locale;

      if(this.getLanguages.find(l => l.value === currentLocale)) {
        this.model = currentLocale;
      }
    }
  },
  computed: {
    getLanguages() {
      return [
        { label: this.$t('top_nav.languages.lb'), value: 'lb' },
        { label: this.$t('top_nav.languages.de'), value: 'de' },
        { label: this.$t('top_nav.languages.fr'), value: 'fr' },
        { label: this.$t('top_nav.languages.en'), value: 'en' },
      ];
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
}
