import { render, staticRenderFns } from "./ShopCartSummary.vue?vue&type=template&id=797a521a&scoped=true&"
import script from "./ShopCartSummary.vue?vue&type=script&lang=js&"
export * from "./ShopCartSummary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "797a521a",
  null
  
)

export default component.exports