
export default {
    name: "CustomTable",
    props: {
        headings: {type: Array, required: false},
    },
    computed: {
        getHeadings() {
            if(this.headings) {
              if(this.headings.every(h => (typeof h === "string"))) {
                return this.headings.map(h => {
                  return {
                    label: h
                  };
                });
              }
            }

            return this.headings;
        },
    },
    methods: {
        getClassByAlign(align) {
            switch (align) {
                case "center":
                    return 'text-center';
                case "right":
                    return 'text-right';
                default:
                    return 'text-left';
            }
        }
    }
}
