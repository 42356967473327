
import ShopButton from "../buttons/ShopButton";
import ShopCartItem from "./ShopCartItem";
import ShopCartItemList from "./ShopCartItemList";
import ShopSkeleton from "../skeleton/ShopSkeleton";
import ShopCartTitle from "./includes/ShopCartTitle";
import SkeletonCartItem from "../skeleton/cart/SkeletonCartItem";
import ShopCartSummary from "./includes/ShopCartSummary";
import ShopCartActions from "./includes/ShopCartActions";
import SkeletonCartActions from "../skeleton/cart/SkeletonCartActions";
import SkeletonCartSummary from "../skeleton/cart/SkeletonCartSummary";
import LoadingSpinner from "../loading/LoadingSpinner";
import ShopCartFeeList from "@/layouts/shop/components/cart/includes/fees/ShopCartFeeList";
import ShopCartGiftList from "@/layouts/shop/components/cart/includes/gifts/ShopCartGiftList";
import viewportMixin from "@/mixins/viewportMixin.js";

export default {
  name: "ShopCart",
  mixins: [viewportMixin],
  components: {
    ShopCartGiftList,
    ShopCartFeeList,
    LoadingSpinner,
    SkeletonCartSummary,
    SkeletonCartActions,
    ShopCartActions,
    ShopCartSummary,
    SkeletonCartItem,
    ShopCartTitle,
    ShopSkeleton,
    ShopCartItemList,
    ShopCartItem,
    ShopButton
  },
  props: {
    roundedBorder: {
      type: Boolean,
      default: true
    },
  },
  data: () => ({
    localCartLoading: false
  }),
  watch: {
    '$i18n.locale': function () {
      // Reload categories when language changed
      if (this.$auth.loggedIn) {
        this.$fetch();
      } else {
        this.loadGuestCart();
      }
    }
  },
  async fetch() {
    // Load Cart on Page Load
    if (this.$auth.loggedIn) {
      this.localCartLoading = true;
      await this.$store.dispatch('cart/fetchCart', this.isInCheckout)
          .finally(() => {
            this.localCartLoading = false;
            this.$emit('loaded');
          });
    }
  },
  fetchOnServer: false,
  created() {
    if (!this.$auth.loggedIn) {
      this.localCartLoading = true;
    }
  },
  mounted() {
    // Load Guest Card client side since we need to access localStorage
    if (!this.$auth.loggedIn) {
      this.loadGuestCart();
    }
  },
  methods: {
    loadGuestCart() {
      this.localCartLoading = true;
      this.$store.dispatch('guestCart/syncQuantitiesWithBackend')
          .finally(() => this.localCartLoading = false);
    }
  },
  computed: {
    isCartEmpty() {
      return this.$auth.loggedIn
          ? this.$store.getters["cart/isCartEmpty"]
          : this.$store.getters["guestCart/isCartEmpty"];
    },
    isCartLoading() {
      return this.$auth.loggedIn
          ? this.$store.getters["cart/isCartLoading"]
          : this.$store.getters["guestCart/isCartLoading"];
    },
    isInCheckout() {
      return this.$store.getters["checkout/isInCheckout"];
    },
    getCartErrors() {
      return this.$auth.loggedIn
          ? this.$store.getters["cart/getCartErrors"]
          : [];
    },


    hasCampaignsOrFees() {
      const campaigns = this.$auth.loggedIn
          ? this.$store.getters["cart/getCampaigns"]
          : this.$store.getters["guestCart/getCampaigns"];

      const fees = this.$auth.loggedIn
          ? this.$store.getters["cart/getFees"]
          : this.$store.getters["guestCart/getFees"];

      return campaigns.length > 0 || fees.length > 0;
    }
  },
}
