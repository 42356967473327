
export default {
  name: "ShopSidebarSimpleLink",
  props: {
    title: {
      type: String,
      default: 'primary'
    },
    type: {
      type: String,
      validator: val => ['primary', 'danger'].includes(val)
    },
    icon: {
      type: String,
      required: true
    },
    routeName: {
      type: String,
      required: true
    }
  },
  computed: {
    getTextClassByType() {
      switch (this.type) {
        case "primary":
          return "text-luxcaddy-500 hover:text-luxcaddy-600";
        case "danger":
          return "text-amber-600 hover:text-red-700";
      }
    }
  },
}
