
import { debounce } from "@/helpers/debounceHelper";
import QuantitySelector from "@/layouts/shop/components/quantitySelector/QuantitySelector.vue";
import ProductCardInfoContents from "@/layouts/shop/components/products/ProductCardInfos/ProductCardInfoContents.vue";
export default {
  name: "EditShoppingListItem",
  components: {ProductCardInfoContents, QuantitySelector },
  props: {
    item: {
      type: Object,
      required: true
    },
    listId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    quantity: 0,
  }),
  created() {
    this.quantity = this.item.quantity;
  },
  methods: {
    updateQuantity: debounce(function (quantity) {
      return this.$shoppingListRepository.setItemQuantity(
        this.listId,
        this.item.productId,
        quantity
      );
    }, 180),
    navigateToProduct() {
      this.$router.push(
        this.localePath({
          name: 'products-id-slug',
          params: {
            id: this.item.productId,
            slug: this.item.slug
          }
        })
      );
    }
  },
  computed: {
    getImageUrl() {
      return this.$getUrlOfImage(this.item, "small");
    },
    itemQuantity: {
      get() {
        return this.quantity;
      },
      set(value) {
        this.quantity = value;
        this.updateQuantity(value)

        if(value <= 0) {
          this.$emit('remove', this.item.productId);
        }
      }
    }
  },
}
