
import ShopCartActionClearCart from "./actions/ShopCartActionClearCart";
import ShopCartActionCreateShoppingList
  from "@/layouts/shop/components/cart/includes/actions/ShopCartActionCreateShoppingList.vue";
export default {
  name: "ShopCartTitle",
  components: {ShopCartActionCreateShoppingList, ShopCartActionClearCart},
  computed: {
    getTotalAmount() {
      return this.$auth.loggedIn
        ? this.$store.getters["cart/getTotals"]?.amount || 0
        : this.$store.getters["guestCart/getTotals"]?.amount || 0;
    },
    getAmountOfItems() {
      return this.$auth.loggedIn
        ? this.$store.getters["cart/getItemCount"]
        : this.$store.getters["guestCart/getItemCount"];
    },
    hasCartErrors() {
      return this.$auth.loggedIn
        ? this.$store.getters["cart/getHasCartErrors"]
        : this.$store.getters["guestCart/getHasCartErrors"];
    },
    isInCheckout() {
      return this.$store.getters['checkout/isInCheckout'];
    },
    isCartLoading() {
      return this.$auth.loggedIn
        ? this.$store.getters["cart/isCartLoading"]
        : this.$store.getters["guestCart/isCartLoading"];
    }
  }
}
