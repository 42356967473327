export default function ({$axios, req, $toast, $auth, $sentry, router, app, store, error: nuxtError}, inject) {

  // Attach the real client ip on the request
  if (req !== undefined) {
    const ip = req.headers['x-forwarded-for'] || req.socket.remoteAddress;
    $axios.onRequest((config) => {
      config.headers.common['x-forwarded-for'] = ip;
    });
  }

  /**
   * Check whether the app needs a hard refresh.
   *
   * @param response
   * @returns {boolean}
   */
  function handleAppVersionLogicFromResponse(response) {
    if (process.server || !response || !response.headers || !Object.prototype.hasOwnProperty.call(response.headers, 'x-app-version')) {
      return false;
    }

    let appVersionFromStore = store.getters['app/getAppVersion'];
    let appVersionFromHeader = response.headers["x-app-version"];

    // App version not previously set
    if (appVersionFromStore === null || appVersionFromStore === undefined) {
      store.commit('app/setAppVersion', appVersionFromHeader);
    } else {
      // App Version was already set
      if (appVersionFromStore !== appVersionFromHeader) {
        // Versions differ, force reload on next route change
        store.commit('app/setAppRefreshNeededTrue');
      }
    }
  }

  $axios.onRequest((config) => {
    if (app.$auth.loggedIn) {
      config.withCredentials = true;
    }

    const lang = app.i18n.locale === "lu"
      ? "lb"
      : app.i18n.locale || "fr";

    if (!config.headers.common) {
      config.headers.common = {};
    }

    config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    config.headers.common['Content-Type'] = 'application/json';
    config.headers.common['Accept'] = 'application/json';
    config.headers.common['Accept-Language'] = lang;

    return config;
  });

  $axios.onResponse(response => {
    handleAppVersionLogicFromResponse(response);
  });

  $axios.onError(async error => {
    if (process.server) {
      if(error.response && error.response.status) {
        console.error(`Code: ${error.response.status}`);

        if(error.response.data) {
          let resData = error.response.data;
          if(resData?.throwable?.stacktrace) {
            resData.throwable.stacktrace = "[...]";
          }
          console.error(resData);
        }
      }

      // Server Rendered
      if (error && error.response && (error.response.status === 401 || error.response.status === 419)) {
        try {
          if (app.$auth.loggedIn) {
            await app.$auth.logout();
          }
          app.$auth.reset();
        } catch (e) {
          $sentry.captureException(e);
        }

        // If the user really has been logged out before, redirect to login page.
        if (!app.$auth.loggedIn) {
          await app.router.push(app.localePath('/auth/login'));
        }

        // Add Debug
        $sentry.captureException(e);
        return Promise.reject(error);
      } else {
        nuxtError({statusCode: error.response?.status ?? 500});
        $sentry.captureException(error);
      }
      return Promise.resolve(false);

    } else {
      // Client Rendered

      if (error && error.response) {
        handleAppVersionLogicFromResponse(error.response);

        let errorMessage = error?.response?.data?.error || "Unknown Error";
        if (error.response.status === 429) {
          errorMessage = app.i18n.t("tmr_message");
        }

        switch (error.response.status) {
          case 401:
          case 419:
            // Don't redirect to login in this case
            if(error.config.ignoreAuthHandling === true) {
              return Promise.reject(error);
            }

            try {
              if (app.$auth.loggedIn) {
                await app.$auth.logout();
              }
              app.$auth.reset();
            } catch (e) {
              $sentry.captureException(e);
            }
            await app.router.push(app.localePath('/auth/login'));
            return Promise.reject(error);
            break;
          case 429:
            // Ensure dialog only shown once.
            if (process.client && !window.TMR_DIALOG_SHOWN) {
              window.TMR_DIALOG_SHOWN = true;
              app.$modal.show({
                type: 'danger',
                title: app.i18n.t('common.error'),
                body: errorMessage,
                primary: {
                  label: app.i18n.t('common.ok'),
                  theme: 'danger',
                  action: () => {
                    window.TMR_DIALOG_SHOWN = false;
                  },
                },
              });
            }
            return Promise.reject(error);
            break;
          default:
            if (process.client && error.response.status !== 401 && error.response.status !== 419) {

              if(error.config.ignoreErrorModal === true) {
                break;
              }

              app.$modal.show({
                type: 'danger',
                title: app.i18n.t('common.error'),
                body: errorMessage,
                primary: {
                  label: "Ok",
                  theme: 'danger',
                  action: () => {
                  },
                },
              });
            }
        }
      }

      return Promise.reject(error);
    }
  });

  inject('api', $axios);
}
