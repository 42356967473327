
import {ValidationObserver} from 'vee-validate';
import ShopButton from "../buttons/ShopButton";
import viewportMixin from "@/mixins/viewportMixin.js";

export default {
  name: "ShopFormWrapper",
  components: {ShopButton, ValidationObserver},
  props: {
    submitLabel: {
      type: String,
      default: "Submit"
    },
    submitButtonFullWidth: {
      type: Boolean,
      default: false
    },
    disableButton: {
      type: Boolean,
      default: false
    },
    hideSubmitButton: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isLoading: false
  }),
  mixins: [viewportMixin],
  methods: {
    async onSubmit() {
      if(!await this.$refs.validationObserver.validate()) {
        this.scrollFirstErrorInputIntoView();
      } else {
        if (!this.disableButton && !this.isLoading) {
          this.isLoading = true;
          this.$emit('submit', this.onLoaded);
        }
      }
    },
    onLoaded() {
      this.isLoading = false;
      this.resetValidation();
    },
    resetValidation() {
      if(this.$refs.validationObserver) {
        this.$refs.validationObserver.reset();
      }
    },

    // Scroll to the first Input that has an error.
    // The Input needs to have the class formInputHasError
    scrollFirstErrorInputIntoView() {
      if(process.client) {
        this.$nextTick(() => {
          const inputsWithError = document.getElementsByClassName('formInputHasError');

          if(inputsWithError.length > 0) {
            const firstErrorInput = inputsWithError[0];

            let yPos = firstErrorInput.getBoundingClientRect().top + window.scrollY - 25;

            // Subtract the height of the respective header in each breakpoint
            if(['default', 'sm', 'md', 'lg'].includes(this.getCurrentBreakpoint)) {
              yPos = yPos - 62;
            } else if(['xl', '2xl'].includes(this.getCurrentBreakpoint)) {
              yPos = yPos - 132;
            }

            window.scrollTo({top: yPos, behavior: 'smooth'});
          }
        });
      }
    }
  }
}
