
export default {
  name: "SkeletonBlogPostText",
  data: () => ({
    lineAmount: 14
  }),
  methods: {
    getRandomWidthClass() {
      const widthChoices = [
        'w-full',
        'w-3/5',
        'w-4/5',
      ];

      const index = Math.floor(Math.random() * widthChoices.length);
      return widthChoices[index];
    }
  },
}
