
import ShoppingListListItem from "@/layouts/shop/components/account/shoppingLists/ShoppingListListItem";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton";
import SingleFaqInModalIcon from "@/layouts/shop/components/faq/ShopFaqSingle/SingleFaqInModalIcon.vue";

export default {
  name: "ShoppingListList",
  components: {SingleFaqInModalIcon, ShopButton, ShoppingListListItem },
  computed: {
    getShoppingLists() {
      return this.$store.getters['shoppingLists/getShoppingLists'];
    }
  },
}
