
import ShopFooterLinkWithIcon from "@/layouts/shop/components/footer/ShopFooterLinkWithIcon";
import ShopFooterLink from "@/layouts/shop/components/footer/ShopFooterLink";
import ShopButton from "./components/buttons/ShopButton";
import BreakpointDebug from "@/layouts/shop/debug/BreakpointDebug.vue";
import ShopRouteButton from "@/layouts/shop/components/buttons/ShopRouteButton.vue";
import configHelper from "@/helpers/configHelper.js";

export default {
  name: 'ShopFooter',
  components: {ShopRouteButton, BreakpointDebug, ShopButton, ShopFooterLink, ShopFooterLinkWithIcon },
  computed: {
    getNewsletterSubscriptionRoute() {
      return this.localePath({
        name: 'newsletter-subscribe'
      });
    },
    areAppStoreLinksEnabled() {
      return configHelper.footer.enableAppStoreLinks;
    },
    getJobOffersCategoryId() {
      return configHelper.jobOffersCategoryId;
    }
  },
}
