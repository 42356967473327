export const state = () => ({
  //if a product is selected, a model will be opened to add this product to some of the shopping lists
  selectedProduct: null,
  cartSelected: false,

  shoppingLists: []
})



export const mutations = {
  setSelectedProduct(state, product) {
    state.selectedProduct = product;
  },
  setCartSelected(state) {
    state.cartSelected = true;
  },
  unselectProduct(state) {
    state.selectedProduct = null;
  },
  unselectCart(state) {
    state.cartSelected = false;
  },

  setShoppingLists(state, lists) {
    state.shoppingLists = lists;
  },

  setProductQuantityOnList(state, {listId, quantity}) {
    if(state.selectedProduct === null) {
      console.error("Trying to set product quantity on list but no product selected.");
      return false;
    }

    let list =  state.shoppingLists
      .find(l => l.id === listId);

    let productOnList = list
      ?.products
      .find(p => p.id === state.selectedProduct.id);

    if(productOnList) {
      if(quantity === 0) {
        // Remove
        state.shoppingLists.find(l => l.id === listId).products =
          state.shoppingLists.find(l => l.id === listId).products.filter(p => p.id !== state.selectedProduct.id);
      } else {
        // Modify
        productOnList.qty = quantity;
      }
    } else {
      state.shoppingLists.find(l => l.id === listId).products.push({
        id: state.selectedProduct.id,
        qty: quantity
      })
    }
  }
}


export const getters = {
  hasSelectedProduct: state => state.selectedProduct !== null,
  hasSelectedCart:  state => state.cartSelected === true,
  getSelectedProduct: state => state.selectedProduct,
  getSelectedProductId: state => state.selectedProduct?.id,
  getShoppingLists: state => state.shoppingLists,

  isProductOnAnyList: state => (productId) => {
    const products = state.shoppingLists.flatMap(l => l.products.flatMap(p => p.id));
    // Remove duplicates
    return [...new Set(products)].includes(productId);
  },

}

export const actions = {
  fetchCompactLists({ commit }) {
    this.$shoppingListRepository.getCompactShoppingLists().then((res) => {
      commit('setShoppingLists', res.data);
    });
  },

  setProductQuantity({dispatch, commit, getters}, { listId, quantity }) {
    return this.$shoppingListRepository.setItemQuantity(
      listId,
      getters.getSelectedProductId,
      quantity
    ).catch(() => {
      commit('unselectProduct');
      dispatch('fetchCompactLists')
    });
  },

  createList({dispatch, commit, getters}, title) {
    return this.$shoppingListRepository.createList(
      title,
      getters.getSelectedProductId,
      1
    ).then(() => {
      dispatch('fetchCompactLists');
    });
  },
  createListFromCart({dispatch, commit, getters}, title) {
    return this.$shoppingListRepository.createListFromCart(
      title,
      1
    ).then(() => {
      commit('unselectCart');
      dispatch('fetchCompactLists');
    });
  }
}
