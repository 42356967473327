
import ProductGrid from "@/layouts/shop/components/grids/ProductGrid";

export default {
  name: "RecipeProductGroup",
  components: { ProductGrid },
  props: {
    groupLabel: { type: String, required: true },
    productIds: { type: Array, required: true }
  },
  data: () => ({
    isOpen: false,
  }),
  computed: {
    getBannerConfig() {
      return {
        main: {
          false: true,
        },
        secondary: {
          false: true,
        }
      };
    },
    getProductsOfGroup() {
      return this.$store.getters['blog/getReferencedProducts'].filter(p => {
        return this.productIds.includes(p.id);
      });
    }
  },
}
