
import ShopCartFeeListItem from "@/layouts/shop/components/cart/includes/fees/ShopCartFeeListItem.vue";

export default {
  name: "ShopCartFeeList",
  components: { ShopCartFeeListItem },
  computed: {
    getFees() {
      return this.$auth.loggedIn
        ? this.$store.getters["cart/getFees"]
        : this.$store.getters["guestCart/getFees"];
    },
  },
}
