const url = "/page-data";

export default $api => ({
  async getPageData(pageIdentifier) {
    let paramsString = '';

    // Each param included with the pageIdentifier will be appended to the url.
    if(pageIdentifier.params) {
      Object.entries(pageIdentifier.params).forEach(v => {
        paramsString += `&pageParams[${v[0]}]=${v[1]}`;
      })
    }

    return await $api.$get(`${url}/?pageName=${pageIdentifier.name}${paramsString}`);
  },
});
