
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";
import CheckoutPaymentPromoCodeModal
  from "@/layouts/shop/components/checkout/payment/sub/promocode/CheckoutPaymentPromoCodeModal.vue";
import LoadingSvgInline from "@/layouts/shop/components/loading/LoadingSvgInline.vue";

export default {
  name: "CheckoutPromoCodeButton",
  components: {LoadingSvgInline, CheckoutPaymentPromoCodeModal, ShopButton},
  data() {
    return {
      showPromoCodeModal: false,
    }
  },
  methods: {
    removePromoCode() {
      this.$store.dispatch('checkout/removeAppliedPromoCode');
    }
  },
  computed: {
    getAppliedPromoCodeCampaignName() {
      return this.$store.getters["cart/getPromoCodeCampaignName"];
    },
    getAppliedPromoCode() {
      return this.$store.getters["cart/getPromoCode"];
    },
    isPromoCodeApplied() {
      return this.$store.getters["cart/isPromoCodeApplied"];
    },
    // Loading indicator while promo code is being removed.
    isRemovingPromoCode() {
      return this.$store.getters['checkout/getIsRemovingPromoCode'];
    },
  },
}
