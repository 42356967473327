
import ShopCheckboxButton from "@/layouts/shop/components/forms/inputs/checkbox/ShopCheckboxButton.vue";
import CheckoutCampaignSelection from "@/layouts/shop/components/checkout/confirmation/campaigns/CheckoutCampaignSelection.vue";
import CheckoutGiftFeeCard from "@/layouts/shop/components/checkout/confirmation/CheckoutGiftFeeCard.vue";

export default {
  name: "CheckoutCampaignManagement",
  components: {
    CheckoutGiftFeeCard,
    CheckoutCampaignSelection,
    ShopCheckboxButton,
  },
  computed: {
    getLoadedCampaignsWithConfirmation() {
      return this.$store.getters['cart/getLoadedCampaignsWithConfirmation'];
    }
  },
  methods: {
    fetchCart() {
      this.$store.dispatch('cart/fetchCart');
    }
  },
}
