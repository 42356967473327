
import CheckoutTimeSlotPercentageBar
  from "@/layouts/shop/components/checkout/timeSlot/selector/sub/CheckoutTimeSlotPercentageBar.vue";
import QuestionMarkButton from "@/layouts/shop/components/buttons/QuestionMarkButton.vue";

export default {
  name: "DeliveryTimeSlotPreviewSlot",
  components: {QuestionMarkButton, CheckoutTimeSlotPercentageBar},
  props: {
    timeslot: {
      type: Object,
      required: true,
    },
  },
  methods: {
    showCampaignDescription(campaign) {
      if(campaign.description === null) {
        return false;
      }

      this.$modal.show({
        type: 'success',
        title: campaign.name,
        body: campaign.description,
        primary: {
          label: this.$t('common.ok'),
          theme: 'primary',
          action: () => {}
        }
      });
    }
  },
  computed: {
    getDiscountOrFixedCampaigns() {
      return (this.timeslot.loadedCampaigns || []).filter(c => ["fixed", "discount"].includes(c.type.toLowerCase()));
    },
    hasDiscountOrFixedCampaign() {
      return this.getDiscountOrFixedCampaigns.length > 0;
    }
  },
}
