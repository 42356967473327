
export default {
  name: "TimeLineImageBlock",
  props: {
    imageSource: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
  },
  methods: {
    onClick() {
      window.open(this.url);
    }
  }
}
