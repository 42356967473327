export const state = () => ({
  isImageModalOpen: false,
  modalTitle: null,


  images: [],

  currentImageUrl: null,
  currentImageNumber: null,
})

export const mutations = {
  setImageModelOpen(state, bool) {
    state.isImageModalOpen = bool;
  },

  setModalTitle(state, title) {
    state.modalTitle = title;
  },

  setImages(state, images) {
    state.images = images;
  },

  setCurrentImage(state, imageUrl) {
    state.currentImageUrl = imageUrl;
  },
  setCurrentImageNumber(state, imageNumber) {
    state.currentImageNumber = imageNumber;
  },

  clearImages(state) {
    state.isImageModalOpen = false;
    state.images = [];
    state.currentImageUrl = null;
    state.currentImageNumber = null;
    state.modalTitle = null;
  }
}

export const getters = {
  isImageModalOpen: state => state.isImageModalOpen,
  getImages: state => state.images,
  getModalTitle: state => state.modalTitle,

  getCurrentImage: state => state.currentImageUrl,
  getCurrentImageNumber: state => state.currentImageNumber,

  getImagesCount: state => state.images.length,
}
