
export default {
  name: "ProductPageCategoryItem",
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  filters: {
    truncate: function (text, length) {
      if (text.length > length) {
        let splitName = text.split('>');
        return splitName[0] + '> … >' + splitName.pop();
      } else {
        return text;
      }
    },
  },
  computed: {
    getCategoryPageLink() {
      return this.localePath({
        name: 'categories-id-slug',
        params: {
          id: this.category.id,
          slug: this.category.slug,
        }
      });
    }
  },
}
