
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";
import ShopTextInputElement from "@/layouts/shop/components/forms/inputs/ShopTextInputElement.vue";

export default {
  name: "AccountDeletionButton",
  components: {ShopTextInputElement, ShopButton},
  data() {
    return {
      hasClickedButton: false,
      password: null,
    }
  },
  methods: {
    requestAccountDeletion() {
      if(!this.hasTypedInPasswordField) {
        return false;
      }

      this
        .$accountAndProfileRepository
        .requestAccountDeletion(this.password)
        .then(() => {
          this.showConfirmationDialog();
          this.cancelRequest();
          this.$router.push(this.localeRoute('/'));
        }).catch(() => {
          this.password = null;
      });
    },
    cancelRequest() {
      this.password = null;
      this.hasClickedButton = false;
    },
    showConfirmationDialog() {
      this.$modal.show({
        type: 'success',
        title: this.$t('account.profile.account_deletion.confirmation_title'),
        body: this.$t('account.profile.account_deletion.confirmation_text'),
        primary: {
          label: this.$t('common.ok'),
          theme: 'light',
          action: () => {}
        }
      });
    }
  },
  computed: {
    hasTypedInPasswordField() {
      return this.password !== null && (typeof this.password === "string" && this.password.length >= 6);
    }
  },
}
