export default ({ app }) => {
  app.router.beforeEach((to, from, next) => {
    if(process.server) {
      next();
    }

    const pageIdentifier = app.$pageDataHelper.getPageIdentifierByRoute(to);

    if(pageIdentifier === null || pageIdentifier === undefined) {
      next();
      return false;
    }

    app.store.dispatch('pageData/fetchPageData', pageIdentifier).finally(() => {
      next();
    });
  });
}
