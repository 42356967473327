
import CheckoutCampaignManagement from "@/layouts/shop/components/checkout/confirmation/campaigns/CheckoutCampaignManagement.vue";
import ShopModal from "@/layouts/shop/components/modal/ShopModal.vue";
import CheckoutFeeManagement from "@/layouts/shop/components/checkout/confirmation/fees/CheckoutFeeManagement.vue";
import {mapGetters} from "vuex";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";

export default {
  name: "CheckoutFeeCampaignConfirmationModal",
  components: {CheckoutFeeManagement, CheckoutCampaignManagement, ShopButton, ShopModal},
  beforeDestroy() {
    this.$store.commit('checkout/setShowConfirmationModal', false);
  },
  computed: {
    ...mapGetters('cart', [
      'getLoadedCampaignsWithConfirmation',
      'getFees',
      'isCartLoading'
    ]),
    ...mapGetters('checkout', [
      'getShowConfirmationModal',
      'getConfirmButtonLoading',
      'isInCheckout'
    ]),
    showLoadingIndicator() {
      return this.getConfirmButtonLoading || this.isCartLoading;
    },
    hasSelectedPaymentMethod() {
      if (this.getSelectedPaymentMethod === 'wallet' && this.getTotalAmount > this.$auth.user.walletAmount) {
        return false;
      }
      return this.getSelectedPaymentMethod !== null;
    },
  },
  methods: {
    onCampaignFeeManagementModalClose() {
      this.$store.commit('checkout/setShowConfirmationModal', false);
    },
    initPayment() {
      if (!this.hasSelectedPaymentMethod) {
        return false;
      }

      this.$store.dispatch('checkout/initializePaymentForCart')
        .finally(() => {
          this.$nextTick(() => {
            return this.$router.push(this.localePath('checkout-payment-payment-init'));
          });
        });
    },
  }
}
