
import DeliveryGraphLegend from "@/layouts/shop/components/account/orders/delivery/DeliveryGraphLegend.vue";

export default {
  name: "DeliveryGraph",
  components: { DeliveryGraphLegend },
  data() {
    return {
      metersToShow: 20000 // Defines how many Meters will be shown in the graph (-> 20km)
    }
  },
  props: {
    deliveryData: {
      type: Object,
      required: true
    }
  },
  computed: {
    getSteps() {
      return this.deliveryData
        .steps
        .filter(s => s.distanceToPrevious !== null);
    },
    getRelativeDeliveryDistances() {
      let relativeDistances = this.getSteps
        .flatMap(s => s.distanceToPrevious);

      let reversedDistances = relativeDistances.reverse();
      let totalSum = 0;
      // Calculate the distances between each delivery together.
      // If it surpasses metersToShow (20km) it will be cut so that exactly 20km is shown.
      reversedDistances = reversedDistances.map(d => {
        if((totalSum + d) > this.metersToShow) {
          d = this.metersToShow - (totalSum);
        }
        totalSum += d;
        return d;
      })

      return reversedDistances.reverse();
    },

    getTotalDistance() {
      return this.getRelativeDeliveryDistances
        .reduce((partialSum, a) => partialSum + a, 0);
    },

    getRelativeDeliveryDistancesInPercentage() {
      let totalDistance = this.getTotalDistance;

      let distances = this.getRelativeDeliveryDistances
        // Get Percentage relative to beginning (0)
        .map((d) => (100 * d / totalDistance) || 0);

      // Take into account previous values to convert relative to absolute percentage values
      let sum = 0;

      distances = distances.map(d => {
        return sum += d;
      });

      // Remove last step since thats our delivery
      distances.pop();
      return distances;
    },
    getDriverPercentagePosition() {
      // Return the percentage relative to 100
      return this.getPercentageOfDistanceRelativeToTotalDistance(this.getAbsoluteDriverPosition);
    },
    getAbsoluteDriverPosition() {
      // Select the Step that the driver will approach next
      let stepUpForDeliveryIndex = this.getSteps.findIndex(
        s => s.deliveryId !== undefined && s.deliveryId === this.deliveryData.id);
      let step = this.getSteps.find(s => s.deliveryId !== undefined && s.deliveryId === this.deliveryData.id);

      if(step === undefined || stepUpForDeliveryIndex === undefined) {
        return this.getPercentageOfDistanceRelativeToTotalDistance(0);
      }

      // Calculate how far the driver is away from that delivery
      if (step.distanceFromCurrentDriverPosition === null || step.distanceFromCurrentDriverPosition > this.getTotalDistance ) {
        return this.getPercentageOfDistanceRelativeToTotalDistance(0);
      }

      return this.getTotalDistance - step.distanceFromCurrentDriverPosition;
    },

    isDebugActivated() {
      const memberId = this.$auth.user.memberId;
      return [
        40642, // Laurent
        50165 // Ilinca
      ].includes(memberId);
    }
  },
  methods: {
    // Returns the percentage based on the distance relative to the complete path (all step distances accumulated)
    getPercentageOfDistanceRelativeToTotalDistance(distance) {
      return ((100 * distance) / this.getTotalDistance).toFixed(2);
    }
  },
}
