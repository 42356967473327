
import ShopSidebarMenu from "./partials/sidebar/ShopSidebarMenu";
import ShopThemeToggle from "./components/theme/ShopThemeToggle";
import LogoSvg from "./svgs/LogoSvg";
import MadeInLuxembourgSvg from "./svgs/MadeInLuxembourgSvg";

export default {
  name: 'ShopSidebar',
  components: { MadeInLuxembourgSvg, LogoSvg, ShopThemeToggle, ShopSidebarMenu },
  methods: {
    redirectToHome() {
      this.$router.push(this.localePath({ name: 'index' }));
    },
  },
}
