
export default {
  name: "ProductRibbon",
  props: {
    isDiscounted: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    mirror: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getColorClass() {
      if(this.isDiscounted) {
        return 'bg-amber-600';
      }
      if(this.isNew) {
        return 'bg-luxcaddy-500';
      }
      return '';
    }
  },
}
