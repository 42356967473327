
export default {
    name: "TableDataCell",
    props: {
        align: {
            type: String,
            default: 'left'
        },
        colspan: {
            type: [String, Number],
            default: 1,
        }
    },
    computed: {
        getClassByAlign() {
            switch (this.align) {
                case "center":
                    return 'text-center';
                case "right":
                    return 'float-right clear-both';
                default:
                    return '';
            }
        }
    }
}
