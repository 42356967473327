

export default $api => ({
  async getCountries() {
    return await $api.$get(`/countries`);
  },

  async getCountryPhonePrefixList() {
    return await $api.$get(`/countryPhonePrefix`);
  }
});
