
export default {
  name: "ShopModal",
  props: {
    contentPadding: {
      type: Boolean,
      default: false
    },
    allowClose: {
      type: Boolean,
      default: true
    },
    big: {
      type: Boolean,
      default: false
    },
    maxHeightMdUp: {
      type: String,
      default: "md:h-full md:max-h-80"
    },
    footerButtonGap: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.blockBackgroundScroll();
  },
  beforeDestroy() {
    this.unblockBackgroundScroll();
    this.$emit('close');
  },
  methods: {
    closeMe() {
      this.$destroy();
    },
    blockBackgroundScroll() {
      document.getElementsByTagName("html")[0].classList.add('overflow-y-hidden', 'padding-right-fix');
    },
    unblockBackgroundScroll() {
      document.getElementsByTagName("html")[0].classList.remove('overflow-y-hidden', 'padding-right-fix');
    }
  },
}
