
export default {
  name: 'ShopThemeToggle',
  props: {
    withText: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getColorPreference() {
      return this.$colorMode.preference;
    },
    getTextBySelection() {
      switch (this.getColorPreference) {
        case "light":
          return "Light";
        case "dark":
          return "Dark";
        default:
          return "System";
      }
    }
  },
  methods: {
    switchTheme() {
      switch (this.getColorPreference) {
        case "light":
          this.$colorMode.preference = "dark";
          break;
        case "dark":
          this.$colorMode.preference = "system";
          break;
        default:
          this.$colorMode.preference = "light";
          break;
      }
    }
  }
}
