
import CheckoutPackagingPricingDetails
  from "@/layouts/shop/components/checkout/packaging/sub/selection/CheckoutPackagingPricingDetails.vue";
import CheckoutPackagingRefundableBadge
  from "@/layouts/shop/components/checkout/packaging/sub/selection/CheckoutPackagingRefundableBadge.vue";

export default {
  name: "CheckoutPackagingSelectedBagsItem",
  components: {CheckoutPackagingRefundableBadge, CheckoutPackagingPricingDetails},
  props: {
    bag: {
      type: Object,
      required: true
    }
  },
  computed: {
    getImageUrl() {
      return this.$getUrlOfImage(this.bag, 'small');
    },
  },
}
