
import CheckoutCreditCard
  from "@/layouts/shop/components/retryPaymentAfterOrderCreation/payment/Creditcard/CheckoutCreditCard.vue";
import DeliveryContactCreateButton
  from "@/layouts/shop/components/account/deliveryContacts/AccountAreaCreateButton.vue";

export default {
  name: "AccountCreditCardList",
  components: {DeliveryContactCreateButton, CheckoutCreditCard},
  props: {
    cards: Array
  },
  computed: {
    hasCards() {
      return this.cards.length > 0;
    }
  },
  methods: {
    showExplanationModal() {
      this.$modal.show({
        type: 'danger',
        title: this.$t('checkout.payment_method_add_modal.title'),
        body: this.$t('checkout.payment_method_add_modal.body'),
        primary: {
          label: this.$t('common.confirm'),
          theme: 'danger',
          action: () => {}
        },
      });
    }
  }
}
