
export default {
  name: "ProductCardShortDateBadge",
  props: {
    textSizeClass: {
      type: String,
      default: "text-xs"
    },
    paddingClasses: {
      type: String,
      default: 'px-2 py-0.5'
    }
  },
}
