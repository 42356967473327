export default {
  methods: {
    getValidationRulesForField(
      fieldName, // title, firstName, familyName, companyName
      titleValue,
      firstNameValue,
      familyNameValue,
      companyNameValue
      ) {
      if (fieldName === 'title' || fieldName === 'firstName' || fieldName === 'familyName') {
        // When all 4 fields empty, title, firstname and lastname is marked as required
        if (titleValue?.length === 0 && firstNameValue?.length === 0 && familyNameValue?.length === 0 && companyNameValue?.length === 0) {
          return "required";
        }

        if (titleValue?.length > 0 || familyNameValue?.length > 0 || firstNameValue?.length > 0) {
          return "required";
        }
        return "";
      } else if (fieldName === "companyName") {
        if (titleValue?.length > 0 || familyNameValue?.length > 0 || firstNameValue?.length > 0) {
          return "";
        }
        return "required";
      }
      return "";
    },
  }
};
