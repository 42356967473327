
import ShopNavMenuLanguageSwitcher from "../../components/nav/button/ShopNavMenuLanguageSwitcher";
import ShopNavMenuLink from "./ShopNavMenuLink";
import ShopNavButtonMenuLink from "./ShopNavButtonMenuLink";
import ShopNavRecipeButton from "@/layouts/shop/partials/nav/recipe/ShopNavRecipeButton";
import ShopNavMenuLinkSimple from "@/layouts/shop/partials/nav/ShopNavMenuLinkSimple.vue";

export default {
  name: "ShopNavMenu",
  components: { ShopNavMenuLinkSimple, ShopNavRecipeButton, ShopNavButtonMenuLink, ShopNavMenuLanguageSwitcher, ShopNavMenuLink }
}
