
export default {
  name: 'ShopCallout',
  props: {
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    }
  }
}
