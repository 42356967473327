
import configHelper from "@/helpers/configHelper.js";

export default {
  name: "PaymentMethodImage",
  props: {
    method: {
      type: String,
      required: true,
    },
    creditCardType: {
      type: String,
      validator: (val) => ["VISA", "MASTERCARD"].includes(val),
    },
  },
  computed: {
    getImagePath() {
      switch (this.method) {
        case "saferpay":
          if (!this.creditCardType) {
            return require("~/assets/images/dummy/paymentmethods/credit-card.png");
          }
          return require(`~/assets/images/dummy/paymentmethods/${this.creditCardType.toLowerCase()}.png`);
        case "payconiq":
          return require("~/assets/images/dummy/paymentmethods/payconiq.png");
        case "bankTransfer":
        case "invoice":
          return require("~/assets/images/dummy/paymentmethods/invoice.png");
        case "monthlyInvoice":
          return require("~/assets/images/dummy/paymentmethods/monthly-invoice.png");
        case "wallet":
          return require("~/assets/images/dummy/paymentmethods/wallet.png");
        default:
          return null;
      }
    },
    getTranslationForPaymentMethod() {
      return this.$t(configHelper.getTranslationKeyForPaymentMethod(this.method));
    },
  },
};
