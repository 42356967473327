
import ShopNavMenuLink from "../../../partials/nav/ShopNavMenuLink";
import ShopNavButtonMenuLink from "../../../partials/nav/ShopNavButtonMenuLink";
export default {
  name: "ShopNavMenuLanguageSwitcher",
  components: { ShopNavButtonMenuLink, ShopNavMenuLink },
  methods: {
    setLocale(localeCode) {
      this.$i18n.setLocale(localeCode);

      // Set Language for Dayjs
      if(localeCode === "lb") {
        localeCode = "fr";
      }
      this.$dayjs.locale(localeCode);
    }
  },
  computed: {
    availableLocales () {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    },
    getCurrentLocaleString() {
      return this.$i18n.locale.toUpperCase();
    },
  }
}
