const url = "/my/shoppingList";

export default $api => ({
  async getCompactShoppingLists() {
    return await $api.$get(`${url}`);
  },

  async setItemQuantity(listId, productId, quantity) {
    return await $api.$patch(`${url}/${listId}/addItem`, {
        item: {
          productId: productId,
          quantity: quantity
        }
      },
      { progress: false }
    );
  },

  async createList(title, productId, quantity) {
    return await $api.$post(`${url}`, {
        title: title,
        item: {
          productId: productId,
          quantity: quantity
        }
      },
      { progress: false, ignoreErrorModal: true }
    );
  },
  async createListFromCart(title) {
    return await $api.$post(`${url}/fromCart`, {
        title: title
      },
      { progress: false, ignoreErrorModal: true  }
    );
  },

  async getListDetail(listId) {
    return await $api.$get(`${url}/${listId}`);
  },

  async update(listId, payload) {
    return await $api.$patch(`${url}/${listId}`, payload);
  },

  async delete(listId) {
    return await $api.$delete(`${url}/${listId}`);
  },

  async addToCart(listId) {
    return await $api.$patch(`${url}/${listId}/addToCart`);
  },
});
