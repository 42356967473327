const url = "/my/deliveryContacts";

export default $api => ({
  async getContacts() {
    let call = `${url}`;

    return await $api.$get(call);
  },
  async getSingleContact(contactId) {
    return await $api.$get(`${url}/${contactId}`);
  },
  async createContact(payload) {
    return await $api.$post(`${url}`, payload);
  },
  async updateContact(contactId, payload) {
    return await $api.$patch(`${url}/${contactId}`, payload);
  },
  async deleteContact(contactId) {
    return $api.$delete(`${url}/${contactId}`);
  }
});
