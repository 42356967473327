
export default {
  name: "DropDownMenu",
  data: () => ({
    inversePositioning: false
  }),
  props: {
    fullWidthOnMobile: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if(!this.isElementCompletelyInViewPort()) {
      this.inversePositioning = true;
    }
  },
  methods: {
    /**
     * While we still have applied left-0, we check if any part of the menu
     * is outside of the viewport. If so, we set inversePositioning to true
     * so that instead of right-0, left-0 will be applied.
     *
     * TODO: Find even better solution in the feature because
     * this will probably still cause problems when the menu would be to wide
     * to stay withing the viewport at all, even after inversing positioning.
     *
     * @returns {boolean}
     */
    isElementCompletelyInViewPort() {
      let el = this.$el;

      let top = el.offsetTop;
      let left = el.offsetLeft;
      let width = el.offsetWidth;
      let height = el.offsetHeight;

      while(el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }

      return (
        top >= window.scrollY &&
        left >= window.scrollY &&
        (top + height) <= (window.scrollY + window.innerHeight) &&
        (left + width) <= (window.scrollX + window.innerWidth)
      );
    }
  },
}
