
import RecipeResumeBox from "@/layouts/shop/components/recipes/RecipeResumeBox";
import RecipeProductGroup from "@/layouts/shop/components/recipes/RecipeProductGroup";
import Vue from 'vue';
import ProductGrid from "@/layouts/shop/components/grids/ProductGrid";

Vue.component('recipe-resume-box', RecipeResumeBox);
Vue.component('recipe-product-group', RecipeProductGroup);
Vue.component('product-grid', ProductGrid);

export default {
  name: "BlogPostTextRender",
  props: {
    text: {
      type: String,
      required: true
    },
    // products referenced in the blog post
    products: {
      type: Array,
      default: () => []
    }
  },
  created() {
    this.$store.commit('blog/setReferencedProducts', this.products);
  },
  methods: {
    replaceRecipeBoxes(text) {
      return text
        .replace(
          /\[recipe personcount="(.*?)" preptime="(.*?)" cookingtime="(.*?)"\]/gs,
          "<div class='not-prose flex flex-none flex-col md:flex-row gap-y-2 gap-x-4'>" +
          "<recipe-resume-box type='people' class='md:flex-1'>$1</recipe-resume-box>" +
          "<recipe-resume-box type='prep' class='md:flex-1'>$2</recipe-resume-box>" +
          "<recipe-resume-box type='cook' class='md:flex-1'>$3</recipe-resume-box>" +
          "</div>"
        );
    },
    /**
     * Replaces "{ProductGroup:[[1]][[2]]}" with a toggleable Product Group where the user can
     * add products to his cart.
     *
     * @param text
     * @returns {*}
     */
    replaceProductGroups(text) {
      const vm = this;

      function getComponent(match, groupLabel, productIds) {
        productIds = vm.getFlatProductIdArray(productIds);

        // Negative Margin nullifies the <br> after each group.
        return `
            <recipe-product-group
                class="not-prose"
                group-label="${groupLabel}"
                :product-ids="${JSON.stringify(productIds)}"
                style="margin-bottom: -14px; margin-top: -14px"
            />`;
      }

      return text
        .replace(/{(.*?):(.*?)}/gs, getComponent);
    },

    /**
     * Replaces "{[[1]][[2]]}" with a Product Grid immediately shown
     *
     * @param text
     * @returns {*}
     */
    replaceProductsWithGrid(text) {
      const vm = this;

      function productReplaceFunc(match, productIds) {
        productIds = vm.getFlatProductIdArray(productIds);
        return `<product-grid ignore-filters for-blog class="my-3 not-prose" :blog-product-ids="${JSON.stringify(productIds)}" />`;
      }

      return text.replace(/{(\[\[.*?)}/gs, productReplaceFunc);
    },

    /**
     * Maps something like [[1]][[2]] to an Array of product ids.
     * @param productIdGroups
     * @returns {number[]}
     */
    getFlatProductIdArray(productIdGroups) {
      return [...productIdGroups.matchAll(/\[\[(.*?)\]\]/gs)].map(match => {
        return Number(match[1]);
      });
    }
  },
  computed: {
    getFormattedText() {
      let text = this.text;

      // order is important
      text = this.replaceRecipeBoxes(text);
      text = this.replaceProductsWithGrid(text);
      text = this.replaceProductGroups(text);


      return {
        template: '<div>' + text + '</div>',
      }
    }
  },
}
