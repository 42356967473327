const url = "/delivery";

export default $api => ({
  async getTrackingData(deliveryId) {
    return await $api.$get(`/my${url}/${deliveryId}/trackingData`, {
      ignoreAuthHandling: true
    });
  },
  async getDetailsByToken(deliveryId, token) {
    return await $api.$get(`${url}/${deliveryId}/details?token=${token}`);
  },
  async sendDeliveryProblemNotification(deliveryId, payload) {
    return await $api.$post(`${url}/${deliveryId}/notification`, payload);
  },
});
