
import LogoSvg from "@/layouts/shop/svgs/LogoSvg.vue";

export default {
  name: "CategoryCard",
  components: {LogoSvg},
  props: {
    category: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      validator: val => ["products", "posts", "recipes"].includes(val)
    },
    showImages: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getIconUrl() {
      return this.category.iconUrl;
    },
    getCategoryRoute() {
      if (this.type === "products") {
        return this.localePath({
          name: 'categories-id-slug',
          params: {
            id: this.category.id,
            slug: this.category.slug,
          }
        });
      } else if (this.type === "posts" || this.type === "recipes") {
        return this.localePath({
          name: 'blog-type-category-categoryId',
          params: {
            type: this.type,
            categoryId: this.category.id
          }
        });
      }
    }
  },
}
