

export default {
  name: "DeliveryTrackingStatusText",
  props: {
    deliveryStatus: {
      type: String,
      default: ""
    }
  },
  computed: {
    getTextToDisplay() {
      if(this.$te('account.orders.delivery_tracking.statuses.' + this.deliveryStatus)){
        return this.$t('account.orders.delivery_tracking.statuses.' + this.deliveryStatus);
      }
      return 'Unknown status';
    }
  },
}
