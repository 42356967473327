import configHelper from "@/helpers/configHelper";

const url = "/my/cart/payment";

export default $api => ({
  /**
   * Used to initialize a payment on an existing, unpaid order.
   *
   * @param orderId
   * @param paymentMethod
   * @param cardAlias
   * @param saveCard
   * @param trackingParams
   * @returns {Promise<void>}
   */
  async initializePaymentForExistingOrder(orderId, paymentMethod, cardAlias = null, saveCard = false, trackingParams = null) {
    let payload = {
      paymentMethod: paymentMethod,
      trackingParams: trackingParams
    };

    if (paymentMethod === configHelper.paymentMethods.saferpay) {
      payload.cardAlias = cardAlias;
      payload.saveCard = saveCard;
    }

    return await $api.$post(`/my/orders/${orderId}/payment`, payload);
  },

  /**
   * Used to initialize a payment from a cart.
   * @param paymentMethod
   * @param deliveryContactId
   * @param deliveryTimeSlotId
   * @param packageOptions
   * @param excludedCampaigns
   * @param promoCode
   * @param cardAlias
   * @param saveCard
   * @param utmParams
   * @returns {Promise<any>}
   */
  async initializePaymentForCart(
    paymentMethod,
    {deliveryContactId, deliveryTimeSlotId, packageOptions, excludedCampaigns = [], promoCode = null, cardAlias = null, saveCard = false, trackingParams}
  ) {

    let payload = {
      paymentMethod: paymentMethod,
      deliveryContactId: deliveryContactId,
      deliveryTimeSlotId: deliveryTimeSlotId,
      packageOptions: packageOptions,
      excludedCampaigns: excludedCampaigns,
      promoCode: promoCode,
      trackingParams: trackingParams
    };

    if (paymentMethod === configHelper.paymentMethods.saferpay) {
      payload.cardAlias = cardAlias;
      payload.saveCard = saveCard;
    }

    return await $api.$post(`${url}/process`, payload);
  },

  saferpay: {
    async checkStatus(token) {
      return await $api.$get(`${url}/preAuthorize/status?token=${token}&paymentMethod=saferpay`);
    },

    async fetchSavedCards() {
      return await $api.$get(`my/cards/all`);
    },

    async deleteSavedCard(cardId) {
      return $api.$delete(`my/cards/${cardId}`);
    }
  },

  payconiq: {
    async checkPaymentStatus(paymentId) {
      return await $api.$get(`${url}/direct/status?paymentId=${paymentId}&paymentMethod=payconiq`);
    },
  },
});
