const url = "/blog";

export default $api => ({
  async listBlogPosts(page = 1, perPage = 12, categoryId) {
    // type = [blogpost, recipe]
    return await $api.$get(`${url}/?page=${page}&perPage=${perPage}&filter[categoryId]=${categoryId}&sort[]=publishedAt,desc`);
  },

  async listNewestRecipes() {
    // type = [blogpost, recipe]
    return await $api.$get(`${url}/newest-recipes`);
  },

  async getBlogPost(blogPostId) {
    return await $api.$get(`${url}/${blogPostId}`);
  },

  async getBlogCategoryTree() {
    return await $api.$get(`${url}/categories/blog`);
  },

  async getRecipeCategoryTree() {
    return await $api.$get(`${url}/categories/recipes`);
  },


  async getFaq() {
    return await $api.$get(`${url}/faq`);
  },

  async getFaqByIdentifier(identifier) {
    return await $api.$get(`${url}/faq/${identifier}`);
  }
});
