
import ProductCardInfoContents from "@/layouts/shop/components/products/ProductCardInfos/ProductCardInfoContents.vue";

export default {
  name: "AccountOrderItemSingle",
  components: {ProductCardInfoContents},
  props: {
    item: {
      type: Object,
      required: true
    },
    isReferenceItem: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getItemName() {
      return this.item.name;
    },
    getOrderedQuantity() {
      return this.item.toBePackedQuantity;
    },

    getItemType() {
      return this.item.type;
    },
    isOfTypeProduct() {
      return this.getItemType.toLowerCase() === "product";
    },
    isOfTypePackage() {
      return this.getItemType.toLowerCase() === "package";
    },

    /**
     * Returns the item price.
     *
     * @returns {string}
     */
    getPrice() {
      if ((this.item.price?.amount ?? null) !== null) {
        return this.$moneyHelper.centsToEuroString(this.item.price.amount);
      }
      return null;
    },
    isPositivePrice() {
      return this.item.price?.amount > 0;
    },
    getPriceClass() {
      let priceClass = this.isReferenceItem ? '3xl:text-xs 4xl:text-sm' : 'font-medium 3xl:text-sm 4xl:text-md';

      if (!this.isPositivePrice) {
        priceClass += ' text-amber-600'
      } else {
        priceClass += ' text-luxcaddy'
      }

      return priceClass;
    },

    getImageUrl() {
      if (this.item.media) {
        return this.$getUrlOfImage(this.item.media, "small");
      }

      return this.$getUrlOfImage(this.item, "small");
    },
  },
}
