
export default {
  name: "ModalBase",
  props: {
    destroyed: {
      type: Function,
      required: false,
      default: () => {
      },
    },
    innerClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      active: false,
    }
  },
  mounted() {
    this.active = true;
    this.$nextTick(() => {
      this.$refs.modal.focus();
    });
  },
  methods: {
    async destroy() {
      this.active = false;
      setTimeout(() => {
        this.destroyed();
      }, 200);
    },
  },
}
