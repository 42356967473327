
export default {
  name: "CheckoutPackagingRefundableBadge",
  props: {
    bag: {
      type: Object,
      required: true
    }
  },
  computed: {
    showAsFixed() {
      return !this.isComputedQuantity && this.bag.maximumQuantity === 1;
    },
    isComputedQuantity() {
      return this.bag.isComputedQuantity;
    },
  },
}
