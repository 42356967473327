const url = "/timeSlots";

export default $api => ({
  async getTimeslots(zone = null, postalCode = null) {
    let call = `${url}`;

    let params = new URLSearchParams();

    if(postalCode) {
      params.append('filter[postalCode]', postalCode);
    }

    if(zone) {
      params.append('filter[zone]', zone);
    }

    params.append('filter[checkMaxHour]', 1);


    return await $api.$get(call + '?' + params.toString());
  },
});
