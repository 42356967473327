

import ShopPageTitle from "@/layouts/shop/components/pages/ShopPageTitle";
import SkeletonFilter from "@/layouts/shop/components/skeleton/filters/SkeletonFilter";
import ProductFilterBar from "@/layouts/shop/components/filters/products/common/ProductFilterBar";
import { FILTER_TYPE_ALLERGEN, FILTER_TYPE_LABEL } from "@/store/filters";

export default {
  name: "ShopPageTitleWithFilter",
  components: { ProductFilterBar, SkeletonFilter, ShopPageTitle },
  props: {
    title: {
      type: String,
      default: ""
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    FILTER_TYPE_LABEL: () => FILTER_TYPE_LABEL,
    FILTER_TYPE_ALLERGEN: () => FILTER_TYPE_ALLERGEN,
  }
}
