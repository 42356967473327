
import { ValidationProvider } from 'vee-validate';
import ShopInputErrorMessage from "./ShopInputErrorMessage";
import ShopInputLabel from "./ShopInputLabel";


export default {
  name: "ShopInputElement",
  components: { ShopInputLabel, ShopInputErrorMessage, ValidationProvider },
  props: {
    value: {
      type: [String, Number]
    },
    id: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: "text"
    },
    label: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: null
    },
    validationRules: {
      type: String,
      default: ""
    },
    insetText: {
      type: String,
      default: ""
    },
    insetPosition: {
      type: String,
      default: 'right'
    },
    step: {
      type: String,
      default: ""
    },
    additionalInputClasses: {
      type: String,
      default: ''
    },
    /**
     * Whether to occupy space for the validation message.
     */
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
    makePasswordVisibleByDefault: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: "off"
    },

    // This is required if two fields with the same label are used twice.
    // if no suffix is set, the first instance of that field will not be used.
    identifierSuffix: {
      type: String,
      default: null
    },
  },
  data: () => ({
    isPasswordField: false,
    actualType: ""
  }),
  created() {
    if(this.type === "password") {
      this.isPasswordField = true;
    }

    if(this.makePasswordVisibleByDefault) {
      this.actualType = "text";
    } else {
      this.actualType = this.type;
    }
  },
  computed: {
    getName() {
      const suffix = this.identifierSuffix

      if (this.id) {
        return suffix ? this.id + "_" + suffix : this.id;
      }

      return suffix ? this.label + '_' + suffix : this.label;
    },

    /**
     * Returns the Input Placeholder.
     *
     * @returns {string}
     */
    getPlaceholder() {
      return this.placeholder;
    },
    /**
     * Determines if the input is required to be filled out.
     *
     * @return {boolean}
     */
    isRequired() {
      if (!this.validationRules) {
        return false;
      }

      const rules = this.validationRules.split('|');
      return rules.includes("required");
    },
    isInsetLeft() {
      return this.insetPosition === 'left';
    },

    isOfTypePassword() {
      return this.isPasswordField;
    }
  },
  methods: {
    emitInput(e) {
      this.$emit('input', e.target.value);
    },
    blurInput(e) {
      this.$emit('blur', e.target.value);
    },
    focus() {
      this.$refs.input.focus();
    }
  }
}
