
import ShopInput from "../../components/forms/inputs/ShopInput";
import {mapGetters} from "vuex";
import LoadingSvgInline from "@/layouts/shop/components/loading/LoadingSvgInline.vue";

export default {
  name: "ShopSearchInput",
  components: {LoadingSvgInline, ShopInput},
  props: {
    showCloseButton: {
      type: Boolean,
      default: false
    }
  },
  created() {
    // Mobile
    if(this.showCloseButton) {
      this.searchTerm = null;
    }
  },
  methods: {
    onSearchButtonClick() {
      if (!this.hasEnteredSearchTerm) {
        return this.$modal.show({
          type: 'danger',
          title: this.$t('common.error'),
          body: this.$t('search.empty_search'),
          primary: {
            label: this.$t('common.confirm'),
            theme: 'danger',
            action: () => {
            },
          },
        });
      }

      if(this.$refs.input && this.$refs.input.$refs.input) {
        this.$refs.input.$refs.input.blur();
      }

      this.$router.push(
        this.localePath({
          name: 'search-results',
          query: {
            q: this.searchTerm
          }
        })
      );
    },
  },
  computed: {
    ...mapGetters('search', [
      'isSearchLoading'
    ]),
    searchTerm: {
      get() {
        return this.$store.getters["search/getSearchTerm"];
      },
      set(value) {
        this.$store.commit('search/setSearchTerm', value);
      }
    },
    hasEnteredSearchTerm() {
      return this.$store.getters["search/hasEnteredSearchTerm"];
    }
  }
}
