
import CheckoutAddressStep from "@/layouts/shop/components/checkout/deliveryAddress/CheckoutAddressStep.vue";
import CheckoutTimeslotStep from "@/layouts/shop/components/checkout/timeSlot/CheckoutTimeslotStep.vue";
import CheckoutSelectionCartRefreshObserver
  from "@/layouts/shop/components/checkout/CheckoutSelectionCartRefreshObserver.vue";
import CheckoutPaymentStep from "@/layouts/shop/components/checkout/payment/CheckoutPaymentStep.vue";
import CheckoutPackagingStep from "@/layouts/shop/components/checkout/packaging/CheckoutPackagingStep.vue";

export default {
  name: "CheckoutComponents",
  components: {
    CheckoutPackagingStep,
    CheckoutPaymentStep, CheckoutSelectionCartRefreshObserver, CheckoutTimeslotStep, CheckoutAddressStep},
  data() {
    return {
      stepsLoaded: {
        deliveryAddress: false,
        timeSlots: false,
        payment: false,
      }
    }
  },
  methods: {
    onStepLoaded(step) {
      this.$set(this.stepsLoaded, step, true);

      // All steps are loaded, hiding loading indicator overlay
      if(Object.values(this.stepsLoaded).every(s => s === true)) {
        this.$nextTick(() => this.$emit('all-steps-loaded'));
      }
    },
  },
}
