
import SelectInput from "./SelectInput";

export default {
  name: "CountrySelectInput",
  components: { SelectInput },
  data: () => ({
    countries: [],
    loadingCountries: false
  }),
  props: {
    value: {
      type: String,
    },
    luxembourgOnly: {
      type: Boolean,
      default: false
    },
    validationRules: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // This is required if two fields with the same label are used twice.
    // if no suffix is set, the first instance of that field will not be used.
    identifierSuffix: {
      type: String,
      default: null
    },
  },
  mounted() {
    if (this.luxembourgOnly)
      return false;

    this.loadingCountries = true;
    this.$countryRepository.getCountries().then(res => {
      this.countries = res.data;
    }).finally(() => {
      this.loadingCountries = false;
    });
  },
  computed: {
    getCountries() {
      if (this.luxembourgOnly) {
        return [
          { label: this.$t('countries.luxembourg'), value: 'lu' }
        ];
      }

      return this.countries.map(c => {
        return {
          label: c.name,
          value: c.isoCode
        }
      })
    },
    getPrioritizedCountries() {
      return ['lu', 'de', 'fr', 'be'];
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
}
