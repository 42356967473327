
import PaymentMethodImage
  from "@/layouts/shop/components/account/orders/single/orderTransactions/PaymentMethodImage.vue";
import configHelper from "@/helpers/configHelper.js";
import CheckoutSelectionRadioButton
  from "@/layouts/shop/components/checkout/common/CheckoutSelectionRadioButton.vue";

export default {
  name: "CheckoutPaymentMethodSelectionItem",
  components: {CheckoutSelectionRadioButton, PaymentMethodImage},
  props: {
    paymentMethod: {
      type: String,
    },
    savedCard: {
      type: Object,
    },
    isUnused: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    onClick(e) {
      if(this.isUnused || this.isExpired) {
        return false;
      }

      this.$emit('click', e);
    }
  },
  computed: {
    getLocalizedPaymentMethod() {
      if(!this.paymentMethod) {
        return null;
      }

      return this.$t(configHelper.getTranslationKeyForPaymentMethod(this.paymentMethod, true));
    },
    isExpired() {
      return this.savedCard && this.savedCard.isExpired;
    }
  },
}
