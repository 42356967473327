
const url = `/my/invoices`;

export default $api => ({
  async listInvoices(page = 1, perPage = 20) {
    return await $api.$get(`${url}/?page=${page}&perPage=${perPage}`);
  },
  async downloadInvoice(invoiceID) {
    return await $api.$get(`${url}/${invoiceID}/download`, {
      responseType: 'blob'
    },).then((data) => {
      const url = URL.createObjectURL(new Blob([data], {
        type: 'application/pdf'
      }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${invoiceID}.pdf`)
      document.body.appendChild(link)
      link.click();
    });
  },
});
