export default ({ app }, inject) => {
  app.router.afterEach((to, from) => {
    window.setTimeout(() => {
      // Brevo not yet loaded or blocked through cookies.
      if(typeof sendinblue === "undefined") {
          inject('brevo', null);
          return;
      }

      // identify user (only once)
      if(app.$auth.loggedIn && window.identifiedBrevoUser === undefined) {
        const user = app.$auth.user;


        sendinblue.identify(user.email, {
          language: app.i18n.locale,
          memberId: user.memberId,
          VORNAME: user.firstName,
          NACHNAME: user.lastName,
          FIRMENNAME: user.companyName,
        })

        window.identifiedBrevoUser = true;
      }


      // Inject in nuxt. Always check first if available!
      inject('brevo', sendinblue);

      // Track frontend page views,
      // without this only SSR tracks. (first hard refresh)
      sendinblue.page(document !== undefined ? document.title : to.name);
    }, 200)
  });
}
