// This function is called whenever the route is changed.
// Returning the savedPosition will result in a native-like behavior when
// navigating with back/forward buttons, scrolling to the last position.
// If there is no saved position, it will simply scroll to top.

export default function (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { x: 0, y: 0 }
  }
}
