
export default {
  name: "ShopCartSummary",
  computed: {
    getTotals() {
      return this.$store.getters["cart/getTotals"];
    },
    getTotal() {
      return this.getTotals?.amount || 0;
    },
  }
}
