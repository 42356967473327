
export default {
  name: "ProductPageSubtitleAttribute",
  props: {
    productSubtitle: {
      type: String,
      required: true
    }
  }
}
