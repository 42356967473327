const functions = {
  centsToEuro(value) {
    return (value / 100).toFixed(2);
  },
  centsToEuroString(value, spaceBeforeSymbol = true) {
    return this.centsToEuro(value) + (spaceBeforeSymbol ? ' €' : '€');
  },
}

export default (context, inject) => {
  inject('moneyHelper', functions)
}
