
import ShopCartItem from "@/layouts/shop/components/cart/ShopCartItem.vue";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";
import {mapActions} from "vuex";

export default {
  name: "CheckoutTimeSlotUnavailableItem",
  components: {ShopButton, ShopCartItem},
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    ...mapActions('cart', [
      'setCartItemQuantity'
    ]),
    removeItem() {
      this.loading = true;
      this.setCartItemQuantity({
        item: this.item,
        quantity: 0,
        loadDefaults: true
      }).then(() => {
        this.$emit('resolve', this.item.id);
      }).finally(() => {
        this.loading = false;
      });
    },
    setItemToMaxAvailableQuantity() {
      if (!this.hasMaxAvailableQuantity) {
        return false;
      }

      this.loading = true;
      this.setCartItemQuantity({
        itemId: this.item.id,
        quantity: this.item.maxAvailableQuantity,
        loadDefaults: true
      }).then(() => {
        this.$emit('resolve', this.item.id);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  computed: {
    hasMaxAvailableQuantity() {
      return this.item.maxAvailableQuantity !== null;
    },
  }
}
