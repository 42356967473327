
import ShopPageTitle from "./shop/components/pages/ShopPageTitle";
import ShopCard from "./shop/components/cards/ShopCard";

export default {
  name: "ShopErrorPage",
  components: { ShopCard, ShopPageTitle },
  layout: 'errorLayout',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    error: {
      type: Object
    }
  },
  computed: {
    getStatusCode() {
      return this.error?.statusCode || this.$t('common.error');
    },
    getTitleText() {
      if (this.error?.statusCode === null) {
        return "Technical error";
      }

      switch (this.error.statusCode) {
        case 404:
          return "Page not found";
        case 429:
          return "Too many requests";
        case 500:
          return "Internal server error";
        case 503:
          return "Service unavailable";
        default:
          return "Technical error";
      }
    },
    getStatusText() {
      if (typeof this.getStatusCode === "number") {
        const transStr = 'errorCodes.error_' + this.getStatusCode;
        return this.$te(transStr)
          ? this.$t(transStr)
          : this.$t('errorCodes.fallback');
      }
      return null;
    }
  },
}
