
import ShopCartActionButton from "../ShopCartActionButton";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";
export default {
  name: "ShopCartActionCreateShoppingList",
  components: { ShopButton, ShopCartActionButton },
  methods: {
    openShoppingListModal() {
      this.$store.commit('shoppingLists/setCartSelected');
      this.$emit('click')
    }
  },
  computed: {
    isCartAddedToList() {
      return this.$store.getters['shoppingLists/hasSelectedCart'];
    }
  }
}
