
import ShopButton from "../../buttons/ShopButton";
import AccountCard from "@/layouts/shop/components/account/sub/AccountCard/AccountCard";
import AccountCardTitle from "@/layouts/shop/components/account/sub/AccountCard/AccountCardTitle";
import AccountCardSubText from "@/layouts/shop/components/account/sub/AccountCard/AccountCardSubText";
export default {
  name: "DeliveryContactCard",
  components: { AccountCardSubText, AccountCardTitle, AccountCard, ShopButton },
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  filters: {
    uppercase(value) {
      return value.toUpperCase();
    }
  },
  methods: {
    confirmDelete() {
      this.$modal.show({
        type: 'danger',
        title: this.$t('common.are_you_sure'),
        body: this.$t('account.delivery_addresses.delete_confirmation'),
        primary: {
          label: this.$t('common.confirm'),
          theme: 'danger',
          action: () => this.deleteContact(),
        },
        secondary: {
          label: this.$t('common.cancel'),
          theme: 'light',
          action: () => {}
        }
      });
    },
    deleteContact() {
      this.$deliveryContactRepository.deleteContact(this.contact.id).then(() => {
        this.$toast.success(this.$t('account.delivery_addresses.deleted_message'));
        this.$nuxt.refresh();
        this.$auth.refreshTokens();
      });
    }
  },
}
