
import ShopCheckbox from "@/layouts/shop/components/forms/inputs/base/ShopCheckbox.vue";
import QuestionMarkButton from "@/layouts/shop/components/buttons/QuestionMarkButton.vue";
import CheckoutTimeSlotPercentageBar
  from "@/layouts/shop/components/checkout/timeSlot/selector/sub/CheckoutTimeSlotPercentageBar.vue";
import CheckoutTimeslotDiscountCampaign
  from "@/layouts/shop/components/checkout/timeSlot/selector/sub/CheckoutTimeslotDiscountCampaign.vue";
import CheckoutSelectionRadioButton
  from "@/layouts/shop/components/checkout/common/CheckoutSelectionRadioButton.vue";

export default {
  name: 'CheckoutTimeSlot',
  components: {
    CheckoutSelectionRadioButton,
    CheckoutTimeslotDiscountCampaign, CheckoutTimeSlotPercentageBar, QuestionMarkButton, ShopCheckbox},
  props: {
    timeslot: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Boolean,
      required: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isRecommended: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onTimeSlotClick() {
      if (this.isFull) {
        this.$modal.show({
          type: 'danger',
          title: this.$t('checkout.timeslot_full'),
          body: this.$t('checkout.timeslot_full_text'),
          primary: {
            label: 'OK',
            theme: 'danger',
            action: () => this.$emit('disabled-confirm'),
          },
        });
        return false;
      }

      this.$emit('selected');
    },
  },
  computed: {
    getFilledPercentage() {
      return this.timeslot.filledPercentage;
    },
    isNearlyFull() {
      return this.getFilledPercentage >= 90 && this.getFilledPercentage <= 99.99
    },
    isFull() {
      return this.getFilledPercentage >= 100;
    },

    getClassesByState() {
      if (this.isFull) {
        return 'bg-red-50 text-gray-400 cursor-not-allowed opacity-60';
      }

      return "cursor-pointer bg-white hover:bg-luxcaddy-100 border border-gray-100";
    },

    isUnavailable() {
      return this.timeslot.isUnavailable;
    }
  }
}
