
import LoadingSpinner from "@/layouts/shop/components/loading/LoadingSpinner";

export default {
  name: "PaymentInitializedSaferpayExistingCard",
  components: { LoadingSpinner },
  mounted() {
    this.checkSaferpayResponse();
  },
  methods: {
    checkSaferpayResponse() {
      if(this.getPaymentInitResponse?.status === "success") {
        return this.$router.push(
          this.localePath('checkout-payment-order-confirmed')
        );
      }

      if (this.getPaymentInitResponse?.initializePayment && this.getPaymentInitResponse?.initializePayment?.token) {
        window._localStorage.setItem('saferPayToken', this.getPaymentInitResponse.initializePayment.token);
        window.location.href = this.getPaymentInitResponse.initializePayment.redirectUrl;
      }

      return this.$router.push(
        this.localePath('checkout-payment-saferpay-error')
      );
    }
  },
  computed: {
    getPaymentInitResponse() {
      return this.$store.getters['checkout/getPaymentInitResponse'];
    }
  },
}
