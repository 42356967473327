
import ShopFaqQuestion from "@/layouts/shop/components/faq/ShopFaqQuestion";
export default {
  name: "ShopFaqMenu",
  components: { ShopFaqQuestion },
  props: {
    questions: {
      type: Array,
      required: true
    }
  },
  computed: {
    getGroupedQuestions() {

      const groupedByCategoryName = this.questions.reduce((data, item) => {
        item.categories.forEach(category => {
          if (!data[category.name]) {
            data[category.name] = {
              categorySortIndex: category.categorySortIndex,
              items: []
            };
          }
          data[category.name].items.push({
            ...item,
            itemSortIndex: category.itemSortIndex
          });
        });
        return data;
      }, {});

      // Convert the grouped object to an array and sort by categorySortIndex
      return Object.keys(groupedByCategoryName)
        .map(key => ({
          categoryName: key,
          categorySortIndex: groupedByCategoryName[key].categorySortIndex,
          items: groupedByCategoryName[key].items.sort((a, b) => b.itemSortIndex - a.itemSortIndex)
        }))
        .sort((a, b) => a.categorySortIndex - b.categorySortIndex);
    },
  },
}
