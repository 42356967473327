
export default {
  name: "CheckoutSelectionRadioButton",
  props: {
    heightClass: {
      type: String,
      default: "h-8",
    },
    widthClass: {
      type: String,
      default: "w-8",
    }
  }
}
