
export default {
  name: "SingleFaqInModalIcon",
  props: {
    id: {
      type: String,
      required: true
    },
    extraText: {
      type: String,
      default: null
    },
    iconRight: {
      type: Boolean,
      default: true,
    },
    isTextColored: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      faqTitle: null,
      faqBody: null,

      isLoading: false,
    }
  },
  methods: {
    loadSingleFaq() {
      if(this.isLoaded) {
        return this.showFaqModal();
      }

      this.isLoading = true;

      this.
        $blogRepository
        .getFaqByIdentifier(this.id)
        .then((res) => {
          this.faqTitle = res.data.title;
          this.faqBody = res.data.text;

          this.showFaqModal();
        })
        .finally(() => this.isLoading = false);
    },

    showFaqModal() {
      this.$modal.show({
        type: 'warning',
        title: this.faqTitle,
        body: this.faqBody,
        primary: {
          label: this.$t('common.ok'),
          theme: 'primary',
          action: () => {}
        }
      });
    }
  },
  computed: {
    isLoaded() {
      return this.faqTitle !== null && this.faqBody !== null;
    }
  },
}
