
export default {
  name: "ShopFooterLinkWithIcon",
  props: {
    text: String,
    icon: [String, Array],
    iconTextClass: {
      type: String,
      default: "text-2xl"
    },
    fixedWidth: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
    },
    route: {
      type: String
    }
  },
}
