
import AccountCard from "@/layouts/shop/components/account/sub/AccountCard/AccountCard.vue";
import AccountCardTitle from "@/layouts/shop/components/account/sub/AccountCard/AccountCardTitle.vue";
import CustomTable from "@/layouts/shop/components/tables/CustomTable.vue";
import TableRow from "@/layouts/shop/components/tables/TableRow.vue";
import TableDataCell from "@/layouts/shop/components/tables/TableDataCell.vue";
import InvoiceStatusBadge from "@/layouts/shop/components/account/invoices/InvoiceStatusBadge.vue";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";

export default {
  name: "InvoiceListItem",
  components: { ShopButton, InvoiceStatusBadge, TableDataCell, TableRow, CustomTable, AccountCardTitle, AccountCard },
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    downloadInvoiceLoading: false,
  }),
  methods: {
    downloadInvoice() {
      this.downloadInvoiceLoading = true;
      this.$invoiceRepository
        .downloadInvoice(this.invoice.id)
        .finally(() => this.downloadInvoiceLoading = false);
    }
  },
  computed: {
    getClassByOverdueStatus() {
      if(this.invoice.overDueDays === null) {
        return null;
      }
      if(this.invoice.overDueDays === 0) {
        return "text-green-500 font-semibold";
      }
      if(this.invoice.overDueDays < 16) {
        return "text-yellow-500 font-semibold";
      }
      return "text-red-500 font-semibold";
    }
  },
}
