/**
 * Recursively returns the id of all parents where func applies.
 * Used to retrieve all parent ids recursively.
 *
 * @param tree
 * @param func
 * @param path
 * @returns {*[]|[]|*}
 */
let findPathInTree = function (tree, func, path = []) {
  if (!tree) {
    return [];
  }
  for (const data of tree) {
    path.push(data);
    if (func(data)) {
      return path;
    }
    if (data.subCategories) {
      const findChildren = findPathInTree(data.subCategories, func, path);

      if (findChildren.length) {
        return findChildren;
      }
    }
    path.pop();
  }
  return [];
}

let findParentCategory = function (categories, subcategoryId, parent = null) {
  for (const category of categories) {
    if (category.id === subcategoryId) {
      return parent; // Found the parent category
    } else if (category.subCategories.length > 0) {
      const result = findParentCategory(category.subCategories, subcategoryId, category);
      if (result !== null) {
        return result; // Found in a subcategory
      }
    }
  }
  return null; // Subcategory ID not found
}


export {findPathInTree, findParentCategory};
