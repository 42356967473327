
export default {
  name: "PaginationButton",
  props: {
    isActive: {
      type: Boolean,

      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getClasses() {
      if(this.disabled) {
        return 'bg-gray-200 cursor-not-allowed';
      }

      if(this.isActive) {
        return 'bg-black';
      }

      return 'bg-luxcaddy-500 hover:bg-luxcaddy-600 cursor-pointer';
    }
  },
}
