
import CheckoutPaymentMethodSelection
  from "@/layouts/shop/components/retryPaymentAfterOrderCreation/payment/CheckoutPaymentMethodSelection.vue";
import SingleFaqInModalIcon from "@/layouts/shop/components/faq/ShopFaqSingle/SingleFaqInModalIcon.vue";

export default {
  name: "UnavailablePaymentMethodsList",
  components: {SingleFaqInModalIcon, CheckoutPaymentMethodSelection},
  computed: {
    getAllowedPaymentProviders() {
      return this.$auth.user.allowedPaymentProviders;
    },
    isBusinessCustomer() {
      return this.$auth.user.companyName !== null;
    },
    canUseInvoice() {
      return this.getAllowedPaymentProviders.includes('invoice');
    },
    canUseMonthlyInvoice() {
      return this.getAllowedPaymentProviders.includes('monthlyInvoice');
    },
    showMenu() {
      return this.isBusinessCustomer && (!this.canUseInvoice || !this.canUseMonthlyInvoice);
    }
  },
}
