
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "ImageFullScreenModalTopBar",
  methods: {
    ...mapMutations('imageModal', [
      'setImageModelOpen'
    ])
  },
  computed: {
    ...mapGetters('imageModal', [
      'getCurrentImageNumber',
      'getImagesCount',
      'getModalTitle'
    ])
  },
}
