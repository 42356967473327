
import { ValidationProvider } from 'vee-validate';
import ShopInputElement from "@/layouts/shop/components/forms/inputs/base/ShopInputElement.vue";
import PasswordLengthRuleValidationText
  from "@/layouts/shop/components/forms/inputs/password/sub/PasswordLengthRuleValidationText.vue";
import PasswordStrengthIndicator
  from "@/layouts/shop/components/forms/inputs/password/sub/PasswordStrengthIndicator.vue";

export default {
  name: "PasswordInputElement",
  components: {PasswordStrengthIndicator, PasswordLengthRuleValidationText, ShopInputElement, ValidationProvider},
  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-types
    value: {
      required: false
    },
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: [String, null]
    },
    // eslint-disable-next-line vue/require-default-prop
    label: {
      type: String,
      required: false
    },
    // eslint-disable-next-line vue/require-default-prop
    placeholder: {
      type: String,
      required: false
    },
    // eslint-disable-next-line vue/require-default-prop
    validationRules: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    /**
     * Whether to occupy space for the validation message.
     */
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
    autocomplete: {
      type: String,
      default: "off"
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    }
  },
}
