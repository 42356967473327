
import LoadingSpinner from "@/layouts/shop/components/loading/LoadingSpinner";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton";

export default {
  name: "PaymentInitializedPayconiq",
  components: { ShopButton, LoadingSpinner },
  layout: "simple",
  data: () => ({
    checkerTimer: null
  }),
  mounted() {
    this.initCheckPaymentStatusTimer();
  },
  beforeDestroy() {
    this.clearCheckerTimer();
  },
  methods: {
    goToOrdersPage() {
      this.$router.push(
        this.localePath({
          name: 'account-index-orders'
        })
      );
    },
    initCheckPaymentStatusTimer() {
      let vm = this;
      this.checkerTimer = setInterval(function () {
        vm.checkPaymentStatus();
      }, 3000);
    },
    openDeepLink() {
      if (!this.getPaymentInitResponse?.deepLink)
        return false;

      window.location.href = this.getPaymentInitResponse.deepLink;
    },
    clearCheckerTimer() {
      clearInterval(this.checkerTimer);
      this.checkerTimer = null;
    },
    /**
     * While the status is pending, the request will be done again in a 3 second interval.
     * If its successful, the user is redirected to a success page.
     */
    checkPaymentStatus() {
      this.$paymentRepository
        .payconiq
        .checkPaymentStatus(this.getPaymentInitResponse.paymentId)
        .then(res => {
          const status = res.data.status;

          if(status === "success") {
            this.clearCheckerTimer();
            return this.$router.push(
              this.localePath('checkout-payment-order-confirmed')
            );
          }

          if(status === "canceled") {
            this.clearCheckerTimer();
            return this.goToOrdersPage();
          }
        })
    },
  },
  computed: {
    getPaymentInitResponse() {
      return this.$store.getters['checkout/getPaymentInitResponse'];
    }
  },
}
