import {findParentCategory, findPathInTree} from "@/helpers/categoryHelper.js";

export const state = () => ({
  categories: [],
  expandedCategories: [],
})


export const mutations = {
  setCategories(state, sidebarLinks) {
    state.categories = sidebarLinks;
    state.expandedCategories = [];
  },

  toggleExpandCategory(state, categoryId) {
    // Closes Categories so that only one category tree at a time is open
    state.expandedCategories = [];

    // Expand selected Category and parents
    const parents = findPathInTree(state.categories, cat => cat.id === categoryId);
    parents.forEach(category => {
      if(!state.expandedCategories.includes(category.id)) {
        state.expandedCategories.push(category.id);
      }
    });
    state.expandedCategories.push(categoryId);
  },

  unsetExpandedCategories(state) {
    state.expandedCategories = [];
  }
}

export const getters = {
  getCategories: state => state.categories,
  isExpanded: state => (catId) => state.expandedCategories.includes(catId),
  getSiblingCategories: state => (catId) => {
    const parentCategory = findParentCategory(state.categories, catId);

    // No parent, return none
    if(parentCategory === null) {
      return [];
    }

    return parentCategory?.subCategories || [];
  },
}

export const actions = {
  async fetchCategories({ commit }) {
    await this.$categoryRepository.getProductCategories().then((res) => {
      commit('setCategories', res.data);
    })
  }
}
