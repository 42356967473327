
import SelectInput from "@/layouts/shop/components/forms/inputs/SelectInput.vue";
export default {
  name: "PhoneCountryCodePrefixSelectInput",
  components: { SelectInput },
  created() {
    this.$countryRepository.getCountryPhonePrefixList().then((res) => {
      this.values = res.data;
    });
  },
  data() {
    return {
      values: []
    }
  },
  props: {
    value: {
      type: Number,
    },
    validationRules: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // This is required if two fields with the same label are used twice.
    // if no suffix is set, the first instance of that field will not be used.
    identifierSuffix: {
      type: String,
      default: null
    },
  },
  computed: {
    getOptions() {
      return this.values.map(value => ({
        label: '+' + value.prefix,
        value: value.prefix
      }));
    },
    getPrioritizedPrefixes() {
      return [352, 49, 33, 32];
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
}
