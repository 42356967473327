
import ShopCard from "@/layouts/shop/components/cards/ShopCard";
import ShopCheckbox from "@/layouts/shop/components/forms/inputs/base/ShopCheckbox";
import ShopRadioButton from "@/layouts/shop/components/forms/inputs/radio/ShopRadioButton";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton";

export default {
  name: "CheckoutCreditCard",
  components: {ShopButton, ShopRadioButton, ShopCheckbox, ShopCard},
  props: {
    card: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    confirmDelete() {
      this.$modal.show({
        type: 'danger',
        title: this.$t('common.are_you_sure'),
        body: this.$t('checkout.credit_card.delete_explanation'),
        primary: {
          label: this.$t('common.delete'),
          theme: 'danger',
          action: () => this.deleteSavedCard(),
        },
        secondary: {
          label: this.$t('common.cancel'),
          theme: 'light',
          action: () => {
          }
        }
      });
    },
    deleteSavedCard() {
      this.$paymentRepository
        .saferpay
        .deleteSavedCard(this.card.id)
        .then(() => {
          this.$toast.success(
            this.$t('checkout.credit_card.deleted')
          );

          // Selected card has been deleted
          if (this.selectedCreditCardAlias === this.card.alias) {
            this.selectedCreditCardAlias = null;
          }

          this.$emit('deleted');
        })
    },
    setSelectCard() {
      if (this.isExpired)
        return false;

      this.$emit('select', this.card.alias);
    }
  },
  computed: {
    getBrandLogo() {
      switch (this.card.paymentMethod) {
        case "VISA":
          return require('~/assets/images/payment/creditcard/brands/visa.png');
        case "MASTERCARD":
          return require('~/assets/images/payment/creditcard/brands/mastercard.png');
      }
    },
    isExpired() {
      return this.card.isExpired;
    },
    isSelected() {
      return this.selectedCreditCardAlias === this.card.alias;
    },
    selectedCreditCardAlias() {
      return this.$store.getters['checkout/getSelectedCreditCardAlias'];
    },

    getOuterClasses() {
      if (this.selectable) {
        return this.isSelected
          ? 'bg-luxcaddy-50 border-luxcaddy'
          : 'border-gray-200 bg-white  hover:border-gray-400 hover:cursor-pointer';
      }

      return "";
    }
  },
}
