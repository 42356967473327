
import { ValidationProvider } from 'vee-validate';
import ShopInputLabel from "./base/ShopInputLabel";
import LoadingSvgInline from "../../loading/LoadingSvgInline";
import ShopInputErrorMessage from "@/layouts/shop/components/forms/inputs/base/ShopInputErrorMessage.vue";

export default {
  name: 'SelectInput',
  components: {ShopInputErrorMessage, LoadingSvgInline, ShopInputLabel, ValidationProvider},
  props: {
    label: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    prioritizedOptions: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
    },
    unselectedPlaceholder: {
      type: String,
      default: ""
    },
    customBackground: {
      type: String,
      default: ''
    },
    customHeight: {
      type: String,
      default: 'h-10'
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    menuRight: {
      type: Boolean,
      default: false
    },
    validationRules: {
      type: String,
      default: ""
    },
    occupyValidationSpace: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    spaceBetweenSelectionAndArrowClass: {
      type: String,
      default: 'space-x-3'
    },
    optimizedForCart: {
      type: Boolean,
      default: false
    },
    faqQuestionId: {
      type: String,
    },
    // This is required if two fields with the same label are used twice.
    // if no suffix is set, the first instance of that field will not be used.
    identifierSuffix: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      isOpen: false
    }
  },
  mounted() {
    this.syncForValidation(this.value);
  },
  watch: {
    value: function (value) {
      this.syncForValidation(value);
      this.$refs.validationProvider.validate();
    },
    // Validation rules change dynamically on the address forms
    validationRules() {
      this.$refs.validationProvider.reset();
    }
  },
  methods: {
    syncForValidation(value) {
      if(this.$refs.validationProvider) {
        this.$refs.validationProvider.syncValue(value);
      }
    },

    isSelected(option) {
      return this.value === option.value;
    },
    closeDropdown() {
      this.isOpen = false;
    },
    toggleDropdown() {
      // Don't open if loading or disabled
      if ((!this.isOpen && this.loading) || this.disabled) {
        return false;
      }

      this.isOpen = !this.isOpen;
    },
    select(option) {
      if (this.disabled)
        return false;

      this.isOpen = false;
      this.$emit('input', option.value);
    },
    getOptionByValue(value) {
      return this.getOptions.find(o => o.value === value) || null;
    },
  },
  computed: {
    hasSelectedOption() {
      if (!this.getOptions.length)
        return false;

      return this.getOptions.some(o => o.value === this.value);
    },
    getName() {
      if(!this.label) {
        return null;
      }

      const label = this.label.toLowerCase().replace(/ /g, '-');

      return this.identifierSuffix ? label + '_' + this.identifierSuffix : label;
    },
    optionsContainsObjects() {
      return this.options.some(o => typeof o === "object");
    },
    hasPrioritizedOptions() {
      return this.prioritizedOptions.length > 0;
    },
    getLastPrioritizedOptionValue() {
      if (!this.hasPrioritizedOptions)
        return null;

      return this.prioritizedOptions[this.prioritizedOptions.length - 1];
    },
    getOptions() {
      let options = [];


      if (this.optionsContainsObjects) {
        options = this.options;
      } else {
        options = this.options.map(o => {
          return {
            label: o,
            value: o
          }
        });
      }

      // Add currently selected value at the end if its not already part of the option list.
      // Example: Quantity in cart of item is 63, but only numbers 1-50 are displayed
      // End of List will be: [...] 47, 48, 49, 50, 63
      if (this.value && !options.find(o => o.value === this.value)) {
        options.push({
          label: this.value,
          value: this.value
        })
      }

      if (this.hasPrioritizedOptions) {
        options = options.sort((firstOption, secondOption) => {
          const firstIndex = this.prioritizedOptions.indexOf(firstOption.value);
          const secondIndex = this.prioritizedOptions.indexOf(secondOption.value);
          return (
            (firstIndex > -1 ? firstIndex : Infinity) - (secondIndex > -1 ? secondIndex : Infinity)
          );
        });
      }

      return options;
    },

    isRequired() {
      return this.validationRules.includes("required");
    }
  },
}
