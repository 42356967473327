
export default {
  name: "ShopRadioButton",
  props: {
    selected: {
      type: Boolean
    },
  },
  methods: {
    onSelect() {
      this.$emit('select', true);
    }
  },
}
