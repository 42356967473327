
import {mapGetters} from "vuex";
import PaymentMethodImage
  from "@/layouts/shop/components/account/orders/single/orderTransactions/PaymentMethodImage.vue";
import configHelper from "@/helpers/configHelper.js";
import CheckoutPaymentSelectedMethod
  from "@/layouts/shop/components/checkout/payment/sub/CheckoutPaymentSelectedMethod.vue";

export default {
  name: "CheckoutPaymentStepReview",
  components: {CheckoutPaymentSelectedMethod, PaymentMethodImage},
  computed: {
    ...mapGetters('checkout', [
      'getSelectedPaymentMethod',
      'getSelectedCreditCardDetails'
    ]),
    ...mapGetters('cart', [
      'getTotals'
    ]),
    getOrderAmount() {
      return this.getTotals.amount;
    },
    getAmountToPay() {
      return this.getOrderAmount;
    },
    getWalletAmount() {
      if (this.getSelectedPaymentMethod === 'monthlyInvoice') {
        return 0;
      }
      return this.$auth.user.walletAmount;
    },
    getAmountPaidFromWallet() {
      if (this.getWalletAmount > this.getAmountToPay) {
        return this.getAmountToPay;
      }

      return this.getWalletAmount;
    },
    getAmountToPayWithoutWallet() {
      return this.getAmountToPay - this.getAmountPaidFromWallet;
    },
    isPartBeingPaidByWallet() {
      return this.getAmountPaidFromWallet > 0;
    }
  }
}
