import { screenSizes } from '@/helpers/tailwindHelper.js';

export default {
  data() {
    return {
      windowWidth: process.client ? window.innerWidth : null
    }
  },
  mounted() {
    if(process.client) {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
      })
    }
  },
  computed: {
    getCurrentBreakpoint() {
      if (process.client) {
        let width = this.windowWidth;

        let breakPoints = Object.entries(screenSizes).filter(size => {
          return width > size[1].replace(/\D/g, '');
        });

        if(!breakPoints.length) {
          return 'default';
        }

        return breakPoints[breakPoints.length - 1][0];
      }
      return null;
    },
    isBreakpointLgOrUp() {
      return ['lg', 'xl', '2xl', '3xl', '4xl'].includes(this.getCurrentBreakpoint);
    },
    isBreakpointBelowLg() {
      return ['default', 'sm', 'md'].includes(this.getCurrentBreakpoint);
    },
    isBreakpointSMorBelow() {
      return ['default', 'sm'].includes(this.getCurrentBreakpoint);
    },
    isBreakpointBelowXl() {
      return ['default', 'sm', 'md', 'lg'].includes(this.getCurrentBreakpoint);
    },

    isBreakpoint3xlOrUp() {
      return ['3xl', '4xl'].includes(this.getCurrentBreakpoint);
    },
    isBreakpointBelow3xl() {
      return ['default', 'sm', 'md', 'lg', 'xl', '2xl'].includes(this.getCurrentBreakpoint);
    }
  }
}
