
export default {
  name: 'ShopSidebarCategory',
  props: {
    category: {
      type: Object,
      required: true
    },
    luxury: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasChildren() {
      return this.category.subCategories.length > 0;
    },
    isExpanded() {
      return this.$store.getters['sidebar/isExpanded'](this.category.id);
    },
    getCategoryLink() {
      return this.localePath({
        name: 'categories-id-slug',
        params: {
          id: this.category.id,
          slug: this.category.slug,
        }
      })
    },
    getTextColor() {
      if(this.luxury) {
        return this.isExpanded
          ? 'uppercase text-luxcaddy-500 hover:text-amber-600'
          : 'uppercase text-black hover:text-luxcaddy-600 dark:text-white dark:hover:text-gray-300'
      }

      return this.isExpanded
        ? 'text-luxcaddy-500 hover:text-luxcaddy-600'
        : 'text-black hover:text-luxcaddy-600 dark:text-white dark:hover:text-gray-300'
    },
    getTextClasses() {
      if(this.luxury) {
        return "ml-2 font-medium tracking-wide text-lg md:text-md uppercase  text-wrap";
      }

      return "font-semibold font-mono tracking-tighter leading-tight text-sm text-wrap";
    }
  },
}
