export const FILTER_TYPE_LABEL = 'label';
export const FILTER_TYPE_ALLERGEN = 'allergen';

export const state = () => ({
  availableLabelFilters: [],
  availableAllergenFilters: [],
  activeFilters: [],

  labelMenuOpen: false,
  allergenMenuOpen: false,
})


let findFilterById = function (state, filterId) {
  let filter = state.availableLabelFilters.find(lf => lf.id === filterId);
  if(!filter) {
    filter = state.availableAllergenFilters.find(lf => lf.id === filterId);
  }
  return filter;
}

let mapFetchedTags = function (tagsFromResponse) {
  if(!tagsFromResponse) {
    return { labelFilters: [], allergenFilters: [] };
  }

  let labelFilters = tagsFromResponse.filter(t => t.type === FILTER_TYPE_LABEL);
  labelFilters = labelFilters.map(f => {
    return {
      id: f.id,
      name: f.name,
      type: f.type,
      img: f.image?.url || null,
    };
  })

  let allergenFilters = tagsFromResponse.filter(t => t.type === FILTER_TYPE_ALLERGEN);
  allergenFilters = allergenFilters.map(f => {
    return {
      id: f.id,
      name: f.name,
      type: f.type,
      img: f.image?.url || null,
    };
  });

  return { labelFilters, allergenFilters };
}

export const mutations = {
  resetFilters(state) {
    state.activeFilters = [];
  },

  setAvailableLabelFilters(state, filters) {
    state.availableLabelFilters = filters;
  },
  setAvailableAllergenFilters(state, filters) {
    state.availableAllergenFilters = filters;
  },
  setMenuOpen(state, {type, open}) {
    switch (type) {
      case FILTER_TYPE_LABEL:
        return state.labelMenuOpen = open;
      case FILTER_TYPE_ALLERGEN:
        return state.allergenMenuOpen = open;
    }
  },
  selectOrUnselectFilterById(state, filterId) {
    if(state.activeFilters.includes(filterId)) {
      state.activeFilters = state.activeFilters.filter(fId => fId !== filterId);
    } else {
      state.activeFilters.push(filterId);
    }
  }
}

export const getters = {
  getActiveFilters: state => state.activeFilters,
  getActiveFiltersWithProperties(state) {
    return state.activeFilters.map(f => {
      return findFilterById(state, f);
    });
  },


  getAvailableLabelFilters: state => state.availableLabelFilters,
  getAvailableAllergenFilters: state => state.availableAllergenFilters,

  isLabelMenuOpen: state => state.labelMenuOpen,
  isAllergenMenuOpen: state => state.allergenMenuOpen,
}

export const actions = {
  fetchAvailableFilters({ commit }) {
    this.$tagRepository.getTags().then((res) => {
      if(res && res.data) {
        const { labelFilters, allergenFilters } = mapFetchedTags(res.data);
        commit('setAvailableLabelFilters', labelFilters);
        commit('setAvailableAllergenFilters', allergenFilters);
      }
    })
  }
}
