
import ImageFullScreenModal
  from "@/layouts/shop/components/products/ProductPage/Image/Fullscreen/ImageFullScreenModal.vue";
import {mapMutations} from "vuex";

export default {
  name: "ProductPageImages",
  components: {ImageFullScreenModal},
  data: () => ({
    selectedImageUrl: null,
    selectedImageAlt: null
  }),
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  created() {
    if (this.getImages.length) {
      this.selectImage(this.getImages[0]);
    }
  },
  methods: {
    ...mapMutations('imageModal', [
      'setImageModelOpen'
    ]),

    selectImage(image) {
      this.selectedImageUrl = image.url;
      this.selectedImageAlt = image.alt ?? this.getProductName;
    },
    isImageSelected(image) {
      return this.selectedImageUrl === image.url;
    }
  },
  computed: {
    hasSelectedBigImage() {
      return !!this.selectedImageUrl && !!this.selectedImageAlt;
    },
    getImages() {
      let images = [];
      const imageObject = this.product.images?.image;

      if (imageObject) {
        if (imageObject.primary) {
          images.push(imageObject.primary[0]);
        }

        if (imageObject.backlabel) {
          images.push(imageObject.backlabel[0]);
        }
      }

      return images;
    },
    getSelectedImageIndex() {
      return this.getImages.findIndex(i => i.url === this.selectedImageUrl);
    },
    getProductName() {
      return this.product.name;
    }
  },
}
