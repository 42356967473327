
import DropDownMenu from "../../components/dropdown/DropDownMenu";

export default {
  name: "ShopNavMenuLink",
  components: { DropDownMenu },
  props: {
    title: { type: String, default: "" },
    boldTitle: { type: Boolean, default: false },
    dropDownFullWidthOnMobile: { type: Boolean, default: false },
    icon: { type: [String, Array], default: null },
    iconClass: { type: String },
    iconSize: { type: String },
    to: { type: Object, String },
    size: {
      type: String,
      default: 'normal',
      validator: val => ['normal', 'small'].includes(val)
    },
    passClickEvent: {
      type: Boolean,
      default: false
    },
    useMono: {
      type: Boolean,
      default: true
    },
    useRelative: {
      type: Boolean,
      default: true
    },
  },
  data: () => ({
    menuShown: false
  }),
  computed: {
    hasSubMenu() {
      return this.$slots.default !== undefined;
    },
    getIconSizeClass() {
      if (this.size === "small") {
        return "text-xl";
      }
      return "text-xl lg:text-2xl";
    },
    getTextSize() {
      if (this.size === "small") {
        return "text-sm 4xl:text-md";
      }
      return "";
    },
    isActive() {
      if(!this.to || !this.to.path) {
        return false;
      }

      return this.$route.matched.some(({ path }) => path.startsWith(this.to.path));
    }
  },
  methods: {
    onClickOutside() {
      this.menuShown = false;
    },
    expandOrNavigate() {
      if (this.passClickEvent) {
        this.$emit('click');
        return;
      }

      if (this.hasSubMenu) {
        this.menuShown = !this.menuShown;

        // Menu open
        if (this.menuShown) {
          this.$emit('menu-open');
        }
        return;
      }

      this.$router.push(
        this.localePath(this.to)
      );
    }
  }
}
