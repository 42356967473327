
export default {
    name: "ProductCardInfoPricePerUnit",
    props: {
        pricePerUnit: {
            type: Number,
            required: true
        },
        unit: {
            type: String,
            required: true,
        },
        textSizeClass: {
            type: String,
            default: 'text-lg'
        },
        textColorClass: {
            type: String,
            default: 'text-gray-500 dark:text-gray-300'
        }
    },
    computed: {
        getReadableUnit() {
            if (this.unit === "ut") {
                return this.$t('product.unit');
            }
            return this.unit;
        }
    },
}
