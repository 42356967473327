export const state = () => ({
  referencedProducts: []
})

export const mutations = {
  setReferencedProducts(state, referencedProducts) {
    state.referencedProducts = referencedProducts;
  },
}

export const getters = {
  getReferencedProducts: state => state.referencedProducts,
}
