export default ($api) => ({
  async fetchCart(
    deliveryContactId = null,
    deliveryTimeSlotId = null,
    packageOptions = [],
    excludedCampaigns = [],
    promoCode = null,
    withCheckoutDefault = false,
    paymentType = null,
  ) {

    let params = {};

    if(deliveryContactId !== null) {
      params.deliveryContactId = deliveryContactId;
    }

    if(deliveryTimeSlotId !== null) {
      params.deliveryTimeSlotId = deliveryTimeSlotId;
    }

    if(packageOptions.length) {
      params.packageOptions = packageOptions;
    }

    if(excludedCampaigns.length) {
      params.excludedCampaigns = excludedCampaigns;
    }

    if(promoCode !== null) {
      params.promoCode = promoCode;
    }

    if(paymentType !== null) {
      params.paymentType = paymentType;
    }

    params.withCheckoutDefault = withCheckoutDefault;

    return await $api
      .$post(`/my/cart`, params);
  },

  /**
   * Expects an Array of objects containing productId and quantity and returns a full cart response.
   * Used to sync the cart of guests.
   *
   * @returns {Promise<void>}
   */
  async syncCartFromSession(itemsInCart) {
    return await $api.$post(`/cart/session`, {
      items: itemsInCart
    });
  },

  async setItems(items) {
    return await $api.$post(`/my/cart/setItems`, {
      items
    });
  },

  async setItem(itemId, quantity) {
    return await $api.$post(`/my/cart/setItems`, {
      items: [
        {
          productId: itemId,
          quantity
        }
      ]
    });
  },
});
