
import ProductFilterSelection from "@/layouts/shop/components/filters/products/common/ProductFilterSelection";
import {FILTER_TYPE_ALLERGEN, FILTER_TYPE_LABEL} from "@/store/filters";

export default {
  name: "ProductFilterMenu",
  components: {ProductFilterSelection},
  props: {
    type: {
      default: "labels",
      validator: val => [
        FILTER_TYPE_LABEL,
        FILTER_TYPE_ALLERGEN
      ].includes(val)
    },
    positionClasses: {
      type: String,
      default: "left-0 w-screen lg:left-auto lg:right-0"
    }
  },
  computed: {
    getFilters() {
      switch (this.type) {
        case FILTER_TYPE_LABEL:
          return this.$store.getters["filters/getAvailableLabelFilters"];
        case FILTER_TYPE_ALLERGEN:
          return this.$store.getters["filters/getAvailableAllergenFilters"];
      }
    }
  },
}
