const url = "/brands";

export default $api => ({
  async getBrand(brandId) {
    return await $api.$get(`${url}/${brandId}`);
  },

  async getProductsByBrand(brandId, page = 1, perPage = 30) {
    return await $api.$get(`${url}/${brandId}/products?page=${page}&perPage=${perPage}`);
  },
});
