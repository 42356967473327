import Vue from "vue";

const invoiceAddressDefault = {
  title: null,
  firstName: null,
  familyName: null,
  companyName: null,
  street: null,
  postalCode: null,
  city: null,
  floor: null,
  country: null,
  phonePrefix: 352,
  phoneNumber: null
};

const deliveryAddressDefault = {
  title: null,
  firstName: null,
  familyName: null,
  companyName: null,
  street: null,
  postalCode: null,
  city: null,
  floor: null,
  country: "lu",
  phonePrefix: 352,
  phoneNumber: null
};

const getDefaultState = () => ({
  accountType: "private",
  email: null,
  password: null,
  vatNumber: null,
  loadedVatNumber: null,
  tosAccepted: false,
  subscribeNewsletter: false,
  deliveryAddress: {...deliveryAddressDefault},
  differentInvoiceAddress: false,
  invoiceAddress: {...invoiceAddressDefault}
});

export const state = getDefaultState;

export const mutations = {
  clearStore(state) {
    Object.assign(state, getDefaultState());
  },


  setAccountType: (state, type) => state.accountType = type,
  setEmail: (state, email) => state.email = email,
  setPassword: (state, password) => state.password = password,

  updateDeliveryAddressProperty: (state, {property, value}) => {
    Vue.set(state.deliveryAddress, property, value);
  },
  updateInvoiceAddressProperty: (state, {property, value}) => {
    Vue.set(state.invoiceAddress, property, value);
  },

  setDifferentInvoiceAddress: (state, bool) => {
    state.differentInvoiceAddress = bool;

    // Reset Invoice address object
    if (!bool) {
      state.invoiceAddress = invoiceAddressDefault;
    }
  },

  resetDetailsStep: (state) => {
    let defaultState = getDefaultState();

    // keep Step 1 values.
    defaultState.accountType = state.accountType;
    defaultState.email = state.email;
    defaultState.password = state.password;

    Object.assign(state, defaultState);
  },

  setVatNumber: (state, number) => state.vatNumber = number,
  setLoadedVatNumber: (state, number) => state.loadedVatNumber = number,

  setTosAccepted: (state, bool) => state.tosAccepted = bool,
  setSubscribeNewsletter: (state, bool) => state.subscribeNewsletter = bool,

  /**
   * Only if values are empty.
   *
   * @param state
   * @param city
   * @param country
   * @param postalCode
   * @param street
   * @param companyName
   */
  setDeliveryAddressFromVatResults: (state, {
    city,
    postalCode,
    street,
    companyName,
  }) => {
    state.deliveryAddress.city = city;
    state.deliveryAddress.postalCode = postalCode;
    state.deliveryAddress.street = street;
    state.deliveryAddress.companyName = companyName;
  }
}

export const getters = {
  getAccountType: state => state.accountType,
  getEmail: state => state.email,
  getPassword: state => state.password,
  getDeliveryAddress: state => state.deliveryAddress,
  getInvoiceAddress: state => state.invoiceAddress,
  getIsUsingDifferentInvoiceAddress: state => state.differentInvoiceAddress,
  getVatNumber: state => state.vatNumber,
  hasVatNumberChanged: state => {
    if(state.loadedVatNumber === null && state.vatNumber !== null) {
      return true;
    }

    return state.loadedVatNumber !== state.vatNumber;
  },
  getTosAccepted: (state) => state.tosAccepted,
  getSubscribeNewsletter: (state) => state.subscribeNewsletter,

  getRegisterPayload: state => {
    const deliveryAddress = state.deliveryAddress;

    const invoiceAddress = state.differentInvoiceAddress ? state.invoiceAddress : null;

    deliveryAddress.firstName = deliveryAddress.firstName === "" ? null : deliveryAddress.firstName;
    deliveryAddress.familyName = deliveryAddress.familyName === "" ? null : deliveryAddress.familyName;
    deliveryAddress.companyName = deliveryAddress.companyName === "" ? null : deliveryAddress.companyName;

    if(invoiceAddress !== null) {
      invoiceAddress.firstName = invoiceAddress.firstName === "" ? null : invoiceAddress.firstName;
      invoiceAddress.familyName = invoiceAddress.familyName === "" ? null : invoiceAddress.familyName;
      invoiceAddress.companyName = invoiceAddress.companyName === "" ? null : invoiceAddress.companyName;
    }

    return {
      'email': state.email,
      'password': state.password,
      'title': deliveryAddress.title,
      'firstName': deliveryAddress.firstName,
      'familyName': deliveryAddress.familyName,
      'companyName': deliveryAddress.companyName,
      'vatNumber': state.vatNumber,
      'street': deliveryAddress.street,
      'postalCode': deliveryAddress.postalCode,
      'city': deliveryAddress.city,
      'country': deliveryAddress.country,
      'phonePrefix': deliveryAddress.phonePrefix,
      'phoneNumber': deliveryAddress.phoneNumber,
      'floor': deliveryAddress.floor,
      'deliveryInstruction': deliveryAddress.deliveryInstruction,
      'invoiceAddress': invoiceAddress,
      'subscribe': state.subscribeNewsletter,
    };
  },

  hasCompletedStepOne(state) {
    return state.email !== null && state.password !== null;
  }
}


export const actions = {

  loadVATNumberDetails({commit, getters}) {
    const vatNumber = getters.getVatNumber;

    if (!vatNumber || (typeof vatNumber === "string" && vatNumber.length < 8)) {
      return false;
    }

    return this.$viesVatNumberRepository.getAddressByVatNumber(vatNumber).then((res) => {
      const data = res.data;

      // Not valid, or no address details
      if (!data.isValid || Array.isArray(data.address)) {
        return false;
      }

      // Only overwrite company address details if Vat number has changed
      // so we dont delete user made changed
      if(getters.hasVatNumberChanged) {
        commit('setDeliveryAddressFromVatResults', {
          city: data.address.city,
          postalCode: data.address.postalCode,
          street: data.address.street,
          companyName: data.companyName
        });
        commit('setLoadedVatNumber', vatNumber);
      }

    }).finally(() => commit('setLoadingVatNumberInfo', false));
  },

}
