
import CheckoutPaymentMethodSelection
  from "@/layouts/shop/components/retryPaymentAfterOrderCreation/payment/CheckoutPaymentMethodSelection.vue";
import LoadingSpinner from "@/layouts/shop/components/loading/LoadingSpinner";
import ShopCheckbox from "@/layouts/shop/components/forms/inputs/base/ShopCheckbox";
import CheckoutCreditCard
  from "@/layouts/shop/components/retryPaymentAfterOrderCreation/payment/Creditcard/CheckoutCreditCard.vue";
import ShopRadioButton from "@/layouts/shop/components/forms/inputs/radio/ShopRadioButton";
import ShopCheckboxButton from "@/layouts/shop/components/forms/inputs/checkbox/ShopCheckboxButton";
import SingleFaqInModalIcon from "@/layouts/shop/components/faq/ShopFaqSingle/SingleFaqInModalIcon.vue";
export default {
  name: "CheckoutCreditCardMenu",
  components: {SingleFaqInModalIcon, ShopCheckboxButton, ShopRadioButton, CheckoutCreditCard, ShopCheckbox, LoadingSpinner, CheckoutPaymentMethodSelection },
  data: () => ({
    isFetchingCards: false,
    savedCards: [],
  }),
  props: {

    disabled: {
      type: Boolean,
      required: false
    },
  },
  mounted() {
    if(this.selectedPaymentMethod === "saferpay") {
      this.fetchSavedCards();
    }
  },
  watch: {
    selectedPaymentMethod(method) {
      if(method !== "saferpay")
        return false;

      this.fetchSavedCards();
    }
  },
  methods: {
    fetchSavedCards() {
      this.isFetchingCards = true;
      this
        .$paymentRepository
        .saferpay
        .fetchSavedCards()
        .then(res => {
          this.savedCards = res.data;

          // If there is a card returned, and it is not expired,
          // it will be pre-selected as the card to use for payment
          if(this.savedCards.length) {
            const card = this.savedCards[0];

            if(!card.isExpired) {
              this.selectedCreditCardAlias = this.savedCards[0].alias;
            }
          }
        }).finally(() => this.isFetchingCards = false);
    },
    selectAlias(alias) {
      this.selectedCreditCardAlias = alias;
    },
    selectNew() {
      this.selectedCreditCardAlias = null;
      this.$emit('select');
    }
  },
  computed: {
    selectedPaymentMethod: {
      get() {
        return this.$store.getters['checkout/getSelectedPaymentMethod'];
      },
      set(method) {
        this.$store.commit('checkout/setSelectedPaymentMethod', method);
      }
    },
    saveNewCreditCard: {
      get() {
        return this.$store.getters['checkout/getSaveNewCreditCard'];
      },
      set(bool) {
        this.$store.commit('checkout/setSaveNewCreditCard', bool);
      }
    },
    isSelected() {
      return !this.selectedCreditCardAlias;
    },
    selectedCreditCardAlias: {
      get() {
        return this.$store.getters['checkout/getSelectedCreditCardAlias'];
      },
      set(alias) {
        this.$store.commit('checkout/setSelectedCreditCardAlias', alias);
      }
    }
  },
}
