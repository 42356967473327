
import ShopButton from "../../buttons/ShopButton";
import viewportMixin from "@/mixins/viewportMixin.js";
export default {
  name: "ShopCartActions",
  components: { ShopButton },
  mixins: [viewportMixin],
  props: {
    fill: {
      type: Boolean,
      default: true
    },
    cartEmptyBehavior: {
      type: String,
      default: 'hide',
      validator: val => ["hide", "disable"].includes(val)
    },
  },
  methods: {
    navigateToCheckout() {
      this.$emit('navigate');
      this.$router.push(this.localePath({ name: 'checkout' }));
    }
  },
  computed: {
    showButton() {
      if (this.isBreakpointBelowLg) {
        return false;
      }

      if(this.cartEmptyBehavior === "hide") {
        return !this.isCartEmpty;
      }

      return true;
    },
    disableButton() {
      if(this.cartEmptyBehavior === "disable") {

        return this.isCartEmpty;
      }
      return false;
    },
    isCartEmpty() {
      return this.$auth.loggedIn
        ? this.$store.getters["cart/isCartEmpty"]
        : this.$store.getters["guestCart/isCartEmpty"];
    }
  }
}
