
import configHelper from "@/helpers/configHelper.js";
import OrderTransactionType from "@/layouts/shop/components/account/orders/single/OrderTransactionType.vue";
import PaymentMethodImage
  from "@/layouts/shop/components/account/orders/single/orderTransactions/PaymentMethodImage.vue";

export default {
  name: "AccountOrderTransactionSingle",
  components: {PaymentMethodImage, OrderTransactionType},
  props: {
    transaction: {
      type: Object,
      required: true
    }
  },
  methods: {
    getInversedPayedAmount(amount) {
      if (amount === 0) {
        return amount;
      }

      return amount > 0
          ? -Math.abs(amount)
          : Math.abs(amount);
    }
  },
  computed: {
    getPayedAmount() {
      return this.$moneyHelper.centsToEuroString(
          this.getInversedPayedAmount(this.transaction.payedAmount)
      );
    },
    getPreAuthorizedAmount() {
      return this.$moneyHelper.centsToEuroString(
          this.getInversedPayedAmount(this.transaction.preAuthorizedAmount)
      );
    },

    getAmount() {
      if (this.transaction.payedAmount) {
        return this.getPayedAmount;
      }

      return this.getPreAuthorizedAmount;
    },

    getTranslationForPaymentMethod() {
      return this.$t(configHelper.getTranslationKeyForPaymentMethod(this.transaction.provider));
    },

    getPaymentMethodImageUrl() {
      return configHelper.getPaymentMethodImage(this.transaction.provider);
    }
  },
}
