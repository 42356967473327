
import ShopModal from "@/layouts/shop/components/modal/ShopModal.vue";
import CheckoutPaymentMethodSelectionMenu
  from "@/layouts/shop/components/checkout/payment/sub/selection/CheckoutPaymentMethodSelectionMenu.vue";
import SingleFaqInModalIcon from "@/layouts/shop/components/faq/ShopFaqSingle/SingleFaqInModalIcon.vue";

export default {
  name: "CheckoutPaymentMethodSelectionModal",
  components: {SingleFaqInModalIcon, CheckoutPaymentMethodSelectionMenu, ShopModal},
  methods: {
    onClose() {
      this.$emit('close');
      this.$destroy();
    }
  },
}
