
export default {
  name: "ShopCartActionButton",
  props: {
    icon: {
      type: String,
      required: true
    }
  }
}
