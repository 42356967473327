
import {mapGetters} from "vuex";
import {debounce} from "@/helpers/debounceHelper.js";

export default {
  name: "CheckoutSelectionCartRefreshObserver",
  computed: {
    ...mapGetters('checkout', [
      'getSelectedDeliveryContactId',
      'getSelectedTimeslotId',
      'getFilteredSelectedBags',
      'getSelectedPaymentMethodToSend'
    ]),

    getCombinedSelections() {
      return this.getSelectedDeliveryContactId + '|' + this.getSelectedTimeslotId + '|' + JSON.stringify(Object.entries(this.getFilteredSelectedBags)) + '|' + this.getSelectedPaymentMethodToSend;
    }
  },
  watch: {
    getCombinedSelections: debounce(function() {
      this.$store.dispatch('cart/fetchCart');
    }, 200)
  }
}
