
import {mapGetters, mapMutations} from "vuex";
import ImageFullScreenModalSingleImage
  from "@/layouts/shop/components/products/ProductPage/Image/Fullscreen/Sub/ImageFullScreenModalSingleImage.vue";

export default {
  name: "ImageFullScreenModalContainer",
  components: {ImageFullScreenModalSingleImage},
  created() {
    this.setCurrentImage(this.getMappedImages[0]);
    this.setCurrentImageNumber(1);
  },
  methods: {
    ...mapMutations('imageModal', [
      'setCurrentImage',
      'setCurrentImageNumber'
    ]),
    setImageAndNumber(image, number) {
      this.setCurrentImage(image);
      this.setCurrentImageNumber(number);
    }
  },
  computed: {
    ...mapGetters('imageModal', [
      'getImages',
      'getCurrentImage'
    ]),
    getMappedImages() {
      return this.$getLargestImageUrls(this.getImages);
    },
    getThumbnailImageUrls() {
      return this.$getSmallestImageUrls(this.getImages);
    }
  },
}
