
import ShopCard from "../cards/ShopCard";
export default {
  name: "RecipeResumeBox",
  components: { ShopCard },
  props: {
    type: {
      type: String,
      validator: val => ['people', 'prep', 'cook'].includes(val),
      required: true
    }
  },
  computed: {
    getImageByType() {
      switch (this.type) {
        case "people":
          return require("~/assets/images/pages/recipes/pictograms/people.svg");
        case "prep":
          return require("~/assets/images/pages/recipes/pictograms/preparation.svg");
        case "cook":
          return require("~/assets/images/pages/recipes/pictograms/cooking-time.svg");
      }
    }
  },
}
