
const WEAK = 1;
const MEDIUM = 2;
const STRONG = 3;

export default {
  name: "PasswordStrengthIndicator",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  computed: {
    getStrength() {
      if(!this.hasMinLength) {
        return WEAK;
      }

      const matchedConditions = [this.hasNumber, this.hasLowerCase, this.hasUpperCase, this.hasSpecialChar].filter(Boolean).length;

      if (matchedConditions === 4) {
        return STRONG;
      }

      if (matchedConditions >= 2) {
        return MEDIUM;
      }

      return WEAK;
    },

    getStrengthText() {
      const strength = this.getStrength;

      switch (strength) {
        case STRONG:
          return this.$t('auth.registration.password_strength.strong');
        case MEDIUM:
          return this.$t('auth.registration.password_strength.medium');
        default:
          return this.$t('auth.registration.password_strength.weak');
      }
    },

    getTextClassByStrength() {
      const strength = this.getStrength;

      switch (strength) {
        case STRONG:
          return "text-luxcaddy-500";
        case MEDIUM:
          return "text-yellow-500";
        default:
          return "text-red-500";
      }
    },

    hasUpperCase() {
      return this.value === null ? false : /[A-Z]/.test(this.value);
    },
    hasLowerCase() {
      return this.value === null ? false : /[a-z]/.test(this.value);
    },
    hasNumber() {
      return this.value === null ? false : /[0-9]/.test(this.value);
    },
    hasSpecialChar() {
      return this.value === null ? false : /[^\w\s]/.test(this.value);
    },
    hasMinLength() {
      return this.value === null ? false : this.value.length >= 6;
    },
  },
}
